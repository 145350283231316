import React, { useContext, useEffect, useRef, useState } from 'react'
import './profile.css'
import pp from '../../media/card.png'
import pb from '../../media/pb.png'
import fw from '../../media/fw.png'
import { Tab, Nav, InputGroup, Form, Row, Col, Button, Modal, ProgressBar } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBoxOpen, faCircleQuestion, faListCheck, faLocationDot, faLock, faUser, faMagnifyingGlass, faPenToSquare, faTrash, faPlus } from '@fortawesome/free-solid-svg-icons'
import { UserContext } from "../UserContext";
import axios from 'axios'
import upload from '../../media/upload.svg';
import place from '../../media/place.jpg';
import swal from 'sweetalert';
import { Link, useNavigate } from 'react-router-dom';
import { faCheckCircle, faTruckLoading, faShippingFast, faHome } from '@fortawesome/free-solid-svg-icons';
import ClipLoader from 'react-spinners/ClipLoader';

const Profile = () => {
    const [show, setShow] = useState(false);
    const { user } = useContext(UserContext);
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [message, setMessage] = useState('');
    const { setUser } = useContext(UserContext);
    const navigate = useNavigate();

    useEffect(() => {
        const hash = window.location.hash.substring(1);
        if (hash) {
            setActiveKey(hash);
        }
    }, []);

    const handlePasswordUpdate = async () => {
        const token = localStorage.getItem('token');

        try {
            const response = await axios.put('https://node.instasales.in/api/v1/password/update', {
                oldPassword,
                newPassword,
                confirmPassword: confirmNewPassword,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                },
                headers: {
                    'Authorization': `Bearer ${token}`
                }, // To include cookies
            });

            setMessage('Password updated successfully');
        } catch (error) {
            console.log("error", error)
            if (error.response && error.response.data.message) {
                setMessage(error.response.data.message);
            } else {
                setMessage('An error occurred while updating the password');
            }
        }
    };

    const initialFormState = {
        name: user?.name,
        email: user?.email,
        subject: '',
        product: '',
        department: '',
        message: '',
        file: null
    };

    const [formState, setFormState] = useState(initialFormState);
    const [messages, setMessages] = useState('');

    const handleInputChange = (e) => {
        const { name, value, files } = e.target;
        setFormState(prevState => ({
            ...prevState,
            [name]: files ? files[0] : value
        }));
    };
    console.log("form state", formState)

    const handleSubmit = async (e) => {
        const token = localStorage.getItem('token');
        e.preventDefault();
        const formData = new FormData();
        formData.append('name', formState.name);
        formData.append('email', formState.email);
        formData.append('subject', formState.subject);
        formData.append('product', formState.product);
        formData.append('department', formState.department);
        formData.append('message', formState.message);
        formData.append('file', formState.file);

        for (let [key, value] of formData.entries()) {
            console.log(`${key}: ${value}`);
        }
        console.log("form data", formData)

        try {
            const response = await axios.post('https://node.instasales.in/api/v1/help-support', formData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    "Content-Type": "application/json",
                }
            });
            console.log("response", response)
            if (response.status == 201) {
                setMessages('Help and support request submitted successfully');
                setFormState(initialFormState);
            }
        } catch (error) {
            console.log("error", error)
            if (error.response && error.response.data.message) {
                setMessages(error.response.data.message);
            } else {
                setMessages('An error occurred while submitting the request');
            }
        }
    };


    const [subscriptions, setSubscriptions] = useState([]);

    useEffect(() => {
        const token = localStorage.getItem('token');

        const fetchSubscriptions = async () => {
            try {
                const response = await axios.get(`https://node.instasales.in/api/v1/subscriptions/${user._id}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    } // Include credentials (cookies)
                });
                setSubscriptions(response.data.subscriptions);
            } catch (error) {
                console.error('Error fetching subscriptions:', error);
            }
        };

        if (user) {
            fetchSubscriptions();
        }
    }, [user]);

    const handleUnsubscribe = async (subscribeToId, type) => {
        const token = localStorage.getItem('token');

        try {
            await axios.delete('https://node.instasales.in/api/v1/unsubscribe', {
                data: { subscribeToId, type },
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            // Remove the subscription from the state
            setSubscriptions(subscriptions.filter(sub => sub.subscribedTo._id !== subscribeToId));
        } catch (error) {
            console.error('Error unsubscribing:', error);
        }
    };

    console.log("subsription", subscriptions)


    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(false); // Loading state
    const [error, setError] = useState(null);

    useEffect(() => {
        const token = localStorage.getItem('token');
        const fetchOrders = async () => {
            setLoading(true); // Show loader

            try {
                const response = await axios.get('https://node.instasales.in/api/v1/orders/me', {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                setOrders(response.data.orders);
            } catch (error) {
                console.error('Error fetching orders:', error);
            } finally {
                setLoading(false); // Hide loader
            }
        };



        fetchOrders();
    }, []);

    // useEffect(() => {
    //     const updateOrderStatus = async () => {
    //         const token = localStorage.getItem('token');

    //         try {
    //             const response = await axios.put(
    //               'https://node.instasales.in/api/v1/orders/update-status',
    //               {},
    //               {
    //                 headers: {
    //                   Authorization: `Bearer ${token}`,
    //                 },
    //               }
    //             );
    //             if (response.data.success) {
    //               // Handle success
    //             }
    //           } catch (error) {
    //           setError(error.response?.data.message || 'Something went wrong');
    //         } 
    //       };


    //     updateOrderStatus();
    //   }, [orders]);


    console.log("orders", orders);



    const [activeKey, setActiveKey] = useState('info');

    const handleAddNewAddressClick = () => {
        setActiveKey('addAddress');
    };

    const [editMode, setEditMode] = useState(false);
    const initialUserData = {
        name: user?.name || '',
        email: user?.email || '',
        phone: user?.phone || '',
        gender: user?.gender || '',
        dob: user?.dob ? user?.dob.split('T')[0] : '',
    };

    const [userData, setUserData] = useState(initialUserData);


    useEffect(() => {


        setUserData(initialUserData)
    }, [user])


    const handleEdit = () => {
        setEditMode(true);
    };

    const handleSave = async () => {
        const token = localStorage.getItem('token');

        try {
            const response = await axios.put('https://node.instasales.in/api/v1/me/update', userData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (response.data.success) {
                console.log("response", response)
                setUser(response.data.user);
                setEditMode(false);
            }
        } catch (error) {
            console.error('Error updating profile:', error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserData({
            ...userData,
            [name]: value,
        });
    };

    const [imageSrc, setImageSrc] = useState('');
    const inputFileRef = useRef(null);

    useEffect(() => {
        const token = localStorage.getItem('token');

        const fetchProfilePhoto = async () => {
            try {
                const response = await axios.get('https://node.instasales.in/api/v1/get-profile-photo', {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });

                if (response.data.success) {
                    setImageSrc(response.data.url);
                } else {
                    console.error('Failed to fetch profile photo:', response.data.message);
                }
            } catch (error) {
                console.error('Error fetching profile photo:', error);
            }
        };

        fetchProfilePhoto();
    }, []);

    const handleImageUpload = async (event) => {
        const file = event.target.files[0];
        if (!file) return;
        const token = localStorage.getItem('token');


        const formData = new FormData();
        formData.append('imageFile', file);

        try {
            const response = await axios.post('https://node.instasales.in/api/v1/upload-profile-photo', formData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data.success) {
                setImageSrc(response.data.url);
                swal("Yayyy!", "Profile photo updated successfully!", "success");
            } else {
                swal("Oops!", response.data.message, "error");
            }
        } catch (error) {
            console.error('Error uploading image:', error);
            swal("Oops!", "Something went wrong!", "error");
        }
    };

    const [addresses, setAddresses] = useState([]);
    const [editAddressId, setEditAddressId] = useState(null);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phoneNumber: '',
        houseNo: '',
        street: '',
        landmark: '',
        town: '',
        city: '',
        state: '',
        postalCode: ''
    });

    useEffect(() => {
        fetchAddresses();
    }, []);

    const fetchAddresses = async () => {
        const token = localStorage.getItem('token');

        try {
            const response = await axios.get('https://node.instasales.in/api/v1/address', {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
            });
            setAddresses(response.data.addresses);
            console.log("address", response.data)
        } catch (error) {
            console.error('Error fetching addresses:', error);
        }
    };

    const fetchAddressDetails = async (pincode) => {
        try {
            const response = await axios.get(`https://api.postalpincode.in/pincode/${pincode}`);
            const data = response.data[0];
            console.log("data", data)
            if (data.Status === "Success" && data.PostOffice.length > 0) {
                const { District, block, State, Pincode } = data.PostOffice[0];
                setFormData({ ...formData, town: District || block, city: District || block, state: State, postalCode: Pincode });
            } else {
                console.error("Invalid pincode or no data found.");
            }
        } catch (error) {
            console.error('Error fetching address details:', error);
        }
    };
    const handleInputChangeaddress = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));

        // If the field is postalCode and the length is 6, fetch the address details
        if (name === 'postalCode' && value.length === 6) {
            fetchAddressDetails(value);
        }
    };
    const handleAddAddress = async () => {
        const token = localStorage.getItem('token');

        try {
            const response = await axios.post('https://node.instasales.in/api/v1/address', formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
            });
            if (response.data.success) {
                fetchAddresses();
                setFormData({
                    name: '',
                    email: '',
                    phoneNumber: '',
                    houseNo: '',
                    street: '',
                    landmark: '',
                    town: '',
                    city: '',
                    state: '',
                    postalCode: ''
                });
                setActiveKey("address");
                swal("Success!", "Address added successfully!", "success");
            }
        } catch (error) {
            console.error('Error adding address:', error);
            swal("Oops!", "Something went wrong while adding the address!", "error");
        }
    };

    const handleUpdateAddress = async () => {
        const token = localStorage.getItem('token');

        try {
            const response = await axios.put(`https://node.instasales.in/api/v1/address/${editAddressId}`, formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
            });
            if (response.data.success) {
                fetchAddresses();
                setFormData({
                    name: '',
                    email: '',
                    phoneNumber: '',
                    houseNo: '',
                    street: '',
                    landmark: '',
                    town: '',
                    city: '',
                    state: '',
                    postalCode: ''
                });
                setActiveKey("address");
                swal("Success!", "Address updated successfully!", "success");
            }
        } catch (error) {
            console.error('Error updating address:', error);
            swal("Oops!", "Something went wrong while updating the address!", "error");
        }
    };

    const handleDeleteAddress = async (id) => {
        const token = localStorage.getItem('token');

        try {
            const response = await axios.delete(`https://node.instasales.in/api/v1/address/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
            });
            if (response.data.success) {
                fetchAddresses();
                swal("Deleted!", "Address deleted successfully!", "success");
            }
        } catch (error) {
            console.error('Error deleting address:', error);
            swal("Oops!", "Something went wrong while deleting the address!", "error");
        }
    };

    const handleEditAddress = (address) => {
        setFormData(address);
        setEditAddressId(address._id);
        setActiveKey("editAddress");
    };

    const handleHoverDivClick = (link) => {
        window.location.href = link;
    };

    const cancelOrder = async (orderId, awbNumber) => {
        const token = localStorage.getItem('token');

        try {
            const response = await axios.post('https://node.instasales.in/api/v1/order/cancel', { orderId, awbNumber }, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            alert('Order canceled successfully');
            console.log("response,", response)
            // Optionally, refetch orders or update the state
        } catch (error) {
            console.error('Error canceling order:', error);
            alert('Failed to cancel order');
        }
    };
    const [trackingInfo, setTrackingInfo] = useState(null);
    const [selectedOrderId, setSelectedOrderId] = useState(null);

    const trackOrder = async (orderId, awbNumber) => {
        const token = localStorage.getItem('token');

        console.log("awbnum", awbNumber);
        console.log("awbnumtype", typeof (awbNumber));

        try {
            const response = await axios.get(`https://node.instasales.in/api/v1/order/track/${awbNumber}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (response.data.success) {
                setTrackingInfo(response.data.trackResponse);
                setSelectedOrderId(orderId);
            } else {
                console.error('Tracking failed:', response.data.message);
                alert('Failed to track order: ' + response.data.message);
            }
        } catch (error) {
            console.error('Error tracking order:', error);
            alert('Failed to track order: ' + error.message);
        }
    };
    const reversedOrders = [...orders].reverse();
    const [searchQuery, setSearchQuery] = useState('');


    const filteredOrders = reversedOrders.filter(order =>
        order?.orderItems[0]?.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    // const { order_id, refrence_id, awb_number, courier, current_status, scan_detail } = trackingInfo?.data;
    console.log("trvking info", trackingInfo)

    // Determine progress based on the current status
    const getProgress = (status) => {
        switch (status) {
            case 'Order Placed':
                return 20;
            case 'Pickup Pending':
                return 40;
            case 'Shipped':
                return 60;
            case 'Out For Delivery':
                return 80;
            case 'Delivered':
                return 100;
            default:
                return 0;
        }
    };

    const progress = getProgress(trackingInfo?.data?.current_status);

    const calculateEstimatedDeliveryDate = (createdAt) => {
        const date = new Date(createdAt);
        date.setDate(date.getDate() + 15);
        return date.toDateString();
    };

    const [searchQuerys, setSearchQuerys] = useState('');

    // Filter subscriptions based on search query
    const filteredSubscriptions = subscriptions.filter((sub) => {
        const name = sub.subscribedTo?.creatorName || sub.subscribedTo?.brandName || '';
        return name.toLowerCase().includes(searchQuerys.toLowerCase());
    });
    return (
        <div className="profile">
            <img className="profile-bg" src={pb} />
            <div className="profile-image-container">
                <div className="upload-img">
                    <img src={upload} alt="" />
                    <input type="file" ref={inputFileRef} onChange={handleImageUpload} style={{ display: 'none' }} />
                </div>
                <div className="profile-image-upload" onClick={() => inputFileRef.current.click()}>Upload</div>
                {/* <img className="profile-pic"  src={pp} /> */}
                <img src={imageSrc ? imageSrc : place} alt="" className="profile-pic profile-image" />
            </div>
            <Tab.Container defaultActiveKey="info" activeKey={activeKey} onSelect={(k) => setActiveKey(k)}>
                <Nav className="profile-tabs-nav">
                    <Nav.Item className="profile-tab">
                        <Nav.Link className="profile-tab-btn" eventKey="info"><FontAwesomeIcon icon={faUser} />&nbsp;Profile Info</Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="profile-tab">
                        <Nav.Link className="profile-tab-btn" eventKey="password"><FontAwesomeIcon icon={faLock} />&nbsp;Password & Security</Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="profile-tab">
                        <Nav.Link className="profile-tab-btn" eventKey="order"><FontAwesomeIcon icon={faBoxOpen} />&nbsp;Your Orders</Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="profile-tab">
                        <Nav.Link className="profile-tab-btn" eventKey="help"><FontAwesomeIcon icon={faCircleQuestion} />&nbsp;Help & Support</Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="profile-tab">
                        <Nav.Link className="profile-tab-btn" eventKey="address"><FontAwesomeIcon icon={faLocationDot} />&nbsp;Manage Address</Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="profile-tab">
                        <Nav.Link className="profile-tab-btn" eventKey="subscription"><FontAwesomeIcon icon={faListCheck} />&nbsp;Subscriptions</Nav.Link>
                    </Nav.Item>
                </Nav>
                <Tab.Content>
                    <Tab.Pane eventKey="info" className="profile-tab-content">
                        <div className="profile-h">
                            <FontAwesomeIcon icon={faUser} />&nbsp;Profile Info
                        </div>
                        <Row className="profile-info-1">
                            <InputGroup as={Col} className="profile-input">
                                <InputGroup.Text>Full Name</InputGroup.Text>
                                <Form.Control
                                    type="text"
                                    name="name"
                                    value={userData?.name}
                                    readOnly={!editMode}
                                    onChange={handleChange}
                                />
                            </InputGroup>
                            <InputGroup as={Col} className="profile-input">
                                <InputGroup.Text>Email</InputGroup.Text>
                                <Form.Control
                                    type="email"
                                    name="email"
                                    value={userData.email}
                                    readOnly={!editMode}
                                    onChange={handleChange}
                                    disabled={true}
                                />
                            </InputGroup>
                            <InputGroup as={Col} className="profile-input">
                                <InputGroup.Text>+91</InputGroup.Text>
                                <Form.Control
                                    type="tel"
                                    name="phone"
                                    value={userData.phone}
                                    readOnly={!editMode}
                                    onChange={handleChange}
                                    disabled={true}
                                />
                            </InputGroup>
                        </Row>
                        <Row className="profile-info-2">
                            <InputGroup as={Col} className="profile-input">
                                <InputGroup.Text>Gender</InputGroup.Text>
                                <Form.Select
                                    name="gender"
                                    value={userData.gender}
                                    disabled={!editMode}
                                    onChange={handleChange}
                                >
                                    <option>---- Select a gender ----</option>
                                    <option value="Female">Female</option>
                                    <option value="Male">Male</option>
                                    <option value="Other">Other</option>
                                </Form.Select>
                            </InputGroup>
                            <InputGroup as={Col} className="profile-input">
                                <InputGroup.Text>Date of Birth</InputGroup.Text>
                                <Form.Control
                                    type="date"
                                    name="dob"
                                    value={userData.dob}
                                    readOnly={!editMode}
                                    onChange={handleChange}
                                />
                            </InputGroup>
                        </Row>
                        {editMode ? (
                            <Button className="profile-btn" onClick={handleSave}>Save</Button>
                        ) : (
                            <Button className="profile-btn" onClick={handleEdit}>Edit</Button>
                        )}
                    </Tab.Pane>

                    <Tab.Pane eventKey="password" className="profile-tab-content">
                        <div className="profile-h">
                            <FontAwesomeIcon icon={faLock} />&nbsp;Password & Security
                        </div>
                        {message && <div className="alert alert-info">{message}</div>}
                        <Row className="profile-info-1">
                            <InputGroup as={Col} className="profile-input">
                                <InputGroup.Text>Old Password</InputGroup.Text>
                                <Form.Control
                                    type="password"
                                    value={oldPassword}
                                    onChange={(e) => setOldPassword(e.target.value)}
                                />
                            </InputGroup>
                            <InputGroup as={Col} className="profile-input">
                                <InputGroup.Text>New Password</InputGroup.Text>
                                <Form.Control
                                    type="password"
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                />
                            </InputGroup>
                            <InputGroup as={Col} className="profile-input">
                                <InputGroup.Text>Confirm New Password</InputGroup.Text>
                                <Form.Control
                                    type="password"
                                    value={confirmNewPassword}
                                    onChange={(e) => setConfirmNewPassword(e.target.value)}
                                />
                            </InputGroup>
                        </Row>
                        <Button className="profile-btn" onClick={handlePasswordUpdate}>Update</Button>
                    </Tab.Pane>
                    <Tab.Pane eventKey="order" className="profile-tab-content">
                        <div className="profile-h"><FontAwesomeIcon icon={faBoxOpen} />&nbsp;Your Orders</div>
                        <InputGroup className="profile-search">
                            <Form.Control
                                type="search"
                                placeholder="Search your orders"
                                className="profile-search-input-box"
                                aria-label="Search"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                            <Button className="profile-search-button">
                                <InputGroup.Text className="profile-search-logo"><FontAwesomeIcon icon={faMagnifyingGlass} size="xs" /></InputGroup.Text>
                            </Button>
                        </InputGroup>
                        <div className="profile-products">
                            {loading ? (
                                <div className="wishlist-loading-product">
                                    <ClipLoader size={50} color={"#123abc"} loading={loading} />

                                </div>
                            ) : (
                                filteredOrders.length === 0 ? (
                                    <div className='no-orders'>No orders found.</div>
                                ) : (
                                    filteredOrders?.map((order, index) => (
                                        <Row className="profile-product" key={index}>
                                            {order.orderItems.map((ord, ordIndex) => (
                                                <>
                                                    <Col lg={2} className='order-img'>
                                                        <Link to={`/product/${ord?.product?.brand_slug}/${ord.product?._id}`}>
                                                            <img className='ordered-item-img' src={ord?.product?.images[0]?.url || fw} alt="Order item" />
                                                        </Link>
                                                    </Col>
                                                    <Col lg={4}>
                                                        <div className="profile-product-h">{ord?.name}</div>
                                                        <div className="profile-product-muted">Brand: {ord?.product?.brand_name || "Unknown"}</div>
                                                        <div className="profile-product-muted">Variant: {ord.variation?.name || "N/A"}</div>
                                                    </Col>
                                                    <Col lg={1} className="profile-product-price">₹{ord.price}</Col>
                                                    <Col lg={3}>
                                                        <div className="profile-product-txt">
                                                            Estimated Delivery: <span className="bold">{calculateEstimatedDeliveryDate(order.createdAt)}</span>
                                                        </div>
                                                        <div className="profile-product-muted">Your Order has been {order.orderStatus}</div>
                                                    </Col>
                                                    <Col lg={2}>
                                                        <Button className="profile-track" onClick={() => trackOrder(order?._id, order.awbNumber)}>Track</Button>
                                                        <Button className="profile-cancel" onClick={() => cancelOrder(order?.order_id, order.awbNumber)}>Cancel</Button>
                                                    </Col>
                                                    {ordIndex < order.orderItems.length - 1 && (
                                                        <div style={{ width: '100%', height: '1px', background: '#C6C6C6', margin: '10px 0' }} />
                                                    )}
                                                </>
                                            ))}
                                            {/* <Col lg={2} className='order-img'>
                                                <Link to={`/product/${order?.orderItems[0]?.product?._id}`}>
                                                    <img className='ordered-item-img' src={order?.orderItems[0]?.product?.images[0].url || fw} alt="Order item" />
                                                </Link>
                                            </Col>
                                            <Col lg={4}>
                                                <div className="profile-product-h">{order?.orderItems[0]?.name}</div>
                                                <div className="profile-product-muted">Brand: {order?.orderItems[0]?.product?.brand_name || "Unknown"}</div>
                                                <div className="profile-product-muted">Variant: {order.orderItems[0].variation.name || "N/A"}</div>
                                            </Col>
                                            <Col lg={1} className="profile-product-price">₹{order?.totalPrice}</Col>
                                            <Col lg={3}>
                                                <div className="profile-product-txt">
                                                    Estimated Delivery: <span className="bold">{calculateEstimatedDeliveryDate(order.createdAt)}</span>
                                                </div>
                                                <div className="profile-product-muted">Your Order has been {order.orderStatus}</div>
                                            </Col>
                                            <Col lg={2}>
                                                <Button className="profile-track" onClick={() => trackOrder(order?._id, order.awbNumber)}>Track</Button>
                                                <Button className="profile-cancel" onClick={() => cancelOrder(order?.order_id, order.awbNumber)}>Cancel</Button>

                                            </Col> */}
                                            {selectedOrderId === order._id && trackingInfo && (
                                                <div className="tracking-info-container">
                                                    <Row>
                                                        <Col lg={12}>
                                                            <div className="tracking-info-header text-center">
                                                                <h3 className="font-weight-bold">{order?.orderItems[0]?.name}</h3>
                                                                <div className="font-italic">Brand: {order?.orderItems[0]?.product?.brand_name || 'Unknown'}</div>
                                                                <div className="tracking-info-price display-4">₹{order.totalPrice}</div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row className="justify-content-center">
                                                        <Col lg={8}>
                                                            <ProgressBar now={progress} className="tracking-progress">
                                                                <ProgressBar striped variant={progress >= 20 ? "success" : "secondary"} now={20} label={<FontAwesomeIcon icon={faCheckCircle} />} />
                                                                <ProgressBar striped variant={progress >= 40 ? "success" : "secondary"} now={20} label={<FontAwesomeIcon icon={faTruckLoading} />} />
                                                                <ProgressBar striped variant={progress >= 60 ? "success" : "secondary"} now={20} label={<FontAwesomeIcon icon={faShippingFast} />} />
                                                                <ProgressBar striped variant={progress >= 80 ? "success" : "secondary"} now={20} label={<FontAwesomeIcon icon={faBoxOpen} />} />
                                                                <ProgressBar striped variant={progress >= 100 ? "success" : "secondary"} now={20} label={<FontAwesomeIcon icon={faHome} />} />
                                                            </ProgressBar>
                                                        </Col>
                                                    </Row>
                                                    <Row className="text-center mt-4">
                                                        <Col>
                                                            <Button className="profile-track-link" variant="primary" href={`https://app.shipmozo.com/track-shipment/SEkyLYUDlu3eFvApnVhs?awb=${order.awbNumber}`} target="_blank">
                                                                Track Your Order
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                    <Row className="tracking-status-row mt-3">
                                                        <Col>
                                                            <h5 className="text-primary">Your Order has been {trackingInfo?.data.current_status}</h5>
                                                        </Col>
                                                    </Row>
                                                    <Row className="tracking-details text-center">
                                                        <Col>
                                                            {trackingInfo?.data.scan_detail?.map((scan, index) => (
                                                                <div key={index} className="tracking-scan-detail mt-2">
                                                                    <strong>{scan.date}</strong> - <span>{scan.status}</span>
                                                                </div>
                                                            ))}
                                                        </Col>
                                                    </Row>
                                                </div>
                                            )}


                                        </Row>
                                    ))
                                )
                            )}

                        </div>

                    </Tab.Pane>
                    <Tab.Pane eventKey="help" className="profile-tab-content">
                        <div className="profile-h">
                            <FontAwesomeIcon icon={faCircleQuestion} />&nbsp;Help & Support
                        </div>
                        {messages && <div className="alert alert-info">{messages}</div>}
                        <Form className="profile-help-form" onSubmit={handleSubmit}>
                            <Row>
                                <InputGroup as={Col} className="profile-input">
                                    <InputGroup.Text>Name</InputGroup.Text>
                                    <Form.Control type="text" name="name" value={formState.name} onChange={handleInputChange} />
                                </InputGroup>
                                <InputGroup as={Col} className="profile-input">
                                    <InputGroup.Text>Email</InputGroup.Text>
                                    <Form.Control type="text" name="email" value={formState?.email} onChange={handleInputChange} />
                                </InputGroup>
                            </Row>
                            <Row>
                                <InputGroup as={Col} className="profile-input">
                                    <InputGroup.Text>Subject</InputGroup.Text>
                                    <Form.Control type="text" name="subject" value={formState.subject} onChange={handleInputChange} />
                                </InputGroup>
                                <InputGroup as={Col} className="profile-input">
                                    <InputGroup.Text>Product</InputGroup.Text>
                                    <Form.Select
                                        name="product"
                                        value={formState.product}
                                        onChange={handleInputChange}
                                    >
                                        <option>---- Select a product ----</option>
                                        {orders?.map((order) =>
                                            order.orderItems?.map((item) => (
                                                <option
                                                    key={item?.product?._id}
                                                    value={item?.product?._id}
                                                >
                                                    {item?.product?.name}
                                                </option>
                                            ))
                                        )}
                                        <option key={""}>No product</option>
                                    </Form.Select>

                                </InputGroup>
                                <InputGroup as={Col} className="profile-input">
                                    <InputGroup.Text>Department</InputGroup.Text>
                                    <Form.Select name="department" value={formState.department} onChange={handleInputChange}>
                                        <option>---- Select a department ----</option>
                                        <option value="A">A</option>
                                        <option value="B">B</option>
                                        <option value="C">C</option>
                                    </Form.Select>
                                </InputGroup>
                            </Row>
                            <Row>
                                <Form.Control className="profile-input ta" as="textarea" rows={4} name="message" value={formState.message} onChange={handleInputChange} />
                            </Row>
                            <Row>
                                <InputGroup as={Col} className="profile-input">
                                    <InputGroup.Text>Screenshot</InputGroup.Text>
                                    <Form.Control type="file" name="file" onChange={handleInputChange} />
                                </InputGroup>
                            </Row>
                            <Button className="profile-help-btn" type="submit">Submit</Button>
                        </Form>
                    </Tab.Pane>
                    <Tab.Pane eventKey="address" className="profile-tab-content">
                        <div className="profile-h"><FontAwesomeIcon icon={faLocationDot} />&nbsp;Manage Address</div>
                        <div className="profile-addresses d-flex align-content-start flex-wrap">
                            <div className="profile-address">
                                <center>
                                    <Button className="profile-addnew bold" onClick={handleAddNewAddressClick}><FontAwesomeIcon icon={faPlus} /> Add New Address</Button>
                                </center>
                            </div>
                            {addresses.slice().reverse().map((address) => (  // Reversed the addresses array
                                <div className="profile-address" key={address?._id}>
                                    <div className="profile-action-items">
                                        <Form.Check inline className="profile-check" name="address1" type="radio" id={`address-${address._id}`} />
                                        <span className="profile-actions">
                                            <FontAwesomeIcon className="profile-edit" icon={faPenToSquare} onClick={() => handleEditAddress(address)} />
                                            <FontAwesomeIcon className="profile-delete" icon={faTrash} onClick={() => handleDeleteAddress(address._id)} />
                                        </span>
                                    </div>
                                    <div className="profile-line1 bold">
                                        {address?.name}, {address.postalCode}
                                    </div>
                                    <div className="profile-line2">
                                        {address.houseNo}, {address.street}, {address.landmark}<br />
                                        {address.city}, {address.state}, {address.country}
                                    </div>
                                    <div className="profile-line3">Ph no. {address.phoneNumber}</div>
                                </div>
                            ))}
                        </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="addAddress" className="profile-tab-content">
                        <div className="profile-h"><FontAwesomeIcon icon={faPlus} />&nbsp;Add New Address</div>
                        <Row className="profile-info">
                            <Col>
                                <InputGroup as={Col} className="profile-input  profile-input2">
                                    <InputGroup.Text>Full Name</InputGroup.Text>
                                    <Form.Control type="text" name="name" placeholder="Full Name" value={formData?.name} onChange={handleInputChangeaddress} />
                                </InputGroup>
                                <InputGroup as={Col} className="profile-input profile-input2">
                                    <InputGroup.Text>Email</InputGroup.Text>
                                    <Form.Control type="mail" name="email" placeholder="Email" value={formData.email} onChange={handleInputChangeaddress} />
                                </InputGroup>
                                <InputGroup className="profile-input profile-input2">
                                    <InputGroup.Text>+91</InputGroup.Text>
                                    <Form.Control type="number" name="phoneNumber" placeholder="Continue with Mobile Number" value={formData.phoneNumber} onChange={handleInputChangeaddress} />
                                </InputGroup>
                            </Col>
                            <Col>
                                <InputGroup className="profile-input profile-input2">
                                    <InputGroup.Text>Flat, House no. , Apartment</InputGroup.Text>
                                    <Form.Control type="text" name="houseNo" placeholder="Flat, House no. , Apartment" value={formData.houseNo} onChange={handleInputChangeaddress} />
                                </InputGroup>
                                <InputGroup className="profile-input profile-input2">
                                    <InputGroup.Text>Area, Street, Sector, Village</InputGroup.Text>
                                    <Form.Control type="text" name="street" placeholder="Area, Street, Sector, Village" value={formData.street} onChange={handleInputChangeaddress} />
                                </InputGroup>
                                <InputGroup className="profile-input profile-input2">
                                    <InputGroup.Text>Landmark</InputGroup.Text>
                                    <Form.Control type="text" name="landmark" placeholder="Landmark" value={formData.landmark} onChange={handleInputChangeaddress} />
                                </InputGroup>
                            </Col>
                            <Col>
                                <InputGroup className="profile-input profile-input2">
                                    <InputGroup.Text>Pincode</InputGroup.Text>
                                    <Form.Control type="number" name="postalCode" placeholder="Pincode" value={formData.postalCode} onChange={handleInputChangeaddress} />
                                </InputGroup>
                                <InputGroup className="profile-input profile-input2">
                                    <InputGroup.Text>Town / City</InputGroup.Text>
                                    <Form.Control type="text" name="city" placeholder="Town / City" value={formData.city} onChange={handleInputChangeaddress} />
                                </InputGroup>
                                <InputGroup className="profile-input profile-input2">
                                    <InputGroup.Text>State</InputGroup.Text>
                                    <Form.Control type="text" placeholder="State" name="state" value={formData.state} onChange={handleInputChangeaddress} />
                                </InputGroup>
                                {/* <InputGroup className="profile-input profile-input2">
                                    <Form.Select name="state" value={formData.state} onChange={handleInputChangeaddress}>
                                        <option>Choose a State</option>
                                        <option value={'Haryana'}>Haryana</option>
                                    </Form.Select>
                                </InputGroup> */}
                            </Col>
                        </Row>
                        <Button className="profile-btn" onClick={handleAddAddress}>Add Address</Button>
                    </Tab.Pane>

                    <Tab.Pane eventKey="editAddress" className="profile-tab-content">
                        <div className="profile-h"><FontAwesomeIcon icon={faPenToSquare} />&nbsp;Edit Address</div>
                        <Row className="profile-info">
                            <Col>
                                <InputGroup as={Col} className="profile-input  profile-input2">
                                    <InputGroup.Text>Full Name</InputGroup.Text>
                                    <Form.Control type="text" name="name" placeholder="Full Name" value={formData.name} onChange={handleInputChangeaddress} />
                                </InputGroup>
                                <InputGroup as={Col} className="profile-input profile-input2">
                                    <InputGroup.Text>Email</InputGroup.Text>
                                    <Form.Control type="mail" name="email" placeholder="Email" value={formData.email} onChange={handleInputChangeaddress} />
                                </InputGroup>
                                <InputGroup className="profile-input profile-input2">
                                    <InputGroup.Text>+91</InputGroup.Text>
                                    <Form.Control type="number" name="phoneNumber" placeholder="Continue with Mobile Number" value={formData.phoneNumber} onChange={handleInputChangeaddress} />
                                </InputGroup>
                            </Col>
                            <Col>
                                <InputGroup className="profile-input profile-input2">
                                    <InputGroup.Text>Flat, House no. , Apartment</InputGroup.Text>
                                    <Form.Control type="text" name="houseNo" placeholder="Flat, House no. , Apartment" value={formData.houseNo} onChange={handleInputChangeaddress} />
                                </InputGroup>
                                <InputGroup className="profile-input profile-input2">
                                    <InputGroup.Text>Area, Street, Sector, Village</InputGroup.Text>
                                    <Form.Control type="text" name="street" placeholder="Area, Street, Sector, Village" value={formData.street} onChange={handleInputChangeaddress} />
                                </InputGroup>
                                <InputGroup className="profile-input profile-input2">
                                    <InputGroup.Text>Landmark</InputGroup.Text>
                                    <Form.Control type="text" name="landmark" placeholder="Landmark" value={formData.landmark} onChange={handleInputChangeaddress} />
                                </InputGroup>
                            </Col>
                            <Col>
                                <InputGroup className="profile-input profile-input2">
                                    <InputGroup.Text>Pincode</InputGroup.Text>
                                    <Form.Control type="number" name="postalCode" placeholder="Pincode" value={formData.postalCode} onChange={handleInputChangeaddress} />
                                </InputGroup>
                                <InputGroup className="profile-input profile-input2">
                                    <InputGroup.Text>Town / City</InputGroup.Text>
                                    <Form.Control type="text" name="city" placeholder="Town / City" value={formData.city} onChange={handleInputChangeaddress} />
                                </InputGroup>
                                <InputGroup className="profile-input profile-input2">
                                    <InputGroup.Text>State</InputGroup.Text>
                                    <Form.Control type="text" placeholder="State" name="state" value={formData.state} onChange={handleInputChangeaddress} />
                                </InputGroup>
                                {/* <InputGroup className="profile-input profile-input2">
                                    <Form.Select name="state" value={formData.state} onChange={handleInputChangeaddress}>
                                        <option>Choose a State</option>
                                        <option value={'Haryana'}>Haryana</option>
                                    </Form.Select>
                                </InputGroup> */}
                            </Col>
                        </Row>
                        <Button className="profile-btn" onClick={handleUpdateAddress}>Save Changes</Button>
                    </Tab.Pane>

                    <Tab.Pane eventKey="subscription" className="profile-tab-content">
                        <div className="profile-h">
                            <FontAwesomeIcon icon={faListCheck} />&nbsp;Subscriptions
                        </div>

                        {/* Search bar */}
                        <InputGroup className="profile-search">
                            <Form.Control
                                type="search"
                                placeholder="Search your subscriptions"
                                className="profile-search-input-box"
                                aria-label="Search"
                                value={searchQuerys}
                                onChange={(e) => setSearchQuerys(e.target.value)}
                            />
                            <Button className="profile-search-button">
                                <InputGroup.Text className="profile-search-logo">
                                    <FontAwesomeIcon icon={faMagnifyingGlass} size="xs" />
                                </InputGroup.Text>
                            </Button>
                        </InputGroup>

                        <div className="profile-sub-cards">
                            {filteredSubscriptions.length === 0 ? (
                                <div className="no-subscriptions">No subscriptions found.</div>
                            ) : (
                                filteredSubscriptions.slice().reverse().map((sub) => (
                                    <div className="profile-sub-card" key={sub._id}>
                                        <img
                                            src={sub.subscribedTo?.avatar ? sub.subscribedTo?.avatar.url : sub.subscribedTo?.brandLogo?.url}
                                            className="profile-sub-img"
                                            alt="profile"
                                            onClick={() =>
                                                handleHoverDivClick(sub.subscribedTo?.creatorName
                                                    ? `/creator/${sub.subscribedTo._id}`
                                                    : `/brand/${sub.subscribedTo._id}`)
                                            }
                                        />
                                        <div className="profile-sub-txt">
                                            <div
                                                className="profile-sub-h"
                                                onClick={() =>
                                                    handleHoverDivClick(sub.subscribedTo?.creatorName
                                                        ? `/creator/${sub.subscribedTo._id}`
                                                        : `/brand/${sub.subscribedTo._id}`)
                                                }
                                            >
                                                {sub.subscribedTo?.creatorName || sub.subscribedTo?.brandName}
                                            </div>
                                            <div className="profile-sub-subh">
                                                {sub.subscribedTo?.followers && (
                                                    <>Followers: {sub.subscribedTo?.followers} Followers<br /></>
                                                )}
                                                {sub.subscribedTo?.creatorType && sub.subscribedTo.creatorType}
                                            </div>
                                            <Button
                                                className="profile-sub-btn"
                                                onClick={() => handleUnsubscribe(sub.subscribedTo._id, sub.onModel)}
                                            >
                                                Unsubscribe
                                            </Button>
                                        </div>
                                    </div>
                                ))
                            )}
                        </div>
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
        </div>
    )
}

export default Profile
