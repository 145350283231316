import React, { useState, useRef, useEffect } from 'react';
import swal from 'sweetalert';
import Carousel from "react-multi-carousel";
import { Button, Card, Col, Row, Modal, Form } from "react-bootstrap";
import cardbg from "../../media/card.png";
import { faFilter, faCaretLeft, faCaretRight, faBell, faBellSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import ClipLoader from 'react-spinners/ClipLoader';
import "./brand.css";
import BrandSeectedCategory from './BrandSeectedCategory';
import p1 from "../../media/p1.png";
import RangeSlider from 'react-bootstrap-range-slider';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import Slider from '@mui/material/Slider';


const BrandCategory = () => {
    const { slug } = useParams();
    const { Category } = useParams();
    const [isSubscribed, setIsSubscribed] = useState(false);
    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const [brandLoading, setBrandLoading] = useState(true); // Add brand loading state
    const [productsLoading, setProductsLoading] = useState(true); // Add products loading state
    const [selectedCubcategory, setSelectedCubcategory] = useState(null)
    const [selectedFilters, setSelectedFilters] = useState({
        subCategory: '',
        sort: '',
        discount: '',
        brands: [],
        priceRange: [0, 800],
        discountRange: [10, 95]
    });
    const [maxPrice, setMaxPrice] = useState(1000);

    
    const carouselRefs = useRef({});
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);
    const alert1 = () => {
        handleClose1();
        swal("Yayyy!", "Message sent successfully!", "success");
    }
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 3
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    const [brand, setBrand] = useState(null);

    const checkSubscriptionStatus = async () => {
        const token = localStorage.getItem('token');

        try {
            const response = await axios.get(`https://node.instasales.in/api/v1/check-subscription-status?subscribeToId=${brand?._id}`, {
                            headers: {
              'Authorization': `Bearer ${token}`
            }
            });
            setIsSubscribed(response.data.isSubscribed);
        } catch (error) {
            console.error('Error checking subscription status:', error);
        }
    };

    const [products, setProducts] = useState([]);
    const [groupedProducts, setGroupedProducts] = useState({});
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState({});
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedSubCategory, setSelectedSubCategory] = useState('');

    const extractCategoriesAndSubCategories = (products) => {
        const categoryMap = {};
        products.forEach(product => {
            const { category, sub_category } = product;
            if (category) {
                if (!categoryMap[category]) {
                    categoryMap[category] = new Set();
                }
                if (sub_category) {
                    categoryMap[category].add(sub_category);
                }
            }
        });
        setCategories(Object.keys(categoryMap));
        setSubCategories(Object.fromEntries(Object.entries(categoryMap).map(([k, v]) => [k, Array.from(v)])));
    };

    const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value);
        setSelectedSubCategory(''); // Reset subcategory when category changes
    };

    const handleSubCategoryChange = (event) => {
        setSelectedSubCategory(event.target.value);
    };

console.log("gropued product",groupedProducts)
    const handleCarouselPrev = (key) => {
        carouselRefs.current[key].previous();
    };

    const handleCarouselNext = (key) => {
        carouselRefs.current[key].next();
    };

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const encodedCategory = encodeURIComponent(Category);
                const response = await axios.get(`https://node.instasales.in/api/v1//brand/product/${brand?._id}/${encodedCategory}`);
                 console.log("response", response)
                setProducts(response.data.products); // Assuming the API response contains a "products" field
                groupProductsByCategory(response.data.products)
                setProductsLoading(false)
                extractCategoriesAndSubCategories(response.data.products);

            } catch (error) {
                console.error('Error fetching products:', error);
                setProductsLoading(false)

            }
        };
        fetchProducts();
    }, [Category, brand?._id]);


   

   
    useEffect(() => {
        const fetchCreatorData = async () => {
            try {
                const response = await axios.get(`https://node.instasales.in/api/v1/brand/brand/${slug}`);
                setBrand(response.data.brand);
                checkSubscriptionStatus();
                setBrandLoading(false); // Set brand loading to false after data is fetched
            } catch (error) {
                console.error('Error fetching creator data:', error);
                setBrandLoading(false); // Set brand loading to false in case of error
            }
        };

        fetchCreatorData();
        checkSubscriptionStatus();

    }, [brand?._id]);

    const [sortedby, setSortedby] = useState()

    console.log("srorted by", sortedby)

    const groupProductsByCategory = (products) => {
       

        const grouped = products.reduce((acc, product) => {
            let Category;
                Category = product.sub_category || 'Others';
             

            if (!acc[Category]) {
                acc[Category] = [];
            }
            acc[Category].push(product);
            return acc;
        }, {});

        setGroupedProducts(grouped);
    };
    const handleSubscribe = async () => {
        const token = localStorage.getItem('token');

        try {
            const response = await axios.post('https://node.instasales.in/api/v1/subscribe', {
                subscribeToId: brand?._id,
                type: 'Brand'
            }, {
                            headers: {
              'Authorization': `Bearer ${token}`
            }
            });

            if (response.data.success) {
                swal("Subscribed!", response.data.message, "success");
                checkSubscriptionStatus();
            } else {
                swal("Oops!", response.data.message, "error");
            }
        } catch (error) {
            console.error('Error subscribing:', error);
            swal("Oops!", "Something went wrong!", "error");
        }
    };

    const handleUnsubscribe = async () => {
        const token = localStorage.getItem('token');

        try {
            const response = await axios.delete('https://node.instasales.in/api/v1/unsubscribe', {
                data: {
                    subscribeToId: brand?._id,
                    type: 'Brand'
                },
                            headers: {
              'Authorization': `Bearer ${token}`
            }
            });

            if (response.data.success) {
                setIsSubscribed(false);
                swal("Unsubscribed!", response.data.message, "success");
            } else {
                swal("Oops!", response.data.message, "error");
            }
        } catch (error) {
            console.error('Error unsubscribing:', error);
            swal("Oops!", "Something went wrong!", "error");
        }
    };

    const goToPrev = (subCategory) => {
        carouselRefs.current[subCategory].previous();
    };

    const goToNext = (subCategory) => {
        carouselRefs.current[subCategory].next();
    };

    if (brandLoading) {
        return (
            <div className="wishlist-loading">
                <ClipLoader size={50} color={"#123abc"} loading={brandLoading} />
            </div>
        );
    }


    const handleHoverDivClick = (link, subCategory) => {
        if (sortedby == 'category') {

            window.location.href = `/brand/${slug}/category/${subCategory}`;
        } else {

            setSelectedCubcategory(subCategory)
        }


    };

    const handlePriceRangeChange = (value) => {
        setSelectedFilters(prevFilters => ({
            ...prevFilters,
            priceRange: [0, value]
        }));
    };

    const handleDiscountRangeChange = (event) => {
        const value = event.target.value;
        setSelectedFilters(prevFilters => ({
            ...prevFilters,
            discountRange: [10, value]
        }));
    };


    const applyFilters = () => {
        let filtered = [...products];

        // Apply category and subcategory filter
        if (selectedCategory) {
            filtered = filtered.filter(product => product.category === selectedCategory);
        }
        if (selectedSubCategory) {
            filtered = filtered.filter(product => product.sub_category === selectedSubCategory);
        }

        // Apply price range filter
        const [minPrice, maxPrice] = selectedFilters.priceRange;
        filtered = filtered.filter(product => {
            const price = product.variations[0]?.options[0].selling_price;
            return price >= minPrice && price <= maxPrice;
        });

        // Filter by brands
        if (selectedFilters.brands.length > 0) {
            filtered = filtered.filter(product => selectedFilters.brands.includes(product.brand_name));
        }

        // Apply sorting
        if (selectedFilters.sort) {
            if (selectedFilters.sort === '1') { // Trending
                filtered = filtered.sort((a, b) => b.popularity - a.popularity); // Assuming popularity is a field
            } else if (selectedFilters.sort === '2') { // Price: High to Low
                filtered = filtered.sort((a, b) => b.variations[0]?.options[0].selling_price - a.variations[0]?.options[0].selling_price);
            } else if (selectedFilters.sort === '3') { // Price: Low to High
                filtered = filtered.sort((a, b) => a.variations[0]?.options[0].selling_price - b.variations[0]?.options[0].selling_price);
            }
        }

        setProducts(filtered);
        handleClose();
    };

    const handleHoverLinkClick = (link) => {
        window.location.href = link;
      };
  
    return (
        <div className="creator categories">
            <div className="creator-header">
                <Button className="creator-btn1" onClick={handleShow}><FontAwesomeIcon icon={faFilter} /> Filters</Button>
            </div>
            <div className="creator-social">
                <Button
                    className="creator-btn2"
                    onClick={isSubscribed ? handleUnsubscribe : handleSubscribe}
                >
                    <FontAwesomeIcon icon={isSubscribed ? faBellSlash : faBell} /> {isSubscribed ? 'Subscribed' : 'Subscribe'}
                </Button>
                <a className="creator-rd-start">{brand?.socialMedia?.instagram && <FontAwesomeIcon onClick={() => handleHoverLinkClick(brand?.socialMedia?.instagram)} icon={faInstagram} size='2x' className="creator-social-rd" />}</a>
                    {brand?.socialMedia?.twitter && <FontAwesomeIcon onClick={() => handleHoverLinkClick(brand?.socialMedia?.twitter)} icon={faTwitter} size='2x' className="creator-social-rd" />} <a> </a>
                    <a> {brand?.socialMedia?.facebook && <FontAwesomeIcon onClick={() => handleHoverLinkClick(brand?.socialMedia?.facebook)} icon={faFacebook} size='2x' className="creator-social-rd" />}</a>
            </div>
            <Card className="text-white creator-card">
                <Card.Img src={brand?.brandLogo?.url ? brand?.brandLogo?.url : cardbg} alt="Card image" className="creator-card-img" />
                <Card.ImgOverlay className="creator-card-txt">
                    <Card.Title className="creator-card-h">{brand.brandName}</Card.Title>
                </Card.ImgOverlay>
            </Card>

            {productsLoading ? (
                <div className="wishlist-loading">
                    <ClipLoader size={50} color={"#123abc"} loading={productsLoading} />
                </div>
            ) : (
                <>
                    {selectedCubcategory != null ? <BrandSeectedCategory brand={brand} subCategory={selectedCubcategory} setSelectedCubcategory={setSelectedCubcategory} /> : (
                        <>


                            {Object.entries(groupedProducts).map(([subSubCategory, products], index) => (
                                <div className="categories-products" key={subSubCategory}>
                                    <Row className={`categories-products-row bg-${index % 2 === 0 ? 'green' : index % 3 === 0 ? 'blue' : 'yellow'}`}>
                                        <Col lg={4} className="categories-products-content">
                                            <div className="categories-products-h">{subSubCategory}</div>
                                            <div className="categories-products-p"></div>
                                            <div className="categories-products-ctrl">
                                                <Button className="custom-left-arrow" onClick={() => handleCarouselPrev(subSubCategory)}>
                                                    <FontAwesomeIcon icon={faCaretLeft} size='2x' />
                                                </Button>
                                                <Button className="custom-right-arrow" onClick={() => handleCarouselNext(subSubCategory)}>
                                                    <FontAwesomeIcon icon={faCaretRight} size='2x' />
                                                </Button>
                                            </div>
                                            <Button className="categories-products-btn creator-products-btn" onClick={() => handleHoverDivClick(`/brand/${slug}/category/${subSubCategory}`, subSubCategory)}>View All</Button>

                                        </Col>
                                        <Col lg={8} className="categories-products-carousel-col">
                                            <Carousel
                                                responsive={responsive}
                                                arrows={false}
                                                ref={(el) => carouselRefs.current[subSubCategory] = el}
                                                className="categories-products-carousel"
                                            >
                                                {products.map((product) => (
                                                    <Card
                                                        key={product._id}
                                                        className="categories-products-card"
                                                        onClick={() => handleHoverDivClick(`/product/${product.brand_slug}/${product._id}`)}
                                                    >
                                                        <img
                                                            src={product?.images[0]?.url || p1}
                                                            alt="card-img"
                                                            className="categories-products-card-img"
                                                        />
                                                        <div className="categories-products-price">₹{product?.variations[0]?.options[0]?.selling_price}</div>
                                                        <div className="categories-products-title">{product.name}</div>
                                                        <div className="categories-products-desc">{product.description}</div>
                                                    </Card>
                                                ))}
                                            </Carousel>
                                        </Col>
                                    </Row>
                                </div>
                            ))}

                        </>
                    )}
                </>
            )}

            <Modal show={show1} onHide={handleClose1} backdrop="static" keyboard={false} size="lg" className="creator-modal1">
                <Modal.Header className="creator-modal1-header" closeButton>
                    Say Something...
                </Modal.Header>
                <Modal.Body className="creator-modal1-body">
                    <Form.Control as="textarea" rows={5} placeholder="Write a message..." className="creator-modal1-fc" />
                    <Button className="creator-modal1-btn" onClick={alert1}>Sign-up</Button>
                </Modal.Body>
            </Modal>


            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} size="lg" className="creator-modal">
                    <Modal.Header className="creator-modal-header" closeButton>
                        <Modal.Title className="creator-modal-title"><FontAwesomeIcon icon={faFilter} onClick={handleShow} /> Filters</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="creator-modal-row">
                            <Col className="creator-modal-col">
                                <Form.Select className="creator-modal-select" value={Category} onChange={handleCategoryChange} disabled={true}>
                                    <option value="">{Category}</option>
                                   
                                </Form.Select>
                            </Col>
                            <Col className="creator-modal-col">
                                <Form.Select className="creator-modal-select" value={selectedSubCategory} onChange={handleSubCategoryChange} >
                                    <option value="">Select Subcategory</option>
                                    {(subCategories[Category] || []).map((subCategory) => (
                                        <option key={subCategory} value={subCategory}>{subCategory}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                        </Row>
                        <Row className="creator-modal-row">
                            <Col className="creator-modal-col">
                                <Form.Select className="creator-modal-select" value={selectedFilters.sort} onChange={(e) => setSelectedFilters({ ...selectedFilters, sort: e.target.value })}>
                                    <option value="">Sort</option>
                                    <option value="1">Trending (Most Popular)</option>
                                    <option value="2">Price: High to Low</option>
                                    <option value="3">Price: Low to High</option>
                                </Form.Select>
                            </Col>
                            <Col className="creator-modal-col">
                                <Form.Select className="creator-modal-select" value={selectedFilters.discount} onChange={(e) => setSelectedFilters({ ...selectedFilters, discount: e.target.value })}>
                                    <option value="">Discount</option>
                                    <option value="1">Current Sale</option>
                                    <option value="2">Over 50% off</option>
                                    <option value="3">Over 20% off</option>
                                </Form.Select>
                            </Col>
                        </Row>
                        
                        <Row className="creator-modal-row">
                            <Col className="creator-modal-col-range">
                                <Form.Label>Price (₹)</Form.Label>
                                <Slider
                                    range={true}
                                    step={10}
                                    defaultValue={[10, maxPrice]}
                                    min={0}
                                    max={maxPrice}
                                    onChange={changeEvent => handlePriceRangeChange(changeEvent.target.value)}
                                    railStyle={{ backgroundColor: "#959595" }}
                                    trackStyle={{ backgroundColor: "#959595" }}
                                />
                                <span className="creator-modal-range-start">₹{selectedFilters.priceRange[0]}</span>
                                <span className="creator-modal-range-end">₹{selectedFilters.priceRange[1]}</span>
                            </Col>
                            <Col className="creator-modal-col-range">
                                <Form.Label>Discount</Form.Label>
                                <RangeSlider
                                    value={selectedFilters.discountRange[1]}
                                    onChange={changeEvent => handleDiscountRangeChange(changeEvent)}
                                    min={10}
                                    max={95}
                                    tooltip="on"
                                    tooltipPlacement="top"
                                />
                                <span className="creator-modal-range-start">{selectedFilters.discountRange[0]}%</span>
                                <span className="creator-modal-range-end">{selectedFilters.discountRange[1]}%</span>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer className="creator-modal-footer">
                        <Button className="creator-modal-btn" onClick={applyFilters}>
                            Apply
                        </Button>
                    </Modal.Footer>
                </Modal>
        </div>
    )
}


export default BrandCategory;
