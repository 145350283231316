import React from 'react'
import CIcon from '@coreui/icons-react'
import {
  cilBell,
  cilCalculator,
  cilNotes,
  cilSpeedometer,
  cilStar,
} from '@coreui/icons'
import { CNavGroup, CNavItem, CNavTitle } from '@coreui/react'

const _nav = [
  {
    component: CNavItem,
    name: 'Dashboard',
    to: '/dashboard',
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
    badge: {
      color: 'info',
      text: 'NEW',
    },
  },
  // {
  //   component: CNavTitle,
  //   name: 'Theme',
  // },
  // {
  //   component: CNavItem,
  //   name: 'Colors',
  //   to: '/theme/colors',
  //   icon: <CIcon icon={cilDrop} customClassName="nav-icon" />,
  // },
  // {
  //   component: CNavItem,
  //   name: 'Typography',
  //   to: '/theme/typography',
  //   icon: <CIcon icon={cilPencil} customClassName="nav-icon" />,
  // },
  {
    component: CNavTitle,
    name: 'Components',
  },
  {
    component: CNavGroup,
    name: 'Cart and Wishlist',
    icon: <CIcon icon={cilCalculator} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'User Carts and Wishlist',
        to: '/admin/carts',
      },
      // {
      //   component: CNavItem,
      //   name: 'Users Wishlists',
      //   to: '/admin/wishlists',
      // }
    ],
  },
  {
    component: CNavGroup,
    name: 'Forms',
    icon: <CIcon icon={cilNotes} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'Help and Support',
        to: '/admin/help-and-support',
      },
      {
        component: CNavItem,
        name: 'Creator Partnership',
        to: '/admin/creator-partnership',
      },
      {
        component: CNavItem,
        name: 'Brand Colaboration',
        to: '/admin/brand-colaboration',
      },
    ],
  },
  {
    component: CNavGroup,
    name: 'Products',
    icon: <CIcon icon={cilStar} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'Products List',
        to: '/admin/products',
        badge: {
          color: 'success',
          text: 'NEW',
        },
      },
      {
        component: CNavItem,
        name: 'New Product',
        to: '/admin/product',
      },
    ],
  },
  {
    component: CNavGroup,
    name: 'Orders',
    icon: <CIcon icon={cilBell} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'Orders List',
        to: '/admin/orders',
      },
      {
        component: CNavItem,
        name: 'Badges',
        to: '/notifications/badges',
      }
    ],
  },
  ,
  {
    component: CNavGroup,
    name: 'Creator Categories',
    icon: <CIcon icon={cilStar} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'Trending',
        to: '/admin/creator-category/Trending',
      },
      {
        component: CNavItem,
        name: 'Feature',
        to: '/admin/creator-category/Feature',
      },
      {
        component: CNavItem,
        name: 'FastestGrowing',
        to: '/admin/creator-category/FastestGrowing',
      },
      {
        component: CNavItem,
        name: 'Popular',
        to: '/admin/creator-category/Popular',
      },
      {
        component: CNavItem,
        name: 'FashionBeauty',
        to: '/admin/creator-category/FashionBeauty',
      },
      {
        component: CNavItem,
        name: 'Lifestyle',
        to: '/admin/creator-category/Lifestyle',
      },
      {
        component: CNavItem,
        name: 'TopPerforming',
        to: '/admin/creator-category/TopPerforming',
      },
      {
        component: CNavItem,
        name: 'NewNoteworthy',
        to: '/admin/creator-category/NewNoteworthy',
      },
      {
        component: CNavItem,
        name: 'MostEngaging',
        to: '/admin/creator-category/MostEngaging',
      },
      {
        component: CNavItem,
        name: 'HighlyRecommended',
        to: '/admin/creator-category/HighlyRecommended',
      },
      {
        component: CNavItem,
        name: 'EditorsPick',
        to: '/admin/creator-category/EditorsPick',
      },
    ],
  },
  ,
  {
    component: CNavGroup,
    name: 'Withdrawl Requests',
    icon: <CIcon icon={cilStar} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'All Requests',
        to: '/admin/requests',
        // badge: {
        //   color: 'success',
        //   text: 'NEW',
        // },
      }
    ],
  },
  ,
  {
    component: CNavGroup,
    name: 'Callback Requests',
    icon: <CIcon icon={cilStar} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'All Requests',
        to: '/admin/callback-requests',
        badge: {
          color: 'success',
          text: 'NEW',
        },
      }
    ],
  },
  {
    component: CNavTitle,
    name: 'Login page',
  },
  {
    component: CNavGroup,
    name: 'Pages',
    icon: <CIcon icon={cilStar} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'Login',
        to: '/login-admin',
      },
      {
        component: CNavItem,
        name: 'Register',
        to: '/register',
      },
      {
        component: CNavItem,
        name: 'Error 404',
        to: '/404',
      },
      {
        component: CNavItem,
        name: 'Error 500',
        to: '/500',
      },
    ],
  }
]

export default _nav
