import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import './info.css';
import card from "../../media/card.png";
import { Button, Row, Col, Carousel, Form, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faQuoteLeftAlt, faQuoteRightAlt } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';

const BrandCollaboration = () => {
  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      phone: '',
      brandType: '',
      description: '',
      file: null,
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Required'),
      email: Yup.string().email('Invalid email address').required('Required'),
      phone: Yup.string().required('Required'),
      brandType: Yup.string().required('Required'),
      description: Yup.string().required('Required'),
      file: Yup.mixed().required('Required'),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        const formData = new FormData();
        for (const key in values) {
          formData.append(key, values[key]);
        }
        await axios.post('https://node.instasales.in/api/v1/brand/brand-colaboration', formData);
        alert('Form submitted successfully');
        resetForm();
      } catch (error) {
        alert('Error submitting form');
      }
    },
  });

  return (
    <div className="brand-collab">
      <div className="bc-top">
        <div className="bc-top-content">
          <div className="bc-top-h">Elevate Your Brand with Us.</div>
          <div className="bc-top-p">
            Welcome to our Brand Collaboration Hub, where we invite brands to partner with us and expand their reach. Collaborate with a diverse network of content creators and tap into new audiences. Unleash the power of influencer marketing and elevate your brand's visibility in the digital landscape.
          </div>
        </div>
      </div>
      <div className="bc-benefits">
        <Row className="bc-benefits-row">
          <Col lg={6} className="bc-benefits-h">Benefits on your way.</Col>
          <Col lg={6} className="bc-benefits-txt">
            <p className="bc-benefits-p"><FontAwesomeIcon icon={faCheckCircle} /> <span className="bold">Extended Reach:</span> Partner with a network of influential content creators to reach a wider audience.</p>
            <p className="bc-benefits-p"><FontAwesomeIcon icon={faCheckCircle} /> <span className="bold">Authentic Endorsements:</span> Benefit from genuine and authentic endorsements from trusted influencers.</p>
            <p className="bc-benefits-p"><FontAwesomeIcon icon={faCheckCircle} /> <span className="bold">Diverse Influencers:</span> Collaborate with creators across various niches and industries.</p>
            <p className="bc-benefits-p"><FontAwesomeIcon icon={faCheckCircle} /> <span className="bold">Collaboration Support:</span> Our team provides support to facilitate seamless partnerships.</p>
          </Col>
        </Row>
        <div className="bc-benefits-circle"></div>
      </div>
      <div className="bc-creators-word">
        <p className="bc-creators-word-h">What Brands Have to Say</p>
        <Carousel data-bs-theme="dark" className="bc-carousel">
          <Carousel.Item className="bc-carousel-item">
            <Row className="bc-carousel-row">
              <Col lg={1} className="bc-carousel-quotes">
                <FontAwesomeIcon icon={faQuoteLeftAlt} size="5x" className="bc-carousel-quote-left" />
              </Col>
              <Col lg={2} className="bc-carousel-img-wrap">
                <img src={card} className="bc-carousel-img" />
              </Col>
              <Col lg={8} className="bc-carousel-txt">
                <div><span className="bc-carousel-dark">APZ Down</span></div>
                <div className="bc-carousel-p">Partnering with content creators has been a transformative experience for our brand. Their ability to authentically showcase our products to their engaged audience has been a game-changer.</div>
              </Col>
              <Col lg={1} className="bc-carousel-quotes">
                <FontAwesomeIcon icon={faQuoteRightAlt} size="5x" className="bc-carousel-quote-right" />
              </Col>
            </Row>
          </Carousel.Item>
          <Carousel.Item className="bc-carousel-item">
            <Row className="bc-carousel-row">
              <Col lg={1} className="bc-carousel-quotes">
                <FontAwesomeIcon icon={faQuoteLeftAlt} size="5x" className="bc-carousel-quote-left" />
              </Col>
              <Col lg={2} className="bc-carousel-img-wrap">
                <img src={card} className="bc-carousel-img" />
              </Col>
              <Col lg={8} className="bc-carousel-txt">
                <div><span className="bc-carousel-dark">Lorem ipsum</span></div>
                <div className="bc-carousel-p">Partnering with content creators has been a transformative experience for our brand. Their ability to authentically showcase our products to their engaged audience has been a game-changer.</div>
              </Col>
              <Col lg={1} className="bc-carousel-quotes">
                <FontAwesomeIcon icon={faQuoteRightAlt} size="5x" className="bc-carousel-quote-right" />
              </Col>
            </Row>
          </Carousel.Item>
          <Carousel.Item className="bc-carousel-item">
            <Row className="bc-carousel-row">
              <Col lg={1} className="bc-carousel-quotes">
                <FontAwesomeIcon icon={faQuoteLeftAlt} size="5x" className="bc-carousel-quote-left" />
              </Col>
              <Col lg={2} className="bc-carousel-img-wrap">
                <img src={card} className="bc-carousel-img" />
              </Col>
              <Col lg={8} className="bc-carousel-txt">
                <div><span className="bc-carousel-dark">Dolor ithwin</span></div>
                <div className="bc-carousel-p">Partnering with content creators has been a transformative experience for our brand. Their ability to authentically showcase our products to their engaged audience has been a game-changer.</div>
              </Col>
              <Col lg={1} className="bc-carousel-quotes">
                <FontAwesomeIcon icon={faQuoteRightAlt} size="5x" className="bc-carousel-quote-right" />
              </Col>
            </Row>
          </Carousel.Item>
        </Carousel>
      </div>
      <div className="bc-join">
        <Form className="bc-join-form" onSubmit={formik.handleSubmit}>
          <p className="bc-join-h">Get In Touch.</p>
          <Row className="bc-join-row">
            <Col lg={6} className="bc-join-col">
              <InputGroup className="bc-join-input-group">
                <InputGroup.Text>Name</InputGroup.Text>
                <Form.Control
                  name="name"
                  placeholder="APZ Down"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={!!formik.errors.name && formik.touched.name}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.name}
                </Form.Control.Feedback>
              </InputGroup>
            </Col>
            <Col lg={6} className="bc-join-col">
              <InputGroup className="bc-join-input-group">
                <InputGroup.Text>Email</InputGroup.Text>
                <Form.Control
                  name="email"
                  placeholder="apzdown@gmail.com"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={!!formik.errors.email && formik.touched.email}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.email}
                </Form.Control.Feedback>
              </InputGroup>
            </Col>
          </Row>
          <Row className="bc-join-row">
            <Col lg={4} className="bc-join-col">
              <InputGroup className="bc-join-input-group">
                <InputGroup.Text>+91</InputGroup.Text>
                <Form.Control
                  name="phone"
                  placeholder="xxxxxxxxxx"
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={!!formik.errors.phone && formik.touched.phone}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.phone}
                </Form.Control.Feedback>
              </InputGroup>
            </Col>
            <Col lg={8} className="bc-join-col">
              <InputGroup className="bc-join-input-group">
                <InputGroup.Text>Brand Type</InputGroup.Text>
                <Form.Control
                  name="brandType"
                  placeholder="Education"
                  value={formik.values.brandType}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={!!formik.errors.brandType && formik.touched.brandType}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.brandType}
                </Form.Control.Feedback>
              </InputGroup>
            </Col>
          </Row>
          <Row className="bc-join-row">
            <InputGroup>
              <Form.Control
                as="textarea"
                rows={3}
                name="description"
                placeholder="Tell us about your brand..."
                value={formik.values.description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={!!formik.errors.description && formik.touched.description}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.description}
              </Form.Control.Feedback>
            </InputGroup>
          </Row>
          <Row className="bc-join-row">
            <InputGroup>
              <Form.Control
                type="file"
                name="file"
                onChange={(event) => formik.setFieldValue('file', event.currentTarget.files[0])}
                onBlur={formik.handleBlur}
                isInvalid={!!formik.errors.file && formik.touched.file}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.file}
              </Form.Control.Feedback>
            </InputGroup>
          </Row>
          <center>
            <Button type="submit" className="bc-join-btn">Submit</Button>
          </center>
        </Form>
      </div>
    </div>
  );
};

export default BrandCollaboration;
