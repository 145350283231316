import React, { useEffect, useState } from 'react'
import './categories.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Col, Form, Row, Modal } from "react-bootstrap";
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import pic from "../../media/profile.jpeg"
import p1 from "../../media/p1.png"
import p2 from "../../media/p2.png"
import p3 from "../../media/p3.png"
import { useParams } from 'react-router-dom';
import axios from 'axios';
import ClipLoader from 'react-spinners/ClipLoader';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import Slider from '@mui/material/Slider';


const findMaxPrice = (events) => {
    if (!events || events.length === 0) {
        return 0;
    }

    let maxPrice = -Infinity;

    events.forEach((event) => {
        event.ticket_types.forEach((ticketType) => {
            if (ticketType.ticketPrice > maxPrice) {
                maxPrice = ticketType.ticketPrice;
            }
        });
    });

    return maxPrice;
};

const All = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const { categoryName, subSubCategory } = useParams();

    console.log("categoryName subSubCategory", categoryName, subSubCategory);
    const [products, setProducts] = useState([]);

    const [loading, setLoading] = useState(false); // State for loading indicator
    const [selectedFilters, setSelectedFilters] = useState({
        subCategory: '',
        sort: '',
        discount: '',
        brands: [],
        priceRange: [0, 800],
        discountRange: [10, 95]
    });
    const [maxPrice, setMaxPrice] = useState(1000);
    // const [priceRange, setPriceRange] = useState([0, maxPrice]);

    useEffect(() => {
        const fetchProducts = async () => {
            setLoading(true); // Set loading to true before API call
            try {
                const response = await axios.get(`https://node.instasales.in/api/v1/products/subcategory/${subSubCategory}`);
                setProducts(response.data.products); // Assuming the API response contains a "products" field
                console.log("products", response);
            } catch (error) {
                console.error('Error fetching products:', error);
            } finally {
                setLoading(false); // Set loading to false after API call completes
            }
        };

        fetchProducts();
    }, [subSubCategory]);

    // Chunking function
    const chunkArray = (array, size) => {
        const chunkedArray = [];
        for (let i = 0; i < array?.length; i += size) {
            chunkedArray.push(array.slice(i, i + size));
        }
        return chunkedArray;
    };

    // Chunk products into arrays of 4
    const productChunks = chunkArray(products, 4);

    console.log("products is all", products);

    const handleHoverDivClick = (link) => {
        window.location.href = link;
    };

    const applyFilters = () => {
        let filtered = [...products];

        // Apply price range filter
        const [minPrice, maxPrice] = selectedFilters.priceRange;
        filtered = filtered.filter(product => {
            const price = product.variations[0]?.options[0].selling_price;
            return price >= minPrice && price <= maxPrice;
        });

        // Filter by brands
        if (selectedFilters.brands.length > 0) {
            filtered = filtered.filter(product => selectedFilters.brands.includes(product.brand_name));
        }

        // Apply sorting
        if (selectedFilters.sort) {
            if (selectedFilters.sort === '1') { // Trending
                filtered = filtered.sort((a, b) => b.popularity - a.popularity); // Assuming popularity is a field
            } else if (selectedFilters.sort === '2') { // Price: High to Low
                filtered = filtered.sort((a, b) => b.variations[0]?.options[0].selling_price - a.variations[0]?.options[0].selling_price);
            } else if (selectedFilters.sort === '3') { // Price: Low to High
                filtered = filtered.sort((a, b) => a.variations[0]?.options[0].selling_price - b.variations[0]?.options[0].selling_price);
            }
        }

        setProducts(filtered);
        handleClose();
    };

    const handleBrandFilterChange = (event) => {
        const brand = event.target.value;
        const isChecked = event.target.checked;
        setSelectedFilters(prevFilters => ({
            ...prevFilters,
            brands: isChecked ? [...prevFilters.brands, brand] : prevFilters.brands.filter(b => b !== brand)
        }));
    };

    const handlePriceRangeChange = (value) => {
        setSelectedFilters(prevFilters => ({
            ...prevFilters,
            priceRange: value
        }));
    };

    const handleDiscountRangeChange = (event) => {
        const value = event.target.value;
        setSelectedFilters(prevFilters => ({
            ...prevFilters,
            discountRange: [10, value]
        }));
    };

    const getUniqueBrands = () => {
        const allBrands = products.map(product => product.brand_name);
        return [...new Set(allBrands)];
    };

    const handleCategoryClick = (category) => {
        window.location.href = `/categories/${category}`;
    };

    const handleDropdownChange = (event) => {
        const selectedCategory = event.target.value;
        if (selectedCategory !== categoryName) {
            handleCategoryClick(selectedCategory);
        }
    };

    return (
        <div className="all">
            <Row className="categories-top">
                <Col className="categories-h" lg={9}>{categoryName}</Col>
                <Col lg={2} className="categories-filter"><Button className="categories-top-btn" onClick={handleShow}><FontAwesomeIcon icon={faFilter} /> Filters</Button></Col>
                <Col className="categories-color" lg={1}></Col>
            </Row>
            <div className="all-items">
                <div className="all-items-h">{subSubCategory}</div>
                <div className="all-items-p">{products?.length} Search results</div>

                {loading ? (
                    <div className="wishlist-loading">
                        <ClipLoader size={50} color={"#123abc"} loading={loading} />
                    </div>
                ) : (
                    <>
                        {productChunks.map((chunk, chunkIndex) => (
                            <div key={chunkIndex}>
                                <Row className="all-items-row">
                                    {chunk.map((product, productIndex) => (
                                        <Col key={productIndex} as={Card} className="categories-products-card" onClick={() => handleHoverDivClick(`/product/${product.brand_slug}/${product._id}`)}>
                                            <img src={product?.images[0]?.url || p1} alt="card-img" className="categories-products-card-img" />
                                            <div className="categories-products-price">₹{product?.variations[0]?.options[0]?.selling_price}</div>
                                            <div className="categories-products-title">{product.name}</div>
                                            <div className="categories-products-desc">{product.description}</div>
                                        </Col>
                                    ))}
                                </Row>
                            </div>
                        ))}
                    </>
                )}
            </div>
            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} size="lg" className="categories-modal">
                <Modal.Header className="categories-modal-header" closeButton>
                    <Modal.Title className="categories-modal-title"><FontAwesomeIcon icon={faFilter} onClick={handleShow} /> Filters</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="categories-modal-row">
                        <Col className="categories-modal-col">
                            <Form.Select className="categories-modal-select" value={categoryName} onChange={handleDropdownChange}>
                                <option disabled>Select a Category</option>
                                <option value="Skin Care">Skin Care</option>
                                <option value="Art">Art</option>
                                <option value="Clothing">Clothing</option>
                                <option value="Accessories">Accessories</option>
                                <option value="Home & Living">Home & Living</option>
                                <option value="Foods & Beverages">Foods & Beverages</option>
                                <option value="Kitchen">Kitchen</option>
                                <option value="Stationery">Stationery</option>
                            </Form.Select>
                        </Col>
                        <Col className="categories-modal-col">
                            <Form.Select className="categories-modal-select" disabled>
                                <option>{subSubCategory}</option>
                            </Form.Select>
                        </Col>
                    </Row>
                    <Row className="categories-modal-row">
                        <Col className="categories-modal-col">
                            <Form.Select className="categories-modal-select" value={selectedFilters.sort} onChange={(e) => setSelectedFilters({ ...selectedFilters, sort: e.target.value })}>
                                <option value="">Sort</option>
                                <option value="1">Trending (Most Popular)</option>
                                <option value="2">Price: High to Low</option>
                                <option value="3">Price: Low to High</option>
                            </Form.Select>
                        </Col>
                        <Col className="categories-modal-col">
                            <Form.Select className="categories-modal-select" value={selectedFilters.discount} onChange={(e) => setSelectedFilters({ ...selectedFilters, discount: e.target.value })}>
                                <option value="">Discount</option>
                                <option value="1">Current Sale</option>
                                <option value="2">Over 50% off</option>
                                <option value="3">Over 20% off</option>
                            </Form.Select>
                        </Col>
                    </Row>

                    <Row className="categories-modal-check">
                        <Col className="categories-modal-check-col">
                            <p className="categories-modal-check-h">
                                <span className="categories-modal-check-txt">Brands</span>
                            </p>
                            <div key={`inline-checkbox`} className="mb-3">
                                {getUniqueBrands().map((brand, index) => (
                                    <Form.Check
                                        key={index}
                                        inline
                                        label={brand}
                                        value={brand}
                                        onChange={handleBrandFilterChange}
                                        checked={selectedFilters.brands.includes(brand)}
                                    />
                                ))}
                            </div>
                        </Col>
                    </Row>
                    <Row className="categories-modal-row">
                        <Col className="categories-modal-col-range">
                            <Form.Label>Price (₹)</Form.Label>
                            {/* <RangeSlider

                                value={selectedFilters.priceRange[1]}
                                onChange={changeEvent => handlePriceRangeChange(changeEvent.target.value)}
                                min={0}
                                max={800}
                                tooltip="on"
                                tooltipPlacement="top"
                            /> */}
                            <Slider
                                range={true}
                                step={10}
                                defaultValue={[10, maxPrice]}
                                min={0}
                                max={maxPrice}
                                onChange={changeEvent => handlePriceRangeChange(changeEvent.target.value)}
                                railStyle={{ backgroundColor: "#959595" }}
                                trackStyle={{ backgroundColor: "#959595" }}
                            />

                            <span className="categories-modal-range-start">₹{selectedFilters.priceRange[0]}</span>
                            <span className="categories-modal-range-end">₹{selectedFilters.priceRange[1]}</span>
                        </Col>
                        <Col className="categories-modal-col-range">
                            <Form.Label>Discount</Form.Label>
                            <Slider
                                range={true}
                                value={selectedFilters.discountRange[1]}
                                defaultValue={[5, 95]}
                                onChange={changeEvent => handleDiscountRangeChange(changeEvent)}
                                min={10}
                                max={95}
                               
                            />
                            <span className="categories-modal-range-start">{selectedFilters.discountRange[0]}%</span>
                            <span className="categories-modal-range-end">{selectedFilters.discountRange[1]}%</span>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className="categories-modal-footer">
                    <Button className="categories-modal-btn" onClick={applyFilters}>
                        Apply
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default All;
