import React, { useEffect, useRef, useState } from 'react';
import Carousel from "react-multi-carousel";
import cardbg from "../../media/card.png";
import "react-multi-carousel/lib/styles.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Col, Row } from "react-bootstrap";
import { faCaretLeft, faCaretRight, faCertificate } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './creators.css';

const Creators = () => {
    const carouselRefs = useRef({});
    const [creatorsData, setCreatorsData] = useState([]);
    const [creatorsByTags, setCreatorsByTags] = useState(null); // State to hold creators by tags
    const [filteredCreators, setFilteredCreators] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [sortBy, setSortBy] = useState('creatorName'); // Default sort by creatorName

    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 4
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    useEffect(() => {
        // Fetch creators data
        const fetchCreators = async () => {
            try {
                const response = await axios.get('https://node.instasales.in/api/v1/creator/creators');
                setCreatorsData(response.data.creators);
                setFilteredCreators(response.data.creators); // Initially set filtered creators to all creators
            } catch (error) {
                console.error('Error fetching creators data:', error);
            }
        };

        // Fetch creators by tags data
        const fetchCreatorsByTags = async () => {
            try {
                const response = await axios.get('https://node.instasales.in/api/v1/creator/creators-tags');
                setCreatorsByTags(response.data[0]); // Assuming the response contains the categories in the first object
            } catch (error) {
                console.error('Error fetching creators by tags:', error);
            }
        };

        fetchCreators();
        fetchCreatorsByTags();
    }, []);

    const handleHoverDivClick = (link) => {
        window.location.href = link;
    }
    // Function to handle search query change
    const handleSearchChange = (event) => {
        const { value } = event.target;
        setSearchQuery(value);
        filterCreators(value);
    };

    // Function to filter creators based on search query
    const filterCreators = (query) => {
        const lowercasedFilter = query.toLowerCase();
        const filteredData = creatorsData.filter(item => {
            return Object.keys(item).some(key =>
                typeof item[key] === 'string' && item[key].toLowerCase().includes(lowercasedFilter)
            );
        });
        setFilteredCreators(filteredData);
    };


    const bgClasses = ['bg-yellow', 'bg-green', 'bg-blue'];


    const formatFollowers = (count) => {
        if (count >= 1000000) {
            return (count / 1000000).toFixed(1) + 'M';
        } else if (count >= 1000) {
            return (count / 1000).toFixed(1) + 'k';
        }
        return count;
    };

// Group creators by their creatorType
    const groupedCreators = filteredCreators.reduce((acc, creator) => {
        const { creatorType } = creator;
        if (!acc[creatorType]) {
            acc[creatorType] = [];
        }
        acc[creatorType].push(creator);
        return acc;
    }, {});

    // Helper function to render carousels dynamically
    const renderCarousel = (creatorType, creators, index) => {
        if (!carouselRefs.current[creatorType]) {
            carouselRefs.current[creatorType] = React.createRef();
        }

        const bgClasses = ['bg-yellow', 'bg-green', 'bg-blue'];
        const bgClass = bgClasses[index % bgClasses.length];

        const formatFollowers = (count) => {
            if (count >= 1000000) {
                return (count / 1000000).toFixed(1) + 'M';
            } else if (count >= 1000) {
                return (count / 1000).toFixed(1) + 'k';
            }
            return count;
        };
        

        return (
            <>
                <Row className="creators-head">
                    <Col lg={10} className="creators-txt">
                        <div className="creators-head-h">{creatorType} Creators</div>
                        <div className="creators-head-p">
                            Discover the best {creatorType} creators.
                        </div>
                    </Col>
                    <Col lg={2} className="creators-ctrl">
                        <Button className="custom-left-arrow" onClick={() => carouselRefs.current[creatorType].current.previous()}>
                            <FontAwesomeIcon icon={faCaretLeft} size='2x' />
                        </Button>
                        <Button className="custom-right-arrow" onClick={() => carouselRefs.current[creatorType].current.next()}>
                            <FontAwesomeIcon icon={faCaretRight} size='2x' />
                        </Button>
                    </Col>
                </Row>
                <div className="creators-card-wrapper">
                    <div className={`creators-card-bg ${bgClass}`}></div>
                    <Carousel responsive={responsive} className="creators-cards" ref={carouselRefs.current[creatorType]} showDots={false} arrows={false}>
                        {creators.map((creator, index) => (
                            <Link to={`/creator/${creator.slug}`} key={index}>
                                <Card className="text-white creators-card">
                                    <Card.Img src={creator.avatar.url || cardbg} alt="Card image" className="creators-card-img" />
                                    <Card.ImgOverlay className="creators-card-txt">
                                        <Card.Title className="creators-card-h">{creator.creatorName}</Card.Title>
                                        <Card.Text className="creators-card-p">{creator.creatorType}</Card.Text>
                                    </Card.ImgOverlay>
                                    <span className="creators-card-stats">{formatFollowers(creator.followers)}</span>
                                </Card>
                            </Link>
                        ))}
                    </Carousel>
                </div>
            </>
        );
    };

    return (
        <div className="creators">
            <Row className="creators-top">
                <Col className="creators-h" lg={11}>Explore a World of Brand Diversity</Col>
                <Col className="creators-color" lg={1}></Col>
            </Row>

            {/* Render all available tags dynamically */}
            {creatorsByTags && Object.entries(creatorsByTags).map(([tag, creators], index) => (
                // Exclude unwanted fields such as '_id' and '__v'
                (tag !== '_id' && tag !== '__v' && Array.isArray(creators) && creators.length > 0) && (
                    <div key={tag}>
                        {renderCarousel(tag, creators, index)}
                    </div>
                )
            ))}

            <div className="creators-discover">
                <div className="creators-discover-h">Discover a Multitude of Brands</div>
                <div className="creators-discover-p">
                    Explore a World of Brand Diversity
                </div>
                <Button className="creators-discover-btn" onClick={() => handleHoverDivClick('/brands')}>
                    <FontAwesomeIcon icon={faCertificate} className="creators-discover-icon" /> <span className="creators-discover-btn-p">Discover Brands</span>
                </Button>
            </div>

            {/* Render Carousels dynamically based on creatorType */}
            {Object.entries(groupedCreators).map(([creatorType, creators], index) => (
                <div key={creatorType}>
                    {renderCarousel(creatorType, creators, index)}
                </div>
            ))}



        </div>
    );
};

export default Creators;
