import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import './info.css';
import cp1 from "../../media/cp1.png";
import cp2 from "../../media/cp2.png";
import cp3 from "../../media/cp3.png";
import card from "../../media/card.png";
import { Button, Row, Col, Carousel, Form, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faQuoteLeftAlt, faQuoteRightAlt } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';

const CreatorPartnership = () => {
  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      phone: '',
      creatorType: '',
      socialMediaLink: '',
      description: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Required'),
      email: Yup.string().email('Invalid email address').required('Required'),
      phone: Yup.string().required('Required'),
      creatorType: Yup.string().required('Required'),
      socialMediaLink: Yup.string().url('Invalid URL').required('Required'),
      description: Yup.string().required('Required'),
    }),
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values, { resetForm }) => {
      try {
        await axios.post('https://node.instasales.in/api/v1/creator/creator-partnership', values);
        alert('Form submitted successfully');
        resetForm();
      } catch (error) {
        alert('Error submitting form');
      }
    },
  });

  return (
    <div className="creator-partnership">
      <div className="cp-top">
        <img src={cp1} className="cp-top-img1" alt="Top Image 1" />
        <div className="cp-top-content">
          <div className="cp-top-h">Creator Partnership Program.</div>
          <div className="cp-top-p">
            Join our Brand Ambassadors Collective, an exclusive opportunity for content creators to set up their stores, promote exciting brands, and earn lucrative commissions. We empower you to transform your influence into income, opening doors to a new world of possibilities.
          </div>
          <Button className="cp-top-btn">Join Now</Button>
        </div>
        <img src={cp2} className="cp-top-img2" alt="Top Image 2" />
      </div>
      <div className="cp-benefits">
        <Row className="cp-benefits-row">
          <Col lg={6} className="cp-benefits-h">Benefits on your way.</Col>
          <Col lg={6} className="cp-benefits-txt">
            <p className="cp-benefits-p"><FontAwesomeIcon icon={faCheckCircle} /> <span className="bold">Boost Your Earnings:</span> Turn your influence into income with generous commission rates.</p>
            <p className="cp-benefits-p"><FontAwesomeIcon icon={faCheckCircle} /> <span className="bold">Access To Exclusive Brands:</span> Collaborate with sought-after brands to promote and sell their products.</p>
            <p className="cp-benefits-p"><FontAwesomeIcon icon={faCheckCircle} /> <span className="bold">Set Up Your Store:</span> Create your unique online store and showcase your favorite products.</p>
            <p className="cp-benefits-p"><FontAwesomeIcon icon={faCheckCircle} /> <span className="bold">Seamless Transactions:</span> Enjoy secure and hassle-free payment processing</p>
          </Col>
        </Row>
        <div className="cp-benefits-circle"></div>
      </div>
      <div className="cp-creators-word">
        <p className="cp-creators-word-h">The Creators' Word</p>
        <Carousel data-bs-theme="dark" className="cp-carousel">
          <Carousel.Item className="cp-carousel-item">
            <Row className="cp-carousel-row">
              <Col lg={1} className="cp-carousel-quotes">
                <FontAwesomeIcon icon={faQuoteLeftAlt} size="5x" className="cp-carousel-quote-left" />
              </Col>
              <Col lg={2} className="cp-carousel-img-wrap">
                <img src={card} className="cp-carousel-img" alt="Carousel Image 1" />
              </Col>
              <Col lg={8} className="cp-carousel-txt">
                <div><span className="cp-carousel-dark">Vineet Deep,</span> <span className="cp-carousel-light">Artist</span></div>
                <div className="cp-carousel-p">I joined the Creators Partnership Program and it's been a game-changer for me. This platform has not only allowed me to set up my own store and promote brands effortlessly but has also given me access to a wide network of fellow creators.</div>
              </Col>
              <Col lg={1} className="cp-carousel-quotes">
                <FontAwesomeIcon icon={faQuoteRightAlt} size="5x" className="cp-carousel-quote-right" />
              </Col>
            </Row>
          </Carousel.Item>
          <Carousel.Item className="cp-carousel-item">
            <Row className="cp-carousel-row">
              <Col lg={1} className="cp-carousel-quotes">
                <FontAwesomeIcon icon={faQuoteLeftAlt} size="5x" className="cp-carousel-quote-left" />
              </Col>
              <Col lg={2} className="cp-carousel-img-wrap">
                <img src={card} className="cp-carousel-img" alt="Carousel Image 2" />
              </Col>
              <Col lg={8} className="cp-carousel-txt">
                <div><span className="cp-carousel-dark">Vineet Deep,</span> <span className="cp-carousel-light">Artist</span></div>
                <div className="cp-carousel-p">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis non ligula metus. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Sed ligula nulla, dignissim sit amet mattis in, faucibus non diam. Nam ac semper lacus.</div>
              </Col>
              <Col lg={1} className="cp-carousel-quotes">
                <FontAwesomeIcon icon={faQuoteRightAlt} size="5x" className="cp-carousel-quote-right" />
              </Col>
            </Row>
          </Carousel.Item>
          <Carousel.Item className="cp-carousel-item">
            <Row className="cp-carousel-row">
              <Col lg={1} className="cp-carousel-quotes">
                <FontAwesomeIcon icon={faQuoteLeftAlt} size="5x" className="cp-carousel-quote-left" />
              </Col>
              <Col lg={2} className="cp-carousel-img-wrap">
                <img src={card} className="cp-carousel-img" alt="Carousel Image 3" />
              </Col>
              <Col lg={8} className="cp-carousel-txt">
                <div><span className="cp-carousel-dark">Vineet Deep,</span> <span className="cp-carousel-light">Artist</span></div>
                <div className="cp-carousel-p">Curabitur vel purus gravida, vestibulum dolor sed, dictum velit. Sed odio massa, commodo a ligula ut, aliquam suscipit nisi. Phasellus placerat ipsum non sagittis tincidunt. Vestibulum commodo egestas metus in luctus. Pellentesque tortor magna, dictum nec ante et.</div>
              </Col>
              <Col lg={1} className="cp-carousel-quotes">
                <FontAwesomeIcon icon={faQuoteRightAlt} size="5x" className="cp-carousel-quote-right" />
              </Col>
            </Row>
          </Carousel.Item>
        </Carousel>
      </div>
      <div className="cp-join">
        <img src={cp3} className="cp-join-img" alt="Join Image" />
        <Form className="cp-join-form" onSubmit={formik.handleSubmit}>
          <p className="cp-join-h">Join the Movement.</p>
          <Row className="cp-join-row">
            <Col lg={6} className="cp-join-col">
              <InputGroup className="cp-join-input-group">
                <InputGroup.Text>Name</InputGroup.Text>
                <Form.Control
                  name="name"
                  placeholder="Jane Doe"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={formik.touched.name && !!formik.errors.name}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.name}
                </Form.Control.Feedback>
              </InputGroup>
            </Col>
            <Col lg={6} className="cp-join-col">
              <InputGroup className="cp-join-input-group">
                <InputGroup.Text>Email</InputGroup.Text>
                <Form.Control
                  name="email"
                  placeholder="jane.doe@gmail.com"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={formik.touched.email && !!formik.errors.email}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.email}
                </Form.Control.Feedback>
              </InputGroup>
            </Col>
          </Row>
          <Row className="cp-join-row">
            <Col lg={4} className="cp-join-col">
              <InputGroup className="cp-join-input-group">
                <InputGroup.Text>+91</InputGroup.Text>
                <Form.Control
                  name="phone"
                  placeholder="xxxxxxxxxx"
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  maxLength={10}
                  isInvalid={formik.touched.phone && !!formik.errors.phone}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.phone}
                </Form.Control.Feedback>
              </InputGroup>
            </Col>
            <Col lg={8} className="cp-join-col">
              <InputGroup className="cp-join-input-group">
                <InputGroup.Text>Creator Type</InputGroup.Text>
                <Form.Control
                  name="creatorType"
                  placeholder="Food Blogger"
                  value={formik.values.creatorType}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={formik.touched.creatorType && !!formik.errors.creatorType}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.creatorType}
                </Form.Control.Feedback>
              </InputGroup>
            </Col>
          </Row>
          <Row className="cp-join-row">
            <InputGroup>
              <InputGroup.Text>Social Media Link</InputGroup.Text>
              <Form.Control
                name="socialMediaLink"
                placeholder="https://example.com"
                value={formik.values.socialMediaLink}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={formik.touched.socialMediaLink && !!formik.errors.socialMediaLink}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.socialMediaLink}
              </Form.Control.Feedback>
            </InputGroup>
          </Row>
          <Row className="cp-join-row">
            <InputGroup>
              <Form.Control
                as="textarea"
                rows={3}
                name="description"
                placeholder="Say something about yourself..."
                value={formik.values.description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={formik.touched.description && !!formik.errors.description}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.description}
              </Form.Control.Feedback>
            </InputGroup>
          </Row>
          <Button type="submit" className="cp-join-btn">Submit</Button>
        </Form>
      </div>
    </div>
  );
};

export default CreatorPartnership;
