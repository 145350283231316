import React, { createContext, useState, useEffect } from 'react';

const BrandContext = createContext();

const BrandProvider = ({ children }) => {
    const [brand, setBrand] = useState(null);

    useEffect(() => {
        // Load user data from local storage
        const BrandData = localStorage.getItem('brand');
        if (BrandData) {
            setBrand(JSON.parse(BrandData));
        }
    }, []);

    return (
        <BrandContext.Provider value={{ brand, setBrand }}>
            {children}
        </BrandContext.Provider>
    );
};

export { BrandContext, BrandProvider };
