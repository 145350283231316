import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Card, Form ,Modal } from 'react-bootstrap';
import axios from 'axios';
import './inventory.css';

function Inventory() {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  // Fetch products with inventory management enabled
  useEffect(() => {
    const fetchInventoryProducts = async () => {
      try {
        const token = localStorage.getItem('Btoken'); // Get the auth token
        const response = await axios.get('https://node.instasales.in/api/v1/brand/inventory-products', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setProducts(response.data.products);
      } catch (err) {
        setError('Failed to load inventory products. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchInventoryProducts();
  }, []);

  // Function to calculate total stock from the product and its variations
  const calculateTotalStock = (product) => {
    let totalStock = product.stock || 0; // Start with the base stock

    // If the product has variations, sum up the stock in the options
    if (product.variations && product.variations.length > 0) {
      product.variations.forEach((variation) => {
        if (variation.options && variation.options.length > 0) {
          variation.options.forEach((option) => {
            totalStock += option.stock || 0; // Add each variation's stock
          });
        }
      });
    }

    return totalStock;
  };

  // Filter products based on search term
  const filteredProducts = products.filter((product) =>
    product.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const [showModal, setShowModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [variationStocks, setVariationStocks] = useState([]);
  
  const handleManageInventory = (product) => {
    setSelectedProduct(product);
    setVariationStocks(product.variations.map(variation => ({
      ...variation,
      options: variation.options.map(option => ({
        ...option,
        updatedStock: option.stock,
      }))
    })));
    setShowModal(true);
  };

  const handleStockChange = (variationIndex, optionIndex, newStock) => {
    const updatedStocks = [...variationStocks];
    updatedStocks[variationIndex].options[optionIndex].updatedStock = newStock;
    setVariationStocks(updatedStocks);
  };

  const handleUpdateStock = async () => {
    try {
      const token = localStorage.getItem('Btoken'); // Get the auth token
      const updatedVariations = variationStocks.map(variation => ({
        v_name: variation.v_name,
        options: variation.options.map(option => ({
          name: option.name,
          stock: option.updatedStock,
        })),
      }));

    const response =   await axios.put(`https://node.instasales.in/api/v1/brand/products/${selectedProduct._id}/update-stock`, {
        variations: updatedVariations,
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("response",response)
      setShowModal(false);
    } catch (error) {
      console.error('Error updating stock:', error);
    }
  };

  if (loading) {
    return (
      <Container className="text-center mt-5">
        <p>Loading inventory...</p>
      </Container>
    );
  }

  if (error) {
    return (
      <Container className="text-center mt-5">
        <p>{error}</p>
      </Container>
    );
  }

  return (
    <Container fluid className="inventory-page">
      {/* Inventory Header */}
      <Row className="my-4">
        <Col md={12}>
          <h4>Inventory</h4>
        </Col>
      </Row>

      {/* Search bar */}
      <Row className="mb-3">
        <Col md={12}>
          <Form.Group className="mb-3 d-flex">
            <Form.Control
              type="text"
              placeholder="Search Products"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Button variant="outline-secondary" className="ms-2">
              <i className="bi bi-search"></i>
            </Button>
          </Form.Group>
        </Col>
      </Row>

      {/* Inventory Cards */}
      <Row className="inventory-list">
        {filteredProducts.length > 0 ? (
          filteredProducts.map((product, index) => (
            <Col md={12} key={index} className="mb-3">
              <Card className="inventory-card p-3">
                <Row>
                  <Col md={2}>
                    <img
                      src={product.images[0]?.url || 'https://via.placeholder.com/100'}
                      alt="Product"
                      className="img-fluid"
                    />
                  </Col>
                  <Col md={6}>
                    <h5>{product.name}</h5>
                  </Col>
                  <Col md={4} className="text-end">
                    <Row className="mb-2">
                      <Col md={6}>
                        <p>Overall Stock Left:</p>
                      </Col>
                      <Col md={6}>
                        <Form.Control
                          type="number"
                          value={calculateTotalStock(product)} // Calculating total stock dynamically
                          className="text-center"
                          readOnly
                        />
                      </Col>
                    </Row>
                    <Button variant="dark" onClick={() => handleManageInventory(product)}>
                      Manage Inventory
                    </Button>
                    <Button variant="light" className="me-2">
                      <i className="bi bi-pencil"></i>
                    </Button>
                    <Button variant="light">
                      <i className="bi bi-trash"></i>
                    </Button>
                  </Col>
                </Row>
              </Card>
            </Col>
          ))
        ) : (
          <Col md={12}>
            <p>No products with inventory management found.</p>
          </Col>
        )}
      </Row>

         {/* Modal for Inventory Management */}
         <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Manage Inventory for {selectedProduct?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {variationStocks.map((variation, variationIndex) => (
              variation.options.map((option, optionIndex) => (
                <Row key={`${variationIndex}-${optionIndex}`} className="mb-3">
                  <Col md={4}>
                    <Form.Group>
                      <Form.Label>Color</Form.Label>
                      <Form.Control type="text" value={variation.v_name + ' / ' + option.name} readOnly />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group>
                      <Form.Label>Size</Form.Label>
                      <Form.Control type="text" value="XL" readOnly />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group>
                      <Form.Label>Total Stock</Form.Label>
                      <Form.Control
                        type="number"
                        value={option.updatedStock}
                        onChange={(e) => handleStockChange(variationIndex, optionIndex, e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              ))
            ))}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleUpdateStock}>
            Update Stock
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default Inventory;
