import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Col, Row, Card } from 'react-bootstrap';
import './product.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faHeart, faPlus } from '@fortawesome/free-solid-svg-icons';
import { useParams, useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { UserContext } from '../UserContext';
import { useProductContext } from '../ProductContext';
import ClipLoader from 'react-spinners/ClipLoader'; // Import the spinner
import { Helmet } from 'react-helmet';
import Carousel from "react-multi-carousel";
import { faCaretLeft, faCaretRight, faFilter } from '@fortawesome/free-solid-svg-icons';

const Product = () => {
  const { id } = useParams();
  const { creatorslug } = useParams();
  const [brand, setBrand] = useState(null);
  const [products, setProducts] = useState([]);
  const [brandLoading, setBrandLoading] = useState(true); // Add brand loading state
  const [productsLoading, setProductsLoading] = useState(true);
  const [product, setProduct] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [activeImage, setActiveImage] = useState('');
  const [isInWishlist, setIsInWishlist] = useState(false);
  const [cartFeedback, setCartFeedback] = useState('');
  const [isInCart, setIsInCart] = useState(false);
  const [cartQuantity, setCartQuantity] = useState(0);
  const [selectedVariation, setSelectedVariation] = useState(null); // State for selected variation
  const [loading, setLoading] = useState(true); // Add loading state
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const { addProduct } = useProductContext();
  const carouselRef = useRef(null);
  const [creator, setCreator] = useState(null);
  const [CreatorLoading, setCreatorLoading] = useState(true); // Add brand loading state

  const fetchProductDetails = async () => {
    const token = localStorage.getItem('token');

    try {
      const { data } = await axios.get(`https://node.instasales.in/api/v1/product/${id}`);
      setProduct(data.product);
      setActiveImage(data.product.images[0]?.url || '');
      setSelectedVariation(data.product.variations[0]?.options[0]); // Set default variation

      const wishlistResponse = await axios.get('https://node.instasales.in/api/v1/wishlist', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const wishlist = wishlistResponse.data.wishlist.products;
      setIsInWishlist(wishlist.some(p => p._id === data.product._id));

      const cartResponse = await axios.get('https://node.instasales.in/api/v1/cart', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const cart = cartResponse.data.cart;
      const cartProduct = cart.products.find(p => p.product._id === data.product._id);
      if (cartProduct) {
        setIsInCart(true);
        setCartQuantity(cartProduct.quantity);
        setQuantity(cartProduct.quantity);
      }
    } catch (error) {
      console.error('Error fetching product details:', error);
    } finally {
      setLoading(false); // Set loading to false after fetching
    }
  };


  useEffect(() => {
    const fetchCreatorData = async () => {
        try {
            const response = await axios.get(`https://node.instasales.in/api/v1/creator/creators/${creatorslug}`);
            setCreator(response.data.creator);
            setCreatorLoading(false)
console.log("creator data",response)
        } catch (error) {
            console.error('Error fetching creator data:', error);
            setCreatorLoading(false)
        }
    };

    fetchCreatorData();

}, [creatorslug]);

 

  useEffect(() => {
    const fetchProducts = async () => {
        if (!creator?._id) return;
        try {
            const response = await axios.get(`https://node.instasales.in/api/v1/creator/products-promoted-by/${creator?._id}`);
            setProducts(response.data.products);
            setProductsLoading(false);

        } catch (err) {
            console.log(err)
            setProductsLoading(false);
        }
    };

    fetchProducts();
}, [creator?._id]);

  useEffect(() => {
    fetchProductDetails();
  }, [id]);

  if (loading) {
    return (
      <div className="wishlist-loading">
        <ClipLoader size={50} color={"#123abc"} loading={loading} />
      </div>
    );
  }

  if (!product) {
    return <div>Loading...</div>;
  }

  const handleAddToWishlist = async (productId) => {
    const token = localStorage.getItem('token');

    try {
      await axios.put('https://node.instasales.in/api/v1/wishlist', { productId }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setIsInWishlist(true);
    } catch (error) {
      console.error('Error updating wishlist:', error);
    }
  };

  const handleRemoveFromWishlist = async (productId) => {
    const token = localStorage.getItem('token');

    try {
      await axios.put('https://node.instasales.in/api/v1/wishlist/remove', { productId }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setIsInWishlist(false);
    } catch (error) {
      console.error('Error removing from wishlist:', error);
    }
  };

  const handleAddToCart = async (productId, quantity) => {
    const token = localStorage.getItem('token');

    try {
      await axios.post('https://node.instasales.in/api/v1/cart', { productId, quantity, variant: selectedVariation }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setCartFeedback('Added to Cart');
      setTimeout(() => setCartFeedback(''), 3000);
      fetchProductDetails();
    } catch (error) {
      console.error('Error adding to cart:', error);
    }
  };

  const handleIncrement = () => {
    setQuantity(prevQuantity => prevQuantity + 1);
  };

  const handleDecrement = () => {
    if (quantity > 1) {
      setQuantity(prevQuantity => prevQuantity - 1);
    }
  };

  const handleBuyNow = async () => {
    const token = localStorage.getItem('token');

    addProduct({ ...product, selectedVariation }, quantity);
    if (!user) {
      navigate('/auth2');
    } else {
      try {
        const addressResponse = await axios.get('https://node.instasales.in/api/v1/address', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        if (!addressResponse.data.addresses) {
          navigate('/add-address');
        } else {
          navigate('/product-summary', { state: { product, selectedVariation, quantity } });
        }
      } catch (error) {
        console.error('Error checking address:', error);
      }
    }
  };

  const handleVariationChange = (variation) => {
    console.log("variation", variation)
    setSelectedVariation(variation);
  };

  const handleCategoryClick = (category) => {
    window.location.href = (`/categories/${category}`);
  };
  // Add products loading state
  const responsive = {
    superLargeDesktop: { breakpoint: { max: 4000, min: 3000 }, items: 4 },
    desktop: { breakpoint: { max: 3000, min: 1024 }, items: 4 },
    tablet: { breakpoint: { max: 1024, min: 464 }, items: 2 },
    mobile: { breakpoint: { max: 464, min: 0 }, items: 1 }
  };

  const goToPrev = () => {
    carouselRef.current.previous();
  };

  const goToNext = () => {
    carouselRef.current.next();
  };
  const handleHoverDivClick = (link) => {

        window.location.href = link;
  


};

  return (
    <>
      <Helmet>
        <title>{`${product?.name} - Buy Now from ${product?.brandName}`}</title>
        <meta name="description" content={product?.description.substring(0, 160)} />
        <meta name="keywords" content={`${product?.brandName}, ${product?.name}, ${product?.category}, ${product?.subCategory}, ${product?.subSubCategory}`} />
        <meta name="author" content={product?.brandName} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:title" content={`${product?.name} - Buy Now from ${product?.brandName}`} />
        <meta property="og:description" content={product?.description.substring(0, 160)} />
        <meta property="og:type" content="product" />
      </Helmet>
      <div className="product home creator">
        <div className="product-background-semicircle"></div>
        <Row className="product-details">
          <Col className="product-img" lg={4}>
            <img src={activeImage} alt="active-image" className="product-img-active" />
          </Col>
          <Col className="product-slider-thumbnails" lg={1}>
            {product?.images?.map((img, index) => (
              <img
                key={index}
                onClick={() => setActiveImage(img.url)}
                src={img.url}
                alt=""
                className={`product-slider-thumbnail pst ${activeImage === img.url ? 'active' : ''}`}
              />
            ))}
          </Col>
          <Col lg={7} className="product-info">
            <div className="product-h">{product?.name}</div>
            <div className="product-features inline">
              <Link to={`/brand/${product.brand_slug}`} className="product-feat"><span className="bold">Brand:</span>&nbsp;{product?.brand_name}</Link>
              <div onClick={() => handleCategoryClick(product.category)} className="product-feat"><span className="bold">Category:</span>&nbsp;{product.category}</div>
            </div>
            <div className="product-quantity inline">
              <div className="product-price">M.R.P.: <span className="product-p-original">₹{selectedVariation?.listing_price}</span></div>
              <div className="product-p-derived">₹{selectedVariation?.selling_price}</div>
              <Button className="product-btn-sub" onClick={handleDecrement}>-</Button>
              <span className="product-number">{quantity}</span>
              <Button className="product-btn-add" onClick={handleIncrement}>+</Button>
            </div>
            <div className="product-btns inline">
              {selectedVariation?.stock > 0 ?
                <>
                  <Button className="product-buy" onClick={handleBuyNow}>
                    <FontAwesomeIcon icon={faCheck} size='xl' />&nbsp;Buy Now
                  </Button>
                  <Button className="product-cart" onClick={() => handleAddToCart(product._id, quantity)}>
                    <FontAwesomeIcon icon={faPlus} />&nbsp;{isInCart ? `In Cart (${cartQuantity})` : (cartFeedback || 'Add to Cart')}
                  </Button>
                </>
                :
                <>
                  <Button className="product-oos">
                    {selectedVariation?.stock > 0 ? "In Stock" : "Out of Stock"}
                  </Button>
                  <a href="/" className="product-notify">Notify me</a>
                </>
              }
            </div>
            <Button
              onClick={() => isInWishlist ? handleRemoveFromWishlist(product._id) : handleAddToWishlist(product._id)}
              className={`product-wishlist inline ${isInWishlist ? 'active' : ''}`}>
              <FontAwesomeIcon className={`heart ${isInWishlist ? 'active' : ''}`} icon={faHeart} />&nbsp;{isInWishlist ? 'Added to Wishlist' : 'Add to Wishlist'}
            </Button>
            <div><span className="bold">Variant: </span>{selectedVariation?.name}</div>
            <div className="product-net-quantities">
              {product?.variations[0]?.options?.map((variation, index) => (
                <Button key={index} className={`product-net-quantity ${selectedVariation?._id === variation._id ? 'selected' : ''}`} onClick={() => handleVariationChange(variation)}>
                  {variation.name}
                </Button>
              ))}
            </div>
            {product.size_chart && <Link to={`${product.size_chart}`} className="size-chart"> Size Chart</Link>}
            <div className="product-description">
              <div className="product-desc-h">Description:</div>
              <div>{product?.description}</div>
            </div>
          </Col>
        </Row>

        {productsLoading ? (
          <div className="wishlist-loading">
            <ClipLoader size={50} color={"#123abc"} loading={brandLoading} />
          </div>
        ) : (
          <>
            <>

              <Row className={`creator-products-row bg-${'green'}`}>
                <Col lg={4} className="creator-products-content">
                  <div className="creator-products-h">{`${creator.creatorName} Other's Promoted Products`}</div>
                  <div className="creator-products-p"></div>
                  <div className="creator-products-ctrl">
                    <Button className="custom-left-arrow" onClick={() => goToPrev()}>
                      <FontAwesomeIcon icon={faCaretLeft} size='2x' />
                    </Button>
                    <Button className="custom-right-arrow" onClick={() => goToNext()}>
                      <FontAwesomeIcon icon={faCaretRight} size='2x' />
                    </Button>
                  </div>
                  <Button className="creator-products-btn" onClick={() => handleHoverDivClick(`/creator/${creatorslug}`)}>View All</Button>
                </Col>
                <Col lg={8} className="creator-products-carousel-col">
                  <Carousel
                    responsive={responsive}
                    arrows={false}
                    ref={carouselRef}
                    className="creator-products-carousel"
                  >
                    {products.map((product, idx) => (
                      <Link to={`/product/${product.brand_slug}/${product._id}`} key={product._id}>
                        <Card key={idx} className="creator-products-card">
                          <img src={product.images[0]?.url } alt="card-img" className="creator-products-card-img" />
                          <div className="creator-products-price">₹{product?.variations[0]?.options[0]?.selling_price}</div>
                          <div className="creator-products-title">{product.name}</div>
                          <div className="creator-products-desc">{product.description}</div>
                        </Card>
                      </Link>
                    ))}
                  </Carousel>
                </Col>
              </Row>


            </>
          </>
        )}
      </div>
    </>
  );
};

export default Product;