import React, { useEffect, useState } from 'react';
import './sumarry.css';
import { Row, Col, Card, Button } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import card from "../../media/card.svg";
import truck from "../../media/truck.svg";
import cc from "../../media/cc.svg";
import hand from "../../media/hand.png";
import ClipLoader from 'react-spinners/ClipLoader';

const OrderConfirmationMultiple = () => {
    const location = useLocation();
    const { orders } = location.state || {};
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (orders) {
            setLoading(false);
        }
    }, [orders]);

    if (loading) {
        return (
            <div className="wishlist-loading">
                <ClipLoader size={50} color={"#123abc"} loading={loading} />
            </div>
        );
    }

    if (!orders || orders.length === 0) {
        return <div>No orders found.</div>;
    }

    return (
        <div className="order-confirmation">
            <div className="confirmation-header">
                <h2><img src={hand} alt="" />Congrats! Your Orders were Placed</h2>
            </div>
            {orders.map((order) => (
                <div key={order._id} className="single-order">
                    <Col lg={3} className="order-item-text track-order-btn-multiple">
                        <Link to="/profile#order"><Button className="track-order-btn ">Track Order</Button></Link>
                    </Col>
                    {order.orderItems.map((ord, index) => (
                        <div key={ord._id} className='single-pro'>
                            <Card className="confirmation-card-multiple">
                                <Row className="order-item-details">
                                    <Col lg={3} className="order-item-img-container">
                                        <img src={ord.image} className="order-item-img order-item-img-multiple " alt={ord.name} />
                                    </Col>
                                    <Col lg={6} className="order-item-text">
                                        <h4>{ord.name}</h4>
                                        <p>Order Id: {order._id}</p>
                                        {/* <p>Brand: {ord.product.brand_name}</p> */}
                                        <p>Variant: {ord.variation?.name}</p>
                                        <p>quantity: {ord.quantity}</p>
                                        <p className='price'>₹{ord.price}</p>
                                    </Col>
                                </Row>
                            </Card>
                            {index < order.orderItems.length - 1 && (
                                <div style={{ maxWidth: '946px', width: "90%", height: '2px', background: '#C6C6C6', margin: '23px 0' }} />
                            )}
                        </div>
                    ))}

                </div>
            ))}
            <div className="order-details">
                <h4>Order Details</h4>
                <Row className='oderdetailrow'>
                    <Col lg={4} className="order-detail-section">
                        <img src={cc} alt="" className='img-confirm' />
                        <h5>Contact Information</h5>
                        <p>{orders[0]?.user.email}</p>
                        <p>Ph no. {orders[0]?.shippingInfo.phoneNo}</p>
                    </Col>
                    <Col lg={4} className="order-detail-section">
                        <img src={truck} alt="" className='img-confirm' />
                        <h5>Deliver to:</h5>
                        <p>{orders[0]?.shippingInfo.address}</p>
                        <p>{orders[0]?.shippingInfo.city}, {orders[0]?.shippingInfo.state}, {orders[0]?.shippingInfo.country} {orders[0]?.shippingInfo.pinCode}</p>
                    </Col>
                    <Col lg={4} className="order-detail-section">
                        <img src={card} alt="" className='img-confirm' />
                        <h5>Payment Details</h5>
                        <p>Debit Card</p>
                        <p>₹{orders.reduce((total, order) => total + order.totalPrice, 0)}</p> {/* Sum of all orders */}
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default OrderConfirmationMultiple;
