import React, { useContext, useEffect, useState } from 'react';
import { Container, Row, Col, Card, Button, Spinner } from 'react-bootstrap';
import axios from 'axios';
import { CreatorContext } from '../CreatorContext';

function CreatorManageProducts() {
  const [topPicks, setTopPicks] = useState([]);
  const [promotedProducts, setPromotedProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { creator } = useContext(CreatorContext);

  useEffect(() => {
    const fetchTopPicks = async () => {
      try {
        const token = localStorage.getItem('Ctoken');
        const response = await axios.get(`https://node.instasales.in/api/v1/creator/${creator._id}/top-picks`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setTopPicks(response.data.topPicks);
      } catch (error) {
        setError('Error fetching top picks');
      }
    };

    const fetchPromotedProducts = async () => {
      try {
        const token = localStorage.getItem('Ctoken');
        const response = await axios.get(`https://node.instasales.in/api/v1/creator/products-promoted-by/${creator._id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setPromotedProducts(response.data.products);
      } catch (error) {
        setError('Error fetching promoted products');
      }
    };

    fetchTopPicks();
    fetchPromotedProducts();
    setLoading(false);
  }, [creator?._id]);

  const handleRemoveTopPick = async (productId) => {
    try {
      const token = localStorage.getItem('Ctoken');
      await axios.delete(`https://node.instasales.in/api/v1/creator/top-picks/${productId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // Remove the product from the top picks in real-time
      setTopPicks((prevTopPicks) => prevTopPicks.filter((product) => product._id !== productId));
    } catch (error) {
      console.error('Error removing product from top picks:', error);
    }
  };

  const handleRemovePromotedProduct = async (productId) => {
    try {
      const token = localStorage.getItem('Ctoken');
      await axios.delete(`https://node.instasales.in/api/v1/creator/promoted-products/${productId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // Remove the product from promoted products in real-time
      setPromotedProducts((prevPromotedProducts) => prevPromotedProducts.filter((product) => product._id !== productId));
    } catch (error) {
      console.error('Error removing product from promoted list:', error);
    }
  };

  const handleAddToTopPicks = async (productId) => {
    try {
      const token = localStorage.getItem('Ctoken');
      const response = await axios.post(`https://node.instasales.in/api/v1/creator/top-picks/${productId}`, {}, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // Add the product to the top picks in real-time
      const addedProduct = promotedProducts.find((product) => product._id === productId);
      setTopPicks((prevTopPicks) => [...prevTopPicks, addedProduct]);
      alert('Product added to Top Picks!');
    } catch (error) {
      console.error('Error adding product to top picks:', error);
      alert('Failed to add product to Top Picks');
    }
  };

  // Filter out the promoted products that are already in the top picks
  const filteredPromotedProducts = promotedProducts.filter(
    (promotedProduct) => !topPicks.some((topPick) => topPick._id === promotedProduct._id)
  );

  if (loading) {
    return <Spinner animation="border" />;
  }

  return (
    <Container className="creator-manage-products my-4">
      {/* Top Picks Section */}
      <Row className="mb-4">
        <Col md={12}>
          <h5>Your Top Picks</h5>
        </Col>
      </Row>
      <Row>
        {topPicks.length > 0 ? (
          topPicks.map((product) => (
            <Col xs={12} sm={6} md={4} lg={3} key={product._id}>
              <Card className="product-card">
                <Card.Img variant="top" src={product.images[0].url} />
                <Card.Body>
                  <Card.Title>{product.name}</Card.Title>
                  <Card.Text>{product.selling_price} ₹</Card.Text>
                  <Button
                    variant="danger"
                    className="mt-2"
                    onClick={() => handleRemoveTopPick(product._id)}
                  >
                    Remove from Top Picks
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          ))
        ) : (
          <p>No top picks available</p>
        )}
      </Row>

      {/* Promoted Products Section */}
      <Row className="mt-5 mb-4">
        <Col md={12}>
          <h5>My Products</h5>
        </Col>
      </Row>
      <Row>
        {filteredPromotedProducts.length > 0 ? (
          filteredPromotedProducts.map((product) => (
            <Col xs={12} sm={6} md={4} lg={3} key={product._id}>
              <Card className="product-card">
                <Card.Img variant="top" src={product.images[0].url} />
                <Card.Body>
                  <Card.Title>{product.name}</Card.Title>
                  <Card.Text>{product.selling_price} ₹</Card.Text>
                  <Button
                    variant="primary"
                    className="mt-2"
                    onClick={() => handleAddToTopPicks(product._id)}
                  >
                    Add to Top Picks
                  </Button>
                  <Button
                    variant="danger"
                    className="mt-2"
                    onClick={() => handleRemovePromotedProduct(product._id)}
                  >
                    Remove from Promoted
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          ))
        ) : (
          <p>No promoted products available</p>
        )}
      </Row>
    </Container>
  );
}

export default CreatorManageProducts;
