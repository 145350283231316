import React, { Fragment, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import "./reset.css";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import LockIcon from "@material-ui/icons/Lock";
import MetaData from "../layout copyy/MetaData";
import axios from "axios";

const ResetPassword = () => {
    //   const history = useHistory();
    const { token } = useParams();

    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const resetPasswordSubmit = async (e) => {
        e.preventDefault();

        const myForm = new FormData();
        myForm.set("password", password);
        myForm.set("confirmPassword", confirmPassword);

        try {
            const config = { headers: { "Content-Type": "application/json" } };

            const { data } = await axios.put(
                `https://node.instasales.in/api/v1/admin/password/reset/${token}`,
                { password, confirmPassword },
                config
            );
            console.log("data0", data)
            alert('Password updated successfully!');
            //   history.push("/login");
        } catch (error) {
            console.log("error", error)
            alert('Error updating password');
        }
    };

    return (
        <Fragment>
            <MetaData title="Change Password" />
            <div className="resetPasswordContainer">
                <div className="resetPasswordBox">
                    <h2 className="resetPasswordHeading">Reset Password</h2>
                    <form className="resetPasswordForm" onSubmit={resetPasswordSubmit}>
                        <div className="inputContainer">
                            <LockOpenIcon />
                            <input
                                type="password"
                                placeholder="New Password"
                                required
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className="inputField"
                            />
                        </div>
                        <div className="inputContainer">
                            <LockIcon />
                            <input
                                type="password"
                                placeholder="Confirm Password"
                                required
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                className="inputField"
                            />
                        </div>
                        <input
                            type="submit"
                            value="Update"
                            className="resetPasswordBtn"
                        />
                    </form>
                </div>
            </div>
        </Fragment>
    );
};

export default ResetPassword;
