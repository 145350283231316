import React, { useState, useRef, useEffect } from 'react';
import "./home.css";
import { Button, Card, Col, Placeholder, Row, Spinner } from "react-bootstrap";
import pic from "../../media/profile.jpeg";
import cardbg from "../../media/card.png";
import p1 from "../../media/p1.png";
import p2 from "../../media/p2.png";
import p3 from "../../media/p3.png";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faCaretRight, faCertificate, faEarthAsia, faHeadset, faShieldHalved, faStar } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { Link } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';

const Home = () => {
    const [show, setShow] = useState(false);
    const carouselRef = useRef(null);
    const carousel1Ref = useRef(null);

    const responsive = {
        superLargeDesktop: { breakpoint: { max: 4000, min: 3000 }, items: 4 },
        desktop: { breakpoint: { max: 3000, min: 1024 }, items: 4 },
        tablet: { breakpoint: { max: 1024, min: 464 }, items: 2 },
        mobile: { breakpoint: { max: 464, min: 0 }, items: 1 }
    };

    const responsive1 = {
        all: { breakpoint: { max: 4000, min: 0 }, items: 1 }
    };

    const responsive2 = {
        superLargeDesktop: { breakpoint: { max: 4000, min: 3000 }, items: 3 },
        desktop: { breakpoint: { max: 3000, min: 1024 }, items: 3 },
        tablet: { breakpoint: { max: 1024, min: 464 }, items: 2 },
        mobile: { breakpoint: { max: 464, min: 0 }, items: 1 }
    };

    const goToPrev = () => carouselRef.current.previous();
    const goToNext = () => carouselRef.current.next();
    const goToPrev1 = () => carousel1Ref.current.previous();
    const goToNext1 = () => carousel1Ref.current.next();

    const [brands, setBrands] = useState([]);
    const [loadingBrands, setLoadingBrands] = useState(true);
    const [errorBrands, setErrorBrands] = useState(null);

    useEffect(() => {
        const fetchBrands = async () => {
            try {
                const { data } = await axios.get('https://node.instasales.in/api/v1/brand/brands');
                setBrands(data.brands);
                setLoadingBrands(false);
                console.log(data)
            } catch (err) {
                setErrorBrands('Failed to fetch brands.');
                setLoadingBrands(false);
                console.log(err)

            }
        };
        fetchBrands();
    }, []);

    const [creatorsData, setCreatorsData] = useState([]);
    const [loadingCreators, setLoadingCreators] = useState(true);
    const [errorCreators, setErrorCreators] = useState(null);

    useEffect(() => {
        const fetchCreators = async () => {
            try {
                const response = await axios.get('https://node.instasales.in/api/v1/creator/creators');
                setCreatorsData(response.data.creators);
                setLoadingCreators(false);
            } catch (error) {
                setErrorCreators('Error fetching creators data.');
                setLoadingCreators(false);
            }
        };
        fetchCreators();
    }, []);

    const [products, setProducts] = useState([]);
    const [loadingProducts, setLoadingProducts] = useState(true);
    const [errorProducts, setErrorProducts] = useState(null);

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const { data } = await axios.get('https://node.instasales.in/api/v1/products');
                setProducts(data.product);
                setLoadingProducts(false);
                console.log(data)
            } catch (error) {
                setErrorProducts('Error fetching products.');
                setLoadingProducts(false);
            }
        };
        fetchProducts();
    }, []);

    // const [products, setProducts] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(1);

    const fetchMoreProducts = async () => {
        try {
            const { data } = await axios.get(`https://node.instasales.in/api/v1/products?page=${page}&limit=10`);
            setProducts((prevProducts) => [...prevProducts, ...data.products]);
            setHasMore(data.products.length > 0); // Stop loading if no more products
            setPage(page + 1);
        } catch (error) {
            console.error('Error fetching more products:', error);
        }
    };

    const handleHoverDivClick = (link) => {
        window.location.href = link;
    };

    const chunkArray = (array, size) => {
        const chunkedArray = [];
        for (let i = 0; i < array?.length; i += size) {
            chunkedArray.push(array.slice(i, i + size));
        }
        return chunkedArray;
    };

    const brandChunks = chunkArray(brands, 4);

    const maxBrandsToShow = 12;
    const brandsToShow = brandChunks.flat()?.length > maxBrandsToShow ? brandChunks.flat().slice(0, maxBrandsToShow) : brandChunks.flat();

    const formatFollowers = (count) => {
        if (count >= 1000000) {
            return (count / 1000000).toFixed(1) + 'M';
        } else if (count >= 1000) {
            return (count / 1000).toFixed(1) + 'k';
        }
        return count;
    };
    return (
        <div className="home">
            <Row className="home-top">
                <Col lg={3} className="home-color"></Col>
                <Card className='home-card'>
                    {loadingCreators ? (
                        <div className='card-img home-img spinner-crea'>

                            <Spinner animation="border" className='spinner-creator' />
                        </div>
                    ) : (
                        <Carousel responsive={responsive1} arrows={false} autoPlay={true} autoPlaySpeed={5000} rewind={true} rewindWithAnimation={true}>
                            {creatorsData?.map((creator) => (
                                <div key={creator._id} onClick={() => handleHoverDivClick(`/creator/${creator.slug}`)}>
                                    <Card.Img src={creator.avatar.url || pic} className='home-img' />
                                    <Card.ImgOverlay className='home-overlay'>
                                        <Card.Title className='home-card-title'>{creator.creatorName}</Card.Title>
                                        <Card.Text className='home-card-text'>{creator?.creatorType}</Card.Text>
                                    </Card.ImgOverlay>
                                </div>
                            ))}
                        </Carousel>
                    )}
                </Card>
                <Col lg={9} className="home-white">
                    <p className="home-txt-h">Connecting <span className="home-txt-fm">E-Commerce</span> and Community.</p>
                    <p className="home-txt">We are a platform that works with creators and brands and make their Wishlist available to you guys. Explore the best pieces that are particularly chosen by your favorite creators.</p>
                </Col>
            </Row>
            {!show ? (
                <Row className="home-brands">
                    <Col className="home-brands-content" lg={11}>
                        <Row className="home-brands-head">
                            <Col lg={10} className="home-brands-txt">
                                <div className="home-brands-h">Top Featured Brands.</div>
                                <div className="home-brands-p">consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</div>
                            </Col>
                            <Col lg={2}>
                                <Button className="home-brands-btn" onClick={() => setShow(!show)}>View All</Button>
                            </Col>
                        </Row>
                        {loadingBrands ? (
                            <>
                                {/* <Spinner animation="border" /> */}
                                <Row className="home-brands-pl-row">
                                    <Col lg={3} className="home-brands-pl-col">
                                        <Placeholder as={Col} animation="glow">
                                            <Placeholder lg={12} className="home-brands-pl" />
                                        </Placeholder>
                                    </Col>
                                    <Col lg={3} className="home-brands-pl-col">
                                        <Placeholder as={Col} animation="glow">
                                            <Placeholder lg={12} className="home-brands-pl" />
                                        </Placeholder>
                                    </Col>
                                    <Col lg={3} className="home-brands-pl-col">
                                        <Placeholder as={Col} animation="glow">
                                            <Placeholder lg={12} className="home-brands-pl" />
                                        </Placeholder>
                                    </Col>
                                    <Col lg={3} className="home-brands-pl-col">
                                        <Placeholder as={Col} animation="glow">
                                            <Placeholder lg={12} className="home-brands-pl" />
                                        </Placeholder>
                                    </Col>
                                </Row>
                                <Row className="home-brands-pl-row">
                                    <Col lg={3} className="home-brands-pl-col">
                                        <Placeholder as={Col} animation="glow">
                                            <Placeholder lg={12} className="home-brands-pl" />
                                        </Placeholder>
                                    </Col>
                                    <Col lg={3} className="home-brands-pl-col">
                                        <Placeholder as={Col} animation="glow">
                                            <Placeholder lg={12} className="home-brands-pl" />
                                        </Placeholder>
                                    </Col>
                                    <Col lg={3} className="home-brands-pl-col">
                                        <Placeholder as={Col} animation="glow">
                                            <Placeholder lg={12} className="home-brands-pl" />
                                        </Placeholder>
                                    </Col>
                                    <Col lg={3} className="home-brands-pl-col">
                                        <Placeholder as={Col} animation="glow">
                                            <Placeholder lg={12} className="home-brands-pl" />
                                        </Placeholder>
                                    </Col>
                                </Row>
                            </>

                        ) : (
                            brandChunks.slice(0, 2)?.map((chunk, index) => (
                                <Row key={index} className="home-brands-pl-row">
                                    {chunk?.map((brand, i) => (
                                        <Col key={i} lg={3} className="home-brands-pl-col" onClick={() => handleHoverDivClick(`/brand/${brand.slug}`)}>
                                            <Placeholder as={Col} animation="glow">
                                                <img src={brand.brandLogo.url} alt="" className="home-brands-pl brands-img" />
                                            </Placeholder>
                                        </Col>
                                    ))}
                                </Row>
                            ))
                        )}
                    </Col>
                    <Col className="home-brands-color" lg={1}></Col>
                </Row>
            ) : (
                <Row className="home-brands-animate">
                    <center>
                        {loadingBrands ? (
                            <>
                                <center>
                                    <Row className="home-brands-a-pl-row">
                                        <Col lg={3} className="home-brands-a-pl-col">
                                            <Placeholder as={Col} animation="glow">
                                                <Placeholder lg={12} className="home-brands-a-pl" />
                                            </Placeholder>
                                        </Col>
                                        <Col lg={3} className="home-brands-a-pl-col">
                                            <Placeholder as={Col} animation="glow">
                                                <Placeholder lg={12} className="home-brands-a-pl" />
                                            </Placeholder>
                                        </Col>
                                        <Col lg={3} className="home-brands-a-pl-col">
                                            <Placeholder as={Col} animation="glow">
                                                <Placeholder lg={12} className="home-brands-a-pl" />
                                            </Placeholder>
                                        </Col>
                                        <Col lg={3} className="home-brandsa-a-pl-col">
                                            <Placeholder as={Col} animation="glow">
                                                <Placeholder lg={12} className="home-brands-a-pl" />
                                            </Placeholder>
                                        </Col>
                                    </Row>
                                    <Row className="home-brands-a-pl-row">
                                        <Col lg={3} className="home-brands-a-pl-col">
                                            <Placeholder as={Col} animation="glow">
                                                <Placeholder lg={12} className="home-brands-a-pl" />
                                            </Placeholder>
                                        </Col>
                                        <Col lg={3} className="home-brands-a-pl-col">
                                            <Placeholder as={Col} animation="glow">
                                                <Placeholder lg={12} className="home-brands-a-pl" />
                                            </Placeholder>
                                        </Col>
                                        <Col lg={3} className="home-brands-a-pl-col">
                                            <Placeholder as={Col} animation="glow">
                                                <Placeholder lg={12} className="home-brands-a-pl" />
                                            </Placeholder>
                                        </Col>
                                        <Col lg={3} className="home-brandsa-a-pl-col">
                                            <Placeholder as={Col} animation="glow">
                                                <Placeholder lg={12} className="home-brands-a-pl" />
                                            </Placeholder>
                                        </Col>
                                    </Row>
                                    <Row className="home-brands-a-pl-row">
                                        <Col lg={3} className="home-brands-a-pl-col">
                                            <Placeholder as={Col} animation="glow">
                                                <Placeholder lg={12} className="home-brands-a-pl" />
                                            </Placeholder>
                                        </Col>
                                        <Col lg={3} className="home-brands-a-pl-col">
                                            <Placeholder as={Col} animation="glow">
                                                <Placeholder lg={12} className="home-brands-a-pl" />
                                            </Placeholder>
                                        </Col>
                                        <Col lg={3} className="home-brands-a-pl-col">
                                            <Placeholder as={Col} animation="glow">
                                                <Placeholder lg={12} className="home-brands-a-pl" />
                                            </Placeholder>
                                        </Col>
                                        <Col lg={3} className="home-brands-a-pl-col">
                                            <Placeholder as={Col} animation="glow">
                                                <Placeholder lg={12} className="home-brands-a-pl" />
                                            </Placeholder>
                                        </Col>
                                    </Row>
                                </center>
                            </>
                        ) : (
                            chunkArray(brandsToShow, 4)?.map((chunk, index) => (
                                <Row key={index} className="home-brands-a-pl-row">
                                    {chunk?.map((brand, i) => (
                                        <Col key={i} lg={3} className="home-brands-a-pl-col" onClick={() => handleHoverDivClick(`/brand/${brand.slug}`)}>
                                            <Placeholder as={Col} animation="glow">
                                                <img src={brand.brandLogo.url} alt="" className="home-brands-a-pl brands-img" />
                                            </Placeholder>
                                        </Col>
                                    ))}
                                </Row>
                            ))
                        )}
                        <Button className="home-brands-a-btn" onClick={() => setShow(!show)}>See less</Button>
                    </center>
                </Row>
            )}
            <div className="home-creators">
                <Row className="home-creators-head">
                    <Col lg={10} className="home-creators-txt">
                        <div className="home-creators-h">Top Featured Creators.</div>
                        <div className="home-creators-p">consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</div>
                    </Col>
                    <Col lg={2} className="home-creators-ctrl">
                        <Button className="custom-left-arrow" onClick={goToPrev}>
                            <FontAwesomeIcon icon={faCaretLeft} size='2x' />
                        </Button>
                        <Button className="custom-right-arrow" onClick={goToNext}>
                            <FontAwesomeIcon icon={faCaretRight} size='2x' />
                        </Button>
                    </Col>
                </Row>
                <div className="home-creators-card-wrapper">
                    <div className="home-creators-card-bg"></div>
                    {loadingCreators ? (
                       <div className="wishlist-loading-product">
                       <Spinner animation="border" />
                   </div>
                    ) : (
                        <Carousel responsive={responsive} className="home-creators-cards" ref={carouselRef} showDots={false} arrows={false}>
                            {creatorsData?.map((creator) => (
                                <Link to={`/creator/${creator.slug}`} key={creator._id}>
                                    <Card className="text-white home-creators-card">
                                        <Card.Img src={creator?.avatar?.url ? creator?.avatar?.url : cardbg} alt="Card image" className="home-creators-card-img" />
                                        <Card.ImgOverlay className="home-creators-card-txt">
                                            <Card.Title className="home-creators-card-h">{creator?.creatorName}</Card.Title>
                                            <Card.Text className="home-creators-card-p">{creator?.creatorType}</Card.Text>
                                        </Card.ImgOverlay>
                                        <span className="home-creators-card-stats">{formatFollowers(creator.followers)}</span>
                                    </Card>
                                </Link>
                            ))}
                        </Carousel>
                    )}
                </div>
            </div>
            <div className="home-discover">
                <div className="home-discover-h">Discover More Creators</div>
                <div className="home-discover-p">Unlock Hidden Treasures from Diverse Creators</div>
                <Button className="home-discover-btn" onClick={() => handleHoverDivClick('/creators')}>
                    <FontAwesomeIcon icon={faCertificate} className="home-discover-icon" /> <span className="home-discover-btn-p">Discover Creators</span>
                </Button>
            </div>
            <div className="home-products">
                <Row className="home-products-row">
                    <Col lg={4} className="home-products-content">
                        <div className="home-products-h">Featured Products</div>
                        <div className="home-products-p">Be The First to Grab</div>
                        <div className="home-products-ctrl">
                            <Button className="custom-left-arrow" onClick={goToPrev1}>
                                <FontAwesomeIcon icon={faCaretLeft} size='2x' />
                            </Button>
                            <Button className="custom-right-arrow" onClick={goToNext1}>
                                <FontAwesomeIcon icon={faCaretRight} size='2x' />
                            </Button>
                        </div>
                        <Link to={'/all-featured'}><Button className="home-products-btn">View All</Button> </Link>
                    </Col>
                    <Col lg={8} className="home-products-carousel-col">
                        {loadingProducts ? (
                            <div className="wishlist-loading-product">
                                <Spinner animation="border" />
                            </div>
                        ) : (
                            <Carousel responsive={responsive2} arrows={false} ref={carousel1Ref} className="home-products-carousel">
                                {products?.map((product) => (
                                    <Link to={`/product/${product.brand_slug}/${product._id}`} key={product._id}>
                                        <Card className="home-products-card">
                                            <img src={product?.images[0]?.url} alt="card-img" className="home-products-card-img product-card-img" />
                                            <div className="home-products-price">₹{product?.variations[0]?.options[0]?.selling_price}</div>
                                            <div className="home-products-title">{product?.name}</div>
                                            <div className="home-products-desc">{product?.description}</div>
                                        </Card>
                                    </Link>
                                ))}
                            </Carousel>
                        )}
                    </Col>
                </Row>
            </div>
            <div className="home-values">
                <center>
                    <div className="home-values-h">Values we provide</div>
                    <div className="home-values-p">consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</div>
                    <Row className="home-values-content">
                        <Col className="home-values-col">
                            <FontAwesomeIcon size='3x' icon={faEarthAsia} />
                            <div className="home-values-col-h">World Wide Shipping</div>
                            <div className="home-values-col-p">Fast, Reliable Delivery System</div>
                            <Button className="home-values-btn">Learn More</Button>
                        </Col>
                        <Col className="home-values-col">
                            <FontAwesomeIcon size='3x' icon={faShieldHalved} />
                            <div className="home-values-col-h">Secure Payments</div>
                            <div className="home-values-col-p">Your Transaction Safeguard</div>
                            <Button className="home-values-btn">Learn More</Button>
                        </Col>
                        <Col className="home-values-col">
                            <FontAwesomeIcon size='3x' icon={faHeadset} />
                            <div className="home-values-col-h">Local Support</div>
                            <div className="home-values-col-p">Your Hometown Helpers</div>
                            <Button className="home-values-btn">Learn More</Button>
                        </Col>
                        <Col className="home-values-col">
                            <FontAwesomeIcon size='3x' icon={faStar} />
                            <div className="home-values-col-h">Trusted Brands</div>
                            <div className="home-values-col-p">Your Reputable Options</div>
                            <Button className="home-values-btn">Learn More</Button>
                        </Col>
                    </Row>
                </center>
            </div>
        </div>
    );
};

export default Home;
