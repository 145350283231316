import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Card, Form, Spinner, Alert } from 'react-bootstrap';
import axios from 'axios';
import './brandorder.css'; // Custom CSS for styling

function BrandOrders() {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  // Fetch orders from the backend
  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const token = localStorage.getItem('Btoken'); // Get the auth token
        const response = await axios.get('https://node.instasales.in/api/v1/brand/orderss', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log("response",response)
        setOrders(response.data.orders);
      } catch (err) {
        setError('Failed to load orders. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  // Filtered orders based on search term
  const filteredOrders = orders.filter((order) =>
    order.orderItems.some((item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  if (loading) {
    return (
      <Container className="text-center mt-5">
        <Spinner animation="border" variant="primary" />
        <p>Loading orders...</p>
      </Container>
    );
  }

  if (error) {
    return (
      <Container className="text-center mt-5">
        <Alert variant="danger">{error}</Alert>
      </Container>
    );
  }

  return (
    <Container fluid className="orders-page">
      {/* Orders History */}
      <Row className="my-4">
        <Col md={12}>
          <h4>Orders History</h4>
        </Col>
      </Row>

      {/* Search bar */}
      <Row className="mb-3">
        <Col md={12}>
          <Form.Group className="mb-3 d-flex">
            <Form.Control
              type="text"
              placeholder="Search Products"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Button variant="outline-secondary" className="ms-2">
              <i className="bi bi-search"></i>
            </Button>
          </Form.Group>
        </Col>
      </Row>

      {/* Order Cards */}
      <Row className="order-list">
        {filteredOrders.length > 0 ? (
          filteredOrders.map((order, index) => (
            <Col md={12} key={index} className="mb-3">
              <Card className="order-card p-3">
                <Row>
                  <Col md={2}>
                    <img
                      src={order.orderItems[0].image}
                      alt="Product"
                      className="img-fluid"
                    />
                  </Col>
                  <Col md={7}>
                    <h5>{order.orderItems[0].name}</h5>
                    <p>{order.orderItems[0].category}</p>
                    <p className="price">
                      ₹{order.orderItems[0].price}{' '}
                      {/* <span className="discounted-price">₹{order.orderItems[0].discount}</span> */}
                    </p>
                    <p className="customer-info">
                      <strong>{order.shippingInfo.name}</strong> <br />
                      {order.shippingInfo.addressLineOne}, {order.shippingInfo.city},{' '}
                      {order.shippingInfo.state}, {order.shippingInfo.pinCode} <br />
                      {order.shippingInfo.phone}
                    </p>
                  </Col>
                  <Col md={3} className="text-end">
                    <p>
                      <strong>Order was Placed on</strong> <br />{' '}
                      {new Date(order.createdAt).toLocaleString()}
                    </p>
                    <p>
                      <strong>Estimated Delivery:</strong> <br />{' '}
                      {new Date(order.createdAt)
                        .setDate(new Date(order.createdAt).getDate() + 5)
                        .toLocaleString()}
                    </p>
                    <Button variant="dark" className="mb-2">
                      Track Order
                    </Button>
                    {/* <Button variant="success">Download Custom Input Image</Button> */}
                  </Col>
                </Row>
              </Card>
            </Col>
          ))
        ) : (
          <Col md={12}>
            <p>No orders found for this brand.</p>
          </Col>
        )}
      </Row>
    </Container>
  );
}

export default BrandOrders;
