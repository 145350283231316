import React, { useContext, useState } from 'react';
import { Container, Row, Col, Button, Form, Card, InputGroup } from 'react-bootstrap';
import axios from 'axios';
import './storesetting.css';
import { BrandContext } from '../BrandContext';

function StoreSettings() {
  const { brand, setBrand } = useContext(BrandContext);

  const [storeName, setStoreName] = useState();
  const [socialLinks, setSocialLinks] = useState({
    instagram: '',
    twitter: '',
    facebook: '',
    youtube: '',
    snapchat: '',
    linkedin: '',
  });
  const [selectedTheme, setSelectedTheme] = useState('');
  const [message, setMessage] = useState('');

  const token = localStorage.getItem('Btoken'); // Get the auth token

  // Handle store name update
  const handleStoreNameUpdate = async () => {
    try {
      const response = await axios.put(
        'https://node.instasales.in/api/v1/brand/update-name',
        { storeName },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setMessage('Store name updated successfully');
    } catch (error) {
      setMessage('Error updating store name');
      console.error(error);
    }
  };

  // Handle social links update
  const handleSocialLinksUpdate = async () => {
    try {
      const response = await axios.put(
        'https://node.instasales.in/api/v1/brand/update-social-links',
        socialLinks,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setMessage('Social links updated successfully');
    } catch (error) {
      setMessage('Error updating social links');
      console.error(error);
    }
  };

  // Handle theme update
  const handleThemeUpdate = async () => {
    try {
      const response = await axios.put(
        'https://node.instasales.in/api/v1/brand/update-theme',
        { theme: selectedTheme },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setMessage('Theme updated successfully');
    } catch (error) {
      setMessage('Error updating theme');
      console.error(error);
    }
  };

  return (
    <Container fluid className="store-settings">
      {/* Header Section */}
      <h3 className="mt-4 mb-3">STORE SETTINGS</h3>

      {/* Store Setting Sections */}
      <Row>
        <Col md={4} className="mb-4">
          <Card>
            <Card.Body>
              <Card.Title>Basic Details</Card.Title>
              <Form.Group className="mb-3">
                <Form.Label>Brand Name</Form.Label>
                <Form.Control
                  type="text"
                  value={storeName || brand?.brandName}
                  onChange={(e) => setStoreName(e.target.value)}
                  placeholder="Enter Brand Name"
                />
              </Form.Group>
              <Button variant="primary" onClick={handleStoreNameUpdate}>
                Update
              </Button>
            </Card.Body>
          </Card>
          <Button variant="secondary" className="mt-3 w-100">
            Manage Products
          </Button>
        </Col>

        <Col md={8}>
          <Card>
            <Card.Body>
              <Card.Title>Add Social Links</Card.Title>
              {['Instagram', 'Twitter', 'Facebook', 'YouTube', 'Snapchat', 'LinkedIn'].map((social, index) => (
                <Form.Group className="mb-3" key={index}>
                  <Form.Label>{social}</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="text"
                      value={socialLinks[social.toLowerCase()]}
                      onChange={(e) =>
                        setSocialLinks({
                          ...socialLinks,
                          [social.toLowerCase()]: e.target.value,
                        })
                      }
                      placeholder={`www.${social.toLowerCase()}.com/yourid`}
                    />
                    <InputGroup.Text>
                      <i className="bi bi-pencil"></i>
                    </InputGroup.Text>
                  </InputGroup>
                </Form.Group>
              ))}
              <Button variant="primary" onClick={handleSocialLinksUpdate}>
                Update Social Links
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Theme Selection */}
      <Row className="my-4">
        <Col md={12}>
          <h4>Available Store Themes</h4>
          <div className="theme-selection">
            {['Lime Green', 'Cool Blue', 'Dark Grey', 'Light Purple'].map((theme, index) => (
              <Button
                key={index}
                variant={selectedTheme === theme ? 'primary' : 'outline-secondary'}
                className="me-2"
                onClick={() => setSelectedTheme(theme)}
              >
                {theme}
              </Button>
            ))}
            <Button variant="primary" onClick={handleThemeUpdate}>
              Update Theme
            </Button>
          </div>
        </Col>
      </Row>

      {/* Display a success or error message */}
      {message && <p className="text-center text-success">{message}</p>}
    </Container>
  );
}

export default StoreSettings;
