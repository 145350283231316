import React, { useContext, useState } from 'react';
import { Container, Row, Col, Card, Accordion, Button, Form , InputGroup} from 'react-bootstrap';
import './creatorprofie.css'; // Add your custom CSS for further styling
import axios from 'axios';
import { faBoxOpen, faCircleQuestion, faListCheck, faLocationDot, faLock, faUser, faMagnifyingGlass, faPenToSquare, faTrash, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CreatorContext } from '../CreatorContext';
import swal from 'sweetalert';


function CreatorProfile() {
    const [activeKey, setActiveKey] = useState(null); // For managing accordion state
    const [file, setFile] = useState(null);
    const { creator , setCreator } = useContext(CreatorContext);
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [message, setMessage] = useState('');

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleUpload = () => {
        if (file) {
            // Handle file upload functionality here
            console.log('Uploading file:', file.name);
        } else {
            console.log('No file selected');
        }
    };

    const initialFormState = {
        name: creator?.creatorName,
        email: creator?.email,
        subject: '',
        department: '',
        message: '',
        file: null
    };

    const [formState, setFormState] = useState(initialFormState);
    const [messages, setMessages] = useState('');

    const handleInputChange = (e) => {
        const { name, value, files } = e.target;
        setFormState(prevState => ({
            ...prevState,
            [name]: files ? files[0] : value
        }));
    };
    console.log("form state", formState)

    const handleSubmit = async (e) => {
        const token = localStorage.getItem('Btoken');
        e.preventDefault();
        const formData = new FormData();
        formData.append('name', formState.name);
        formData.append('email', formState.email);
        formData.append('subject', formState.subject);
        formData.append('product', formState.product);
        formData.append('department', formState.department);
        formData.append('message', formState.message);
        formData.append('file', formState.file);

        for (let [key, value] of formData.entries()) {
            console.log(`${key}: ${value}`);
        }
        console.log("form data", formData)

        try {
            const response = await axios.post('https://node.instasales.in/api/v1/help-support', formData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    "Content-Type": "application/json",
                }
            });
            console.log("response", response)
            if (response.status == 201) {
                setMessages('Help and support request submitted successfully');
                setFormState(initialFormState);
            }
        } catch (error) {
            console.log("error", error)
            if (error.response && error.response.data.message) {
                setMessages(error.response.data.message);
            } else {
                setMessages('An error occurred while submitting the request');
            }
        }
    };
    const handlePasswordUpdate = async () => {
        const token = localStorage.getItem('Btoken');

        try {
            const response = await axios.put('https://node.instasales.in/api/v1/password/update', {
                oldPassword,
                newPassword,
                confirmPassword: confirmNewPassword,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                },
                headers: {
                    'Authorization': `Bearer ${token}`
                }, // To include cookies
            });

            setMessage('Password updated successfully');
        } catch (error) {
            console.log("error", error)
            if (error.response && error.response.data.message) {
                setMessage(error.response.data.message);
            } else {
                setMessage('An error occurred while updating the password');
            }
        }
    };

    const handleUpdateBankDetails = async () => {
        const token = localStorage.getItem('Ctoken');
    
        try {
            const response = await axios.put('https://node.instasales.in/api/v1/creator/update-bank-details', {
                bankDetails: creator.bankDetails,
                upiId: creator.upiId
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
console.log("response",response)
    
            if (response.status === 200) {
                swal("Success!", "Bank details updated successfully", "success");
                setCreator(response.data.creator)
            }
        } catch (error) {
            console.error("Error updating bank details:", error);
            swal("Error!", "Failed to update bank details", "error");
        }
    };
    


    return (
        <Container fluid className="profile-page">
            {/* Profile Info */}
            <Row className="my-4">
                <Col md={12}>
                    <Card className="profile-card">
                        <Card.Body className="d-flex align-items-center">
                            <img
                                src="https://via.placeholder.com/100"
                                alt="Profile"
                                className="rounded-circle me-3"
                            />
                            <div>
                                <h5>{creator?.creatorName}</h5>
                                <p>{creator?.email}</p>
                                <p>{creator?.phone}</p>
                            </div>
                            <Button variant="light" className="ms-auto">
                                <i className="bi bi-pencil"></i>
                            </Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            {/* Accordion Sections */}
            <Accordion activeKey={activeKey} onSelect={(key) => setActiveKey(key)}>
                {/* Withdrawal Settings */}
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Withdrawal Settings</Accordion.Header>
                    <Accordion.Body>
                        <Form>
                            <Row>
                                <InputGroup as={Col} className="profile-input">
                                    <InputGroup.Text>Account Holder's Name</InputGroup.Text>
                                    <Form.Control
                                        type="text"
                                        value={creator?.bankDetails?.accountHolderName || ""}
                                        onChange={(e) => setCreator({ ...creator, bankDetails: { ...creator.bankDetails, accountHolderName: e.target.value } })}
                                        placeholder="Enter Account Holder Name"
                                    />
                                </InputGroup>
                            </Row>
                            <Row>
                                <InputGroup as={Col} className="profile-input">
                                    <InputGroup.Text>Bank Name</InputGroup.Text>
                                    <Form.Control
                                        type="text"
                                        value={creator?.bankDetails?.bankName || ""}
                                        onChange={(e) => setCreator({ ...creator, bankDetails: { ...creator.bankDetails, bankName: e.target.value } })}
                                        placeholder="Enter Bank Name"
                                    />
                                </InputGroup>
                            </Row>
                            <Row>
                                <InputGroup as={Col} className="profile-input">
                                    <InputGroup.Text>Account Number</InputGroup.Text>
                                    <Form.Control
                                        type="text"
                                        value={creator?.bankDetails?.accountNumber || ""}
                                        onChange={(e) => setCreator({ ...creator, bankDetails: { ...creator.bankDetails, accountNumber: e.target.value } })}
                                        placeholder="Enter Account Number"
                                    />
                                </InputGroup>
                            </Row>
                            <Row>
                                <InputGroup as={Col} className="profile-input">
                                    <InputGroup.Text>Confirm Account Number</InputGroup.Text>
                                    <Form.Control
                                        type="text"
                                        placeholder="Confirm Account Number"
                                    />
                                </InputGroup>
                            </Row>
                            <Row>
                                <InputGroup as={Col} className="profile-input">
                                    <InputGroup.Text>IFSC Code</InputGroup.Text>
                                    <Form.Control
                                        type="text"
                                        value={creator?.bankDetails?.ifscCode || ""}
                                        onChange={(e) => setCreator({ ...creator, bankDetails: { ...creator.bankDetails, ifscCode: e.target.value } })}
                                        placeholder="Enter IFSC Code"
                                    />
                                </InputGroup>
                            </Row>
                            <Row>
                                <InputGroup as={Col} className="profile-input">
                                    <InputGroup.Text>UPI ID</InputGroup.Text>
                                    <Form.Control
                                        type="text"
                                        value={creator?.upiId || ""}
                                        onChange={(e) => setCreator({ ...creator, upiId: e.target.value })}
                                        placeholder="Enter UPI ID"
                                    />
                                </InputGroup>
                            </Row>
                            <Button className="profile-btn" onClick={handleUpdateBankDetails}>Update</Button>
                        </Form>
                    </Accordion.Body>
                </Accordion.Item>

                {/* Security & Privacy */}
                <Accordion.Item eventKey="1">
                    <Accordion.Header>Security & Privacy</Accordion.Header>
                    <Accordion.Body>
                    <div className="profile-h">
                            <FontAwesomeIcon icon={faLock} />&nbsp;Password & Security
                        </div>
                        {message && <div className="alert alert-info">{message}</div>}
                        <Row className="profile-info-1">
                            <InputGroup as={Col} className="profile-input">
                                <InputGroup.Text>Old Password</InputGroup.Text>
                                <Form.Control
                                    type="password"
                                    value={oldPassword}
                                    onChange={(e) => setOldPassword(e.target.value)}
                                />
                            </InputGroup>
                            <InputGroup as={Col} className="profile-input">
                                <InputGroup.Text>New Password</InputGroup.Text>
                                <Form.Control
                                    type="password"
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                />
                            </InputGroup>
                            <InputGroup as={Col} className="profile-input">
                                <InputGroup.Text>Confirm New Password</InputGroup.Text>
                                <Form.Control
                                    type="password"
                                    value={confirmNewPassword}
                                    onChange={(e) => setConfirmNewPassword(e.target.value)}
                                />
                            </InputGroup>
                        </Row>
                        <Button className="profile-btn" onClick={handlePasswordUpdate}>Update</Button>
      
                    </Accordion.Body>
                </Accordion.Item>

                {/* Help & Support */}
                <Accordion.Item eventKey="2">
                    <Accordion.Header>Help & Support</Accordion.Header>
                    <Accordion.Body>
                        <div className="profile-h">
                            <FontAwesomeIcon icon={faCircleQuestion} />&nbsp;Help & Support
                        </div>
                        {messages && <div className="alert alert-info">{messages}</div>}
                        <Form className="profile-help-form" onSubmit={handleSubmit}>
                            <Row>
                                <InputGroup as={Col} className="profile-input">
                                    <InputGroup.Text>Name</InputGroup.Text>
                                    <Form.Control type="text" name="name" value={formState.name} onChange={handleInputChange} />
                                </InputGroup>
                                <InputGroup as={Col} className="profile-input">
                                    <InputGroup.Text>Email</InputGroup.Text>
                                    <Form.Control type="text" name="email" value={formState?.email} onChange={handleInputChange} />
                                </InputGroup>
                            </Row>
                            <Row>
                                <InputGroup as={Col} className="profile-input">
                                    <InputGroup.Text>Subject</InputGroup.Text>
                                    <Form.Control type="text" name="subject" value={formState.subject} onChange={handleInputChange} />
                                </InputGroup>
                               
                                <InputGroup as={Col} className="profile-input">
                                    <InputGroup.Text>Department</InputGroup.Text>
                                    <Form.Select name="department" value={formState.department} onChange={handleInputChange}>
                                        <option>---- Select a department ----</option>
                                        <option value="A">A</option>
                                        <option value="B">B</option>
                                        <option value="C">C</option>
                                    </Form.Select>
                                </InputGroup>
                            </Row>
                            <Row>
                                <Form.Control className="profile-input ta" as="textarea" rows={4} name="message" value={formState.message} onChange={handleInputChange} />
                            </Row>
                            <Row>
                                <InputGroup as={Col} className="profile-input">
                                    <InputGroup.Text>Screenshot</InputGroup.Text>
                                    <Form.Control type="file" name="file" onChange={handleInputChange} />
                                </InputGroup>
                            </Row>
                            <Button className="profile-help-btn" type="submit">Submit</Button>
                        </Form>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </Container>
    );
}

export default CreatorProfile;
