import React, { useEffect, useState } from 'react'
import './categories.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Col, Form, Row, Modal } from "react-bootstrap";
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import pic from "../../media/profile.jpeg"
import p1 from "../../media/p1.png"
import p2 from "../../media/p2.png"
import p3 from "../../media/p3.png"
import { useParams } from 'react-router-dom';
import axios from 'axios';
import ClipLoader from 'react-spinners/ClipLoader';
import RangeSlider from 'react-bootstrap-range-slider';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';

const AllSubSubCategory = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const { subcategoryName } = useParams();
    const { subsubcategory } = useParams();

    console.log("categoryName subSubCategory"  ,subcategoryName,subsubcategory  )
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(false); // State for loading indicator
    const [selectedFilters, setSelectedFilters] = useState({
        subCategory: '',
        sort: '',
        discount: '',
        brands: [],
        priceRange: [0, 800],
        discountRange: [10, 95]
    });

    useEffect(() => {
        setLoading(true);
        const fetchProducts = async () => {
            try {
                const response = await axios.get(`https://node.instasales.in/api/v1/products/subsubcategory/${subsubcategory}`);
                setProducts(response.data.products); // Assuming the API response contains a "products" field
                console.log("products",response)
            } catch (error) {
                console.error('Error fetching products:', error);
            }finally {
                setLoading(false); // Set loading to false after API call completes
            }
        };

        fetchProducts();
    }, [subcategoryName]);

    // Chunking function
    const chunkArray = (array, size) => {
        const chunkedArray = [];
        for (let i = 0; i < array?.length; i += size) {
            chunkedArray.push(array.slice(i, i + size));
        }
        return chunkedArray;
    };

    // Chunk products into arrays of 4
    const productChunks = chunkArray(products, 4);

    console.log("products is all",products)

    const handleHoverDivClick = (link) => {
        window.location.href = link;
      };
  
      const applyFilters = () => {
        let filtered = [...products];

        // Apply price range filter
        const [minPrice, maxPrice] = selectedFilters.priceRange;
        filtered = filtered.filter(product => {
            const price = product.variations[0]?.options[0].selling_price;
            return price >= minPrice && price <= maxPrice;
        });
         // // Apply discount range filter
        // const [minDiscount, maxDiscount] = selectedFilters.discountRange;
        // filtered = filtered.filter(product => {
        //     const discount = product.variations[0]?.options[0].discount_percentage;
        //     return discount >= minDiscount && discount <= maxDiscount;
        // });

        // Filter by brands
        if (selectedFilters.brands.length > 0) {
            filtered = filtered.filter(product => selectedFilters.brands.includes(product.brand_name));
        }

        // Apply sorting
        if (selectedFilters.sort) {
            if (selectedFilters.sort === '1') { // Trending
                filtered = filtered.sort((a, b) => b.popularity - a.popularity); // Assuming popularity is a field
            } else if (selectedFilters.sort === '2') { // Price: High to Low
                filtered = filtered.sort((a, b) => b.variations[0]?.options[0].selling_price - a.variations[0]?.options[0].selling_price);
            } else if (selectedFilters.sort === '3') { // Price: Low to High
                filtered = filtered.sort((a, b) => a.variations[0]?.options[0].selling_price - b.variations[0]?.options[0].selling_price);
            }
        }

        setProducts(filtered);
        handleClose();
    };

    const handleBrandFilterChange = (event) => {
        const brand = event.target.value;
        const isChecked = event.target.checked;
        setSelectedFilters(prevFilters => ({
            ...prevFilters,
            brands: isChecked ? [...prevFilters.brands, brand] : prevFilters.brands.filter(b => b !== brand)
        }));
    };
    const handlePriceRangeChange = (value) => {
        setSelectedFilters(prevFilters => ({
            ...prevFilters,
            priceRange: [0, value]
        }));
    };

    const handleDiscountRangeChange = (event) => {
        const value = event.target.value;
        setSelectedFilters(prevFilters => ({
            ...prevFilters,
            discountRange: [10, value]
        }));
    };
    const getUniqueBrands = () => {
        const allBrands = products.map(product => product.brand_name);
        return [...new Set(allBrands)];
    };

    return (
        <div className="all">
            <Row className="categories-top">
                <Col className="categories-h" lg={9}>{subcategoryName}</Col>
                <Col lg={2} className="categories-filter"><Button className="categories-top-btn" onClick={handleShow}><FontAwesomeIcon icon={faFilter} /> Filters</Button></Col>
                <Col className="categories-color" lg={1}></Col>
            </Row>
            <div className="all-items">
                <div className="all-items-h">{subsubcategory}</div>
                <div className="all-items-p">{products?.length} Search results</div>
                {loading ? (
                <div className="wishlist-loading">
                    <ClipLoader size={50} color={"#123abc"} loading={loading} />
                </div>
            ) : (
                <>
                {productChunks.map((chunk, chunkIndex) => (
                    <div key={chunkIndex}>
                        <Row className="all-items-row">
                            {chunk.map((product, productIndex) => (
                                
                                <Col key={productIndex} as={Card} className="categories-products-card" onClick={()=> handleHoverDivClick(`/product/${product.brand_slug}/${product._id}`)}>
                                    {/* <p className="categories-products-banner">
                                        <span>
                                            <img src={pic} alt="promoting-creator" className="categories-products-banner-img overlap-1" />
                                            <img src={pic} alt="promoting-creator" className="categories-products-banner-img overlap-2" />
                                            <img src={pic} alt="promoting-creator" className="categories-products-banner-img overlap-3" />
                                        </span>
                                        30+ Creators are promoting
                                    </p> */}
                                    <img src={product?.images[0]?.url || p1} alt="card-img" className="categories-products-card-img" />
                                    <div className="categories-products-price">₹{product?.variations[0]?.options[0]?.selling_price}</div>
                                    <div className="categories-products-title">{product.name}</div>
                                    <div className="categories-products-desc">{product.description}</div>
                                </Col>
                            ))}
                        </Row>
                    </div>
                ))}
                </>)}
            </div>
              <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} size="lg" className="categories-modal">
                <Modal.Header className="categories-modal-header" closeButton>
                    <Modal.Title className="categories-modal-title"><FontAwesomeIcon icon={faFilter} onClick={handleShow} /> Filters</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="categories-modal-row">
                        <Col className="categories-modal-col">
                            <Form.Select className="categories-modal-select" disabled>
                                <option>{subcategoryName}</option>
                            </Form.Select>
                        </Col>
                        <Col className="categories-modal-col">
                            <Form.Select className="categories-modal-select" disabled>
                                <option>{subsubcategory}</option>
                            </Form.Select>
                        </Col>
                    </Row>
                    <Row className="categories-modal-row">
                        <Col className="categories-modal-col">
                            <Form.Select className="categories-modal-select" value={selectedFilters.sort} onChange={(e) => setSelectedFilters({ ...selectedFilters, sort: e.target.value })}>
                                <option value="">Sort</option>
                                <option value="1">Trending (Most Popular)</option>
                                <option value="2">Price: High to Low</option>
                                <option value="3">Price: Low to High</option>
                            </Form.Select>
                        </Col>
                        <Col className="categories-modal-col">
                            <Form.Select className="categories-modal-select" value={selectedFilters.discount} onChange={(e) => setSelectedFilters({ ...selectedFilters, discount: e.target.value })}>
                                <option value="">Discount</option>
                                <option value="1">Current Sale</option>
                                <option value="2">Over 50% off</option>
                                <option value="3">Over 20% off</option>
                            </Form.Select>
                        </Col>
                    </Row>
                    <Row className="categories-modal-check">
                        <Col className="categories-modal-check-col">
                            <p className="categories-modal-check-h"><span className="categories-modal-check-txt">Brands</span><Button className="categories-modal-check-btn">View All</Button></p>
                            <div key={`inline-checkbox`} className="mb-3">
                                {getUniqueBrands().map((brand, index) => (
                                    <Form.Check
                                        key={index}
                                        inline
                                        label={brand}
                                        value={brand}
                                        onChange={handleBrandFilterChange}
                                        checked={selectedFilters.brands.includes(brand)}
                                    />
                                ))}

                            </div>
                        </Col>
                    </Row>
                    <Row className="categories-modal-row">
                    <Col className="categories-modal-col-range">
                            <Form.Label>Price (₹)</Form.Label>
                            <RangeSlider
                                value={selectedFilters.priceRange[1]}
                                onChange={changeEvent => handlePriceRangeChange(changeEvent.target.value)}
                                min={0}
                                max={800}
                                tooltip="on"
                                tooltipPlacement="top"
                            />
                            <span className="categories-modal-range-start">₹{selectedFilters.priceRange[0]}</span>
                            <span className="categories-modal-range-end">₹{selectedFilters.priceRange[1]}</span>
                        </Col>
                        <Col className="categories-modal-col-range">
                            <Form.Label>Discount</Form.Label>
                            <Form.Range value={0} />
                            <span className="categories-modal-range-start">10%</span><span className="categories-modal-range-end">95%</span>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className="categories-modal-footer">
                    <Button className="categories-modal-btn" onClick={applyFilters}>
                        Apply
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default AllSubSubCategory
