import React, { useEffect, useState } from 'react';
import './notifications.css';
import { Container } from 'react-bootstrap';
import axios from 'axios';
import ClipLoader from 'react-spinners/ClipLoader'; // Importing ClipLoader

const Notifications = () => {
    const [notifications, setNotifications] = useState([]);
    const [loading, setLoading] = useState(true); // Adding loading state

    useEffect(() => {
        const token = localStorage.getItem('token');

        const fetchNotifications = async () => {
            setLoading(true); // Set loading to true before fetching data
            try {
                const response = await axios.get('https://node.instasales.in/api/v1/notifications', {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });
                setNotifications(response.data.notifications);
            } catch (error) {
                console.error('Error fetching notifications:', error);
            } finally {
                setLoading(false); // Set loading to false after data is fetched
            }
        };

        fetchNotifications();
    }, []);

    const handleMarkAsRead = async (notificationId) => {
        const token = localStorage.getItem('token');

        try {
            await axios.put(
                `https://node.instasales.in/api/v1/notifications/${notificationId}/read`,
                {},
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                }
            );

            setNotifications((prevNotifications) =>
                prevNotifications.map((notification) =>
                    notification._id === notificationId ? { ...notification, isRead: true } : notification
                )
            );
        } catch (error) {
            console.error('Error marking notification as read:', error);
        }
    };

    if (loading) {
        return (
            <div className="cart-loading">
                <ClipLoader size={50} color={"#123abc"} loading={loading} />
            </div>
        );
    }

    return (
        <Container className="notifications">
            <p className="notifications-h">Notifications</p>
            {notifications.map((notification) => (
                <div
                    key={notification._id}
                    className={`notification ${notification.isRead ? 'opened' : 'new'}`}
                    onClick={() => handleMarkAsRead(notification._id)}
                >
                    <div className="notification-h">{notification.heading}</div>
                    <div className="notification-p">{notification.description}</div>
                </div>
            ))}
        </Container>
    );
};

export default Notifications;
