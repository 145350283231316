import React, { useEffect, useState, useRef } from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import './categories.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Col, Row, Modal, Form } from "react-bootstrap";
import { faFilter, faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import p1 from "../../media/p1.png";
import ClipLoader from 'react-spinners/ClipLoader';

const AllSubcategory = () => {
    const { subcategoryName } = useParams();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [products, setProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [selectedFilters, setSelectedFilters] = useState({
        subCategory: '',
        sort: '',
        brands: []
    });
    const carouselRefs = useRef({});

    const responsive = {
        superLargeDesktop: { breakpoint: { max: 4000, min: 3000 }, items: 3 },
        desktop: { breakpoint: { max: 3000, min: 1024 }, items: 3 },
        tablet: { breakpoint: { max: 1024, min: 464 }, items: 2 },
        mobile: { breakpoint: { max: 464, min: 0 }, items: 1 }
    };

    const handleCarouselPrev = (key) => {
        carouselRefs.current[key].previous();
    };
    
    const handleCarouselNext = (key) => {
        carouselRefs.current[key].next();
    };
    const [loading, setLoading] = useState(false); // State for loading indicator


    useEffect(() => {
        setLoading(true);
        const fetchProducts = async () => {
            try {
                const response = await axios.get(`https://node.instasales.in/api/v1/products/subcategory/${subcategoryName}`);
                setProducts(response.data.products);
                setFilteredProducts(response.data.products);

            } catch (error) {
                console.error('Error fetching products:', error);
            }finally {
                setLoading(false); // Set loading to false after API call completes
            }
        };
        fetchProducts();
    }, [subcategoryName]);

    // Group products by sub-sub-category
    const groupedProducts = filteredProducts.reduce((acc, product) => {
        const subSubCategory = product.sub_sub_category || "Others";
        if (!acc[subSubCategory]) acc[subSubCategory] = [];
        acc[subSubCategory].push(product);
        return acc;
    }, {});

    const handleHoverDivClick = (link) => {
        window.location.href = link;
    };

    const getUniqueBrands = () => {
        const allBrands = products.map(product => product.brand_name);
        return [...new Set(allBrands)];
    };

    const handleBrandFilterChange = (event) => {
        const brand = event.target.value;
        const isChecked = event.target.checked;

        setSelectedFilters(prevFilters => ({
            ...prevFilters,
            brands: isChecked ? [...prevFilters.brands, brand] : prevFilters.brands.filter(b => b !== brand)
        }));
    };

    const handleSubCategoryChange = (event) => {
        setSelectedFilters(prevFilters => ({
            ...prevFilters,
            subCategory: event.target.value
        }));
    };

    const handleSortChange = (event) => {
        setSelectedFilters(prevFilters => ({
            ...prevFilters,
            sort: event.target.value
        }));
    };

    const applyFilters = () => {
        let filtered = [...products];

        // Filter by sub-category
        if (selectedFilters.subCategory) {
            filtered = filtered.filter(product => product.sub_category === selectedFilters.subCategory);
        }

        // Filter by brands
        if (selectedFilters.brands.length > 0) {
            filtered = filtered.filter(product => selectedFilters.brands.includes(product.brand_name));
        }

        // Sort products
        if (selectedFilters.sort) {
            if (selectedFilters.sort === '1') { // Trending
                filtered = filtered.sort((a, b) => b.popularity - a.popularity); // Assuming popularity is a field
            } else if (selectedFilters.sort === '2') { // Price: High to Low
                filtered = filtered.sort((a, b) => b.variations[0].options[0].selling_price - a.variations[0].options[0].selling_price);
            } else if (selectedFilters.sort === '3') { // Price: Low to High
                filtered = filtered.sort((a, b) => a.variations[0].options[0].selling_price - b.variations[0].options[0].selling_price);
            }
        }

        setFilteredProducts(filtered);
        handleClose();
    };

    const getUniqueSubCategories = () => {
        const allSubCategories = products.map(product => product.sub_sub_category);
        return [...new Set(allSubCategories)];
    };

    return (
        <div className="categories">
            <Row className="categories-top">
                <Col className="categories-h" lg={9}>{subcategoryName}</Col>
                <Col lg={2} className="categories-filter"><Button className="categories-top-btn" onClick={handleShow}><FontAwesomeIcon icon={faFilter} /> Filters</Button></Col>
                <Col className="categories-color" lg={1}></Col>
            </Row>
            {loading ? (
                <div className="wishlist-loading">
                    <ClipLoader size={50} color={"#123abc"} loading={loading} />
                </div>
            ) : (
                <>
                {Object.entries(groupedProducts).map(([subSubCategory, products], index) => (
                    <div className="categories-products" key={subSubCategory}>
                        <Row className={`categories-products-row bg-${index % 2 === 0 ? 'green' : index % 3 === 0 ? 'blue' : 'yellow'}`}>
                            <Col lg={4} className="categories-products-content">
                                <div className="categories-products-h">{subSubCategory}</div>
                                <div className="categories-products-p"></div>
                                <div className="categories-products-ctrl">
                                    <Button className="custom-left-arrow" onClick={() => handleCarouselPrev(subSubCategory)}>
                                        <FontAwesomeIcon icon={faCaretLeft} size='2x' />
                                    </Button>
                                    <Button className="custom-right-arrow" onClick={() => handleCarouselNext(subSubCategory)}>
                                        <FontAwesomeIcon icon={faCaretRight} size='2x' />
                                    </Button>
                                </div>
                                <Button onClick={() => handleHoverDivClick(`/Clothings/${subcategoryName}/${subSubCategory}`)} className="categories-products-btn">View All</Button>
                                                            {/* <Button onClick={() => handleHoverDivClick(`/subcategories-all/${subSubCategory}`)} className="categories-products-btn">View All</Button> */}

                            </Col>
                            <Col lg={8} className="categories-products-carousel-col">
                                <Carousel responsive={responsive} arrows={false} ref={(el) => carouselRefs.current[subSubCategory] = el} className="categories-products-carousel">
                                    {products.map((product) => (
                                        <Card key={product._id} className="categories-products-card" onClick={() => handleHoverDivClick(`/product/${product.brand_slug}/${product._id}`)}>
                                            <img src={product?.images[0]?.url || p1} alt="card-img" className="categories-products-card-img" />
                                            <div className="categories-products-price">₹{product?.variations[0]?.options[0]?.selling_price}</div>
                                            <div className="categories-products-title">{product.name}</div>
                                            <div className="categories-products-desc">{product.description}</div>
                                        </Card>
                                    ))}
                                </Carousel>
                            </Col>
                        </Row>
                    </div>
                ))}
                </>)}
            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} size="lg" className="categories-modal">
                <Modal.Header className="categories-modal-header" closeButton>
                    <Modal.Title className="categories-modal-title"><FontAwesomeIcon icon={faFilter} /> Filters</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="categories-modal-row">
                    <Col className="categories-modal-col">
                            <Form.Select className="categories-modal-select" disabled>
                                <option>{subcategoryName}</option>
                            </Form.Select>
                        </Col>
                        <Col className="categories-modal-col">
                            <Form.Select className="categories-modal-select" value={selectedFilters.subCategory} onChange={handleSubCategoryChange}>
                                <option value="">All Subcategories</option>
                                {getUniqueSubCategories().map((subCategory, index) => (
                                    <option key={index} value={subCategory}>{subCategory}</option>
                                ))}
                            </Form.Select>
                        </Col>
                    </Row>
                    <Row className="categories-modal-row">
                    <Col className="categories-modal-col">
                            <Form.Select className="categories-modal-select" value={selectedFilters.sort} onChange={handleSortChange}>
                                <option value="">Sort</option>
                                <option value="1">Trending (Most Popular)</option>
                                <option value="2">Price: High to Low</option>
                                <option value="3">Price: Low to High</option>
                            </Form.Select>
                        </Col>
                        <Col className="categories-modal-col">
                            <Form.Select className="categories-modal-select">
                                <option>Discount</option>
                                <option value="1">Current Sale</option>
                                <option value="2">Over 50% off</option>
                                <option value="3">Over 20% off</option>
                            </Form.Select>
                        </Col>
                    </Row>
                    <Row className="categories-modal-check">
                        <Col className="categories-modal-check-col">
                            <p className="categories-modal-check-h"><span className="categories-modal-check-txt">Brands</span><Button className="categories-modal-check-btn">View All</Button></p>
                            <div key={`inline-checkbox`} className="mb-3">
                                {getUniqueBrands().map((brand, index) => (
                                    <Form.Check
                                        key={index}
                                        className='inline-checkbox'
                                        type="checkbox"
                                        label={brand}
                                        value={brand}
                                        onChange={handleBrandFilterChange}
                                        checked={selectedFilters.brands.includes(brand)}
                                    />
                                ))}
                            </div>
                        </Col>
                    </Row>
                    <Row className="categories-modal-check">
                        {/* <Col className="categories-modal-check-col">
                            <p className="categories-modal-check-h"><span className="categories-modal-check-txt">Skin Type</span><Button className="categories-modal-check-btn">View All</Button></p>
                            <div key={`inline-checkbox`} className="mb-3">
                                <Form.Check inline label="Dry" name="group1" id={`inline-checkbox-1`} />
                                <Form.Check inline label="Oily" name="group1" id={`inline-checkbox-2`} />
                                <Form.Check inline label="Normal" name="group1" id={`inline-checkbox-3`} />
                                <Form.Check inline label="Sensitive" name="group1" id={`inline-checkbox-4`} />
                                <Form.Check inline label="Combination" name="group1" id={`inline-checkbox-5`} />
                            </div>
                        </Col> */}
                    </Row>
                </Modal.Body>
                <Modal.Footer className="categories-modal-footer">
                    <Button className="categories-modal-footer-btn" onClick={applyFilters}>Apply Filters</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default AllSubcategory;
