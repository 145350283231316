import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import axios from 'axios';
import './branddashboard.css'; // You can create a CSS file for custom styling

function BrandDashboard() {
    const [dashboardData, setDashboardData] = useState({
        totalRevenue: '--',
        expectedEarnings: '--',
        storeViews: '--',
        subscribersCount: '--',
        productsSoldCount: '--',
        creatorsPromotingCount: '--',
    });

    useEffect(() => {
        const fetchDashboardData = async () => {
            try {
                const token = localStorage.getItem('Btoken'); // Assuming token is stored here
                const response = await axios.get('https://node.instasales.in/api/v1/brand/dashboard', {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                console.log("Response",response)
                if (response.data.success) {

                    setDashboardData(response.data.data);
                }
            } catch (error) {
                console.error("Error fetching dashboard data", error);
            }
        };

        fetchDashboardData();
    }, []);

    return (
        <Container fluid className="dashboard">
            <Row className="mb-4">
                <Col md={12}>
                    <Card className="p-4 text-center">
                        <Card.Body>
                            <h4>Let's Add Your Product</h4>
                            <Button variant="dark" href='/brand/new-product'>Add Now</Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Row className="mb-4">
                <Col md={4}>
                    <Card className="text-center p-3">
                        <Card.Body>
                            <h6>Total Revenue</h6>
                            <p>₹ {dashboardData.totalRevenue}</p>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card className="text-center p-3">
                        <Card.Body>
                            <h6>Expected Earning</h6>
                            <p>₹ {dashboardData.expectedEarnings}</p>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card className="text-center p-3">
                        <Card.Body>
                            <h6>Store Views</h6>
                            <p>{dashboardData.storeViews}</p>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Row className="mb-4">
                <Col md={4}>
                    <Card className="text-center p-3">
                        <Card.Body>
                            <h6>No. of Store Subscribers</h6>
                            <p>{dashboardData.subscribersCount}</p>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card className="text-center p-3">
                        <Card.Body>
                            <h6>Total Products Sold</h6>
                            <p>{dashboardData.productsSoldCount}</p>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card className="text-center p-3">
                        <Card.Body>
                            <h6>Creators Promoting Product</h6>
                            <p>{dashboardData.creatorsPromotingCount}</p>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Row>
                <Col md={12} className="text-center">
                    <h5>Add a New Product To Your Store</h5>
                    <Button variant="dark" onClick={() => window.location.href='/brand/manage-products'}>
                        Manage Store
                    </Button>
                </Col>
            </Row>
        </Container>
    );
}

export default BrandDashboard;
