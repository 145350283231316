import React, { useState } from 'react';
import { Form, Button, Col, Row } from 'react-bootstrap';
import axios from 'axios';

const RegisterCreator = () => {
    const [formData, setFormData] = useState({
        creatorName: '',
        email: '',
        phone: '',
        password: "12345678",
        dob: '25/02/2001',
        creatorBio: '',
        twitter: '',
        instagram: '',
        facebook: '',
        avatar: null,
        gender: '',
        creatorType: '',
        storeLink: '',
        followers: [],
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleFileChange = (e) => {
        setFormData({ ...formData, avatar: e.target.files[0] });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const form = new FormData();
        for (let key in formData) {
            if (key === 'avatar') {
                form.append(key, formData[key], formData[key].name);
            } else {
                form.append(key, formData[key]);
            }
        }

        try {
            const response = await axios.post('https://node.instasales.in/api/v1/creator/register', form, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log(response.data);
            alert('Creator registered successfully!');
        } catch (error) {
            console.error(error);
            alert('An error occurred while registering the creator.');
        }
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Row>
                <Col md={6}>
                    <Form.Group controlId="creatorName">
                        <Form.Label>Creator Name</Form.Label>
                        <Form.Control
                            type="text"
                            name="creatorName"
                            value={formData.creatorName}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group controlId="email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <Form.Group controlId="phone">
                        <Form.Label>Phone</Form.Label>
                        <Form.Control
                            type="text"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group controlId="password">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                            type="password"
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <Form.Group controlId="dob">
                        <Form.Label>Date of Birth</Form.Label>
                        <Form.Control
                            type="date"
                            name="dob"
                            value={formData.dob}
                            onChange={handleChange}
                        />
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group controlId="gender">
                        <Form.Label>Gender</Form.Label>
                        <Form.Control
                            type="text"
                            name="gender"
                            value={formData.gender}
                            onChange={handleChange}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Form.Group controlId="creatorType">
                <Form.Label>Creator Type</Form.Label>
                <Form.Control
                    type="text"
                    name="creatorType"
                    value={formData.creatorType}
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.Group controlId="storeLink">
                <Form.Label>Store Link</Form.Label>
                <Form.Control
                    type="text"
                    name="storeLink"
                    value={formData.storeLink}
                    onChange={handleChange}
                    required
                />
            </Form.Group>
            <Form.Group controlId="creatorBio">
                <Form.Label>Bio</Form.Label>
                <Form.Control
                    as="textarea"
                    rows={3}
                    name="creatorBio"
                    value={formData.creatorBio}
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.Group controlId="avatar">
                <Form.Label>Avatar</Form.Label>
                <Form.Control
                    type="file"
                    name="avatar"
                    onChange={handleFileChange}
                    required
                />
            </Form.Group>
            <Row>
                <Col md={4}>
                    <Form.Group controlId="twitter">
                        <Form.Label>Twitter</Form.Label>
                        <Form.Control
                            type="text"
                            name="twitter"
                            value={formData.twitter}
                            onChange={handleChange}
                        />
                    </Form.Group>
                </Col>
                <Col md={4}>
                    <Form.Group controlId="instagram">
                        <Form.Label>Instagram</Form.Label>
                        <Form.Control
                            type="text"
                            name="instagram"
                            value={formData.instagram}
                            onChange={handleChange}
                        />
                    </Form.Group>
                </Col>
                <Col md={4}>
                    <Form.Group controlId="facebook">
                        <Form.Label>Facebook</Form.Label>
                        <Form.Control
                            type="text"
                            name="facebook"
                            value={formData.facebook}
                            onChange={handleChange}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Button variant="primary" type="submit">
                Register
            </Button>
        </Form>
    );
};

export default RegisterCreator;
