import React, { useState } from 'react';
import { Form, Button, Row, Col, InputGroup } from 'react-bootstrap';
import axios from 'axios';
import './newproduct.css'; // Import the CSS file

const CreateProductForm = () => {
  const [productData, setProductData] = useState({
    name: '',
    brand_name: '',
    category: '',
    sub_category: '',
    sub_sub_category: '',
    listing_date: Date.now(),
    images: [],
    size_chart: '',
    variations: [
      {
        v_name: '',
        options: [
          {
            name: '',
            listing_price: '',
            selling_price: '',
            stock: '',
            images: [],
            color_variations: [],
            quantity_variations: []
          }
        ]
      }
    ],
    customization: {
      text: { enabled: false, message: [] },
      image: { enabled: false, message: [] }
    },
    inventory_management: false,
    isPromotionAllow: true,
    listing_price: '',
    selling_price: '',
    stock: '',
    description: '',
    slug: '',
    promoted_by: []
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProductData({
      ...productData,
      [name]: value
    });
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setProductData({
      ...productData,
      [name]: checked
    });
  };

  const handleCustomizationChange = (e) => {
    const { name, checked } = e.target;
    setProductData({
      ...productData,
      customization: {
        ...productData.customization,
        [name]: { ...productData.customization[name], enabled: checked }
      }
    });
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setProductData({
      ...productData,
      images: files
    });
  };

  const handleVariationNameChange = (index, e) => {
    const { value } = e.target;
    const updatedVariations = [...productData.variations];
    updatedVariations[index].v_name = value;
    setProductData({
      ...productData,
      variations: updatedVariations
    });
  };

  const handleVariationOptionChange = (index, optionIndex, e) => {
    const { name, value } = e.target;
    const updatedVariations = [...productData.variations];

    if (name === 'color_variations' || name === 'quantity_variations') {
      updatedVariations[index].options[optionIndex][name] = value.split(',').map(item => item.trim());
    } else {
      updatedVariations[index].options[optionIndex][name] = value;
    }

    setProductData({
      ...productData,
      variations: updatedVariations
    });
  };

  const handleAddVariation = () => {
    setProductData({
      ...productData,
      variations: [
        ...productData.variations,
        {
          v_name: '',
          options: [
            {
              name: '',
              listing_price: '',
              selling_price: '',
              stock: '',
              images: [],
              color_variations: [],
              quantity_variations: []
            }
          ]
        }
      ]
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const formData = new FormData();
  
    // Append product data to formData (excluding images and variations initially)
    Object.keys(productData).forEach((key) => {
      if (key !== 'images' && key !== 'variations' && key !== 'customization') {
        formData.append(key, productData[key]);
      }
    });
  
    // Append images to formData
    productData.images.forEach((file) => {
      formData.append('images', file);
    });
  
    // Append variations as a JSON string
    formData.append('variations', JSON.stringify(productData.variations));
  
    // Append customization as a JSON string
    formData.append('customization', JSON.stringify(productData.customization));
  
    try {
      const token = localStorage.getItem('Btoken');
      const response = await axios.post('https://node.instasales.in/api/v1/product/new', formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        }
      });
      console.log('Product Created: ', response.data);
    } catch (error) {
      console.error('Error creating product: ', error.response?.data || error.message);
    }
  };
  

  return (
    <Form className="create-product-form" onSubmit={handleSubmit}>
      <h4>Create New Product</h4>
      <Form.Group controlId="productName">
        <Form.Label className="form-label">Product Name</Form.Label>
        <Form.Control
          type="text"
          name="name"
          value={productData.name}
          onChange={handleInputChange}
          required
        />
      </Form.Group>

      <Form.Group controlId="brandName">
        <Form.Label className="form-label">Brand Name</Form.Label>
        <Form.Control
          type="text"
          name="brand_name"
          value={productData.brand_name}
          onChange={handleInputChange}
          required
        />
      </Form.Group>

      <Form.Group controlId="category">
        <Form.Label className="form-label">Category</Form.Label>
        <Form.Control
          type="text"
          name="category"
          value={productData.category}
          onChange={handleInputChange}
          required
        />
      </Form.Group>

      <Form.Group controlId="subCategory">
        <Form.Label className="form-label">Sub-Category</Form.Label>
        <Form.Control
          type="text"
          name="sub_category"
          value={productData.sub_category}
          onChange={handleInputChange}
          required
        />
      </Form.Group>

      <Form.Group controlId="subSubCategory">
        <Form.Label className="form-label">Sub-Sub-Category</Form.Label>
        <Form.Control
          type="text"
          name="sub_sub_category"
          value={productData.sub_sub_category}
          onChange={handleInputChange}
        />
      </Form.Group>

      <Form.Group controlId="listingDate">
        <Form.Label className="form-label">Listing Date</Form.Label>
        <Form.Control
          type="date"
          name="listing_date"
          value={new Date(productData.listing_date).toISOString().split('T')[0]}
          onChange={handleInputChange}
          required
        />
      </Form.Group>

      <Form.Group controlId="images">
        <Form.Label className="form-label">Images</Form.Label>
        <Form.Control type="file" name="images" multiple onChange={handleFileChange} />
      </Form.Group>

      <h4>Product Variants</h4>
      {productData.variations.map((variation, index) => (
        <div className="variation-section" key={index}>
          <h5>Variant {index + 1}</h5>
          <Row>
            <Col md={6}>
              <Form.Group controlId={`variationName-${index}`}>
                <Form.Label className="form-label">Variation Name</Form.Label>
                <Form.Control
                  type="text"
                  name="v_name"
                  value={variation.v_name}
                  onChange={(e) => handleVariationNameChange(index, e)}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId={`optionName-${index}`}>
                <Form.Label className="form-label">Option Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={variation.options[0].name}
                  onChange={(e) => handleVariationOptionChange(index, 0, e)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={4}>
              <Form.Group controlId={`listingPrice-${index}`}>
                <Form.Label className="form-label">Listing Price</Form.Label>
                <InputGroup>
                  <InputGroup.Text>$</InputGroup.Text>
                  <Form.Control
                    type="number"
                    name="listing_price"
                    value={variation.options[0].listing_price}
                    onChange={(e) => handleVariationOptionChange(index, 0, e)}
                  />
                </InputGroup>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group controlId={`sellingPrice-${index}`}>
                <Form.Label className="form-label">Selling Price</Form.Label>
                <InputGroup>
                  <InputGroup.Text>$</InputGroup.Text>
                  <Form.Control
                    type="number"
                    name="selling_price"
                    value={variation.options[0].selling_price}
                    onChange={(e) => handleVariationOptionChange(index, 0, e)}
                  />
                </InputGroup>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group controlId={`stock-${index}`}>
                <Form.Label className="form-label">Stock</Form.Label>
                <Form.Control
                  type="number"
                  name="stock"
                  value={variation.options[0].stock}
                  onChange={(e) => handleVariationOptionChange(index, 0, e)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group controlId={`colorVariations-${index}`}>
                <Form.Label className="form-label">Color Variations</Form.Label>
                <Form.Control
                  type="text"
                  name="color_variations"
                  placeholder="Enter color options (e.g., Red, Blue, Green)"
                  value={variation.options[0].color_variations.join(', ')}
                  onChange={(e) => handleVariationOptionChange(index, 0, e)}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId={`quantityVariations-${index}`}>
                <Form.Label className="form-label">Quantity Variations</Form.Label>
                <Form.Control
                  type="text"
                  name="quantity_variations"
                  placeholder="Enter quantity options (e.g., 1kg, 500g)"
                  value={variation.options[0].quantity_variations.join(', ')}
                  onChange={(e) => handleVariationOptionChange(index, 0, e)}
                />
              </Form.Group>
            </Col>
          </Row>
        </div>
      ))}

      <Button variant="primary" className="btn-add-variation" onClick={handleAddVariation}>
        Add Another Variation
      </Button>

      <h4>Customization Options</h4>
      <Form.Group controlId="customizationText">
        <Form.Check
          type="checkbox"
          label="Enable Text Customization"
          name="text"
          checked={productData.customization.text.enabled}
          onChange={handleCustomizationChange}
        />
      </Form.Group>

      <Form.Group controlId="customizationImage">
        <Form.Check
          type="checkbox"
          label="Enable Image Customization"
          name="image"
          checked={productData.customization.image.enabled}
          onChange={handleCustomizationChange}
        />
      </Form.Group>

      <hr />

      <Form.Group controlId="inventoryManagement">
        <Form.Check
          type="checkbox"
          label="Enable Inventory Management"
          name="inventory_management"
          checked={productData.inventory_management}
          onChange={handleCheckboxChange}
        />
      </Form.Group>

      <Form.Group controlId="isPromotionAllowed">
        <Form.Check
          type="checkbox"
          label="Allow Promotion"
          name="isPromotionAllow"
          checked={productData.isPromotionAllow}
          onChange={handleCheckboxChange}
        />
      </Form.Group>

      <Form.Group controlId="description">
        <Form.Label className="form-label">Description</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          name="description"
          value={productData.description}
          onChange={handleInputChange}
        />
      </Form.Group>

      <Button variant="success" type="submit">
        Create Product
      </Button>
    </Form>
  );
};

export default CreateProductForm;
