import React, { useState } from 'react';
import { Form, Button, Container } from 'react-bootstrap';
import axios from 'axios';

const AddBrand = () => {
    const [formData, setFormData] = useState({
        brandName: '',
        email: '',
        phone: '',
        password: "password123",
        brandLogo: null,
        facebook: '',
        twitter: '',
        instagram: '',
        linkedin: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleFileChange = (e) => {
        setFormData({ ...formData, brandLogo: e.target.files[0] });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const form = new FormData();
        form.append('brandName', formData.brandName);
        form.append('email', formData.email);
        form.append('phone', formData.phone);
        form.append('password', formData.password);
        form.append('brandLogo', formData.brandLogo);
        form.append('facebook', formData.facebook);
        form.append('twitter', formData.twitter);
        form.append('instagram', formData.instagram);
        form.append('linkedin', formData.linkedin);

        try {
            const response = await axios.post('https://node.instasales.in/api/v1/brand/register', form, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log(response.data);
            alert('Brand registered successfully!');
            // Clear the form after successful submission
            setFormData({
                brandName: '',
                email: '',
                phone: '',
                password: 'password123',
                dob: '',
                brandLogo: null,
                facebook: '',
                twitter: '',
                instagram: '',
            });

            // Clear the file input
            document.getElementById('formBrandLogo').value = '';
        } catch (error) {
            console.error(error);
            alert('An error occurred while registering the brand.');
        }
    };

    return (
        <Container>
            <h2>Register Brand</h2>
            <Form onSubmit={handleSubmit}>
                {/* Existing fields */}
                <Form.Group controlId="formBrandName">
                    <Form.Label>Brand Name</Form.Label>
                    <Form.Control
                        type="text"
                        name="brandName"
                        value={formData.brandName}
                        onChange={handleChange}
                        required
                        maxLength="50"
                    />
                </Form.Group>
                <Form.Group controlId="formEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                </Form.Group>
                <Form.Group controlId="formPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                        type="password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        required
                        minLength="8"
                    />
                </Form.Group>
                <Form.Group controlId="formPhone">
                    <Form.Label>Phone</Form.Label>
                    <Form.Control
                        type="text"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        required
                    />
                </Form.Group>
               

                <Form.Group controlId="formBrandLogo">
                    <Form.Label>Brand Logo</Form.Label>
                    <Form.Control
                        type="file"
                        name="brandLogo"
                        onChange={handleFileChange}
                        required
                    />
                </Form.Group>

                {/* Social Media Links */}
                <Form.Group controlId="formInstagram">
                    <Form.Label>Instagram</Form.Label>
                    <Form.Control
                        type="text"
                        name="instagram"
                        value={formData.instagram}
                        onChange={handleChange}
                    />
                </Form.Group>
                
                <Form.Group controlId="formTwitter">
                    <Form.Label>Twitter</Form.Label>
                    <Form.Control
                        type="text"
                        name="twitter"
                        value={formData.twitter}
                        onChange={handleChange}
                    />
                </Form.Group>
                <Form.Group controlId="formFacebook">
                    <Form.Label>Facebook</Form.Label>
                    <Form.Control
                        type="text"
                        name="facebook"
                        value={formData.facebook}
                        onChange={handleChange}
                    />
                </Form.Group>


                <Button variant="primary" type="submit">
                    Register
                </Button>
            </Form>
        </Container>
    );
};

export default AddBrand;
