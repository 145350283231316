import React, { createContext, useState, useContext } from 'react';

const ProductContext = createContext();

export const ProductProvider = ({ children }) => {
  const [selectedProducts, setSelectedProducts] = useState([]);

  const addProduct = (product, quantity) => {
    setSelectedProducts((prevProducts) => {
      const productIndex = prevProducts.findIndex(p => p._id === product._id);
      if (productIndex !== -1) {
        const updatedProducts = [...prevProducts];
        updatedProducts[productIndex].quantity = quantity;
        return updatedProducts;
      } else {
        return [...prevProducts, { ...product, quantity }];
      }
    });
  };

  const removeProduct = (productId) => {
    setSelectedProducts((prevProducts) => prevProducts.filter(product => product._id !== productId));
  };

  const clearProducts = () => {
    setSelectedProducts([]);
  };

  return (
    <ProductContext.Provider value={{ selectedProducts, addProduct, removeProduct, clearProducts }}>
      {children}
    </ProductContext.Provider>
  );
};

export const useProductContext = () => useContext(ProductContext);
