import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import axios from 'axios';
import './manage.css'; // Updated file name for clarity
import { FaTimes, FaStar } from 'react-icons/fa';  // Import icons from React Icons

const ManageProducts = () => {
  const [products, setProducts] = useState([]);
  const [popularProducts, setPopularProducts] = useState([]);

  // Retrieve token from local storage
  const token = localStorage.getItem('Btoken');

  useEffect(() => {
    // Fetch products and popular products from the API
    const fetchProducts = async () => {
      try {
        const response = await axios.get('https://node.instasales.in/api/v1/brand/all-productss', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log("response", response);
        setProducts(response.data.products);
        setPopularProducts(response.data.popularProducts);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
  }, [token]); 

  // Handle deleting a product
  const handleDelete = async (id) => {
    try {
      setProducts(products.filter((product) => product._id !== id));
      
      await axios.delete(`https://node.instasales.in/api/v1/brand/brand/products/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      console.error('Error deleting product:', error);
    }
  };

  // Handle adding product to popular products
  const handleAddToPopular = async (productId) => {
    try {
      const response = await axios.post(
        'https://node.instasales.in/api/v1/brand/brand/popular-products',
        {
          productIds: [productId],
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.success) {
        const updatedProduct = products.find((product) => product._id === productId);
        setPopularProducts((prevPopular) => [...prevPopular, updatedProduct]);  // Add to popular products
        console.log('Product added to popular:', response);
      }
    } catch (error) {
      console.error('Error adding product to popular:', error);
    }
  };

  // Handle removing product from popular products
  const handleRemoveFromPopular = async (productId) => {
    try {
      const response = await axios.post(
        'https://node.instasales.in/api/v1/brand/remove-popular-product',
        { productId },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.data.success) {
        setPopularProducts((prevPopular) =>
          prevPopular.filter((product) => product._id !== productId)
        );
        console.log('Product removed from popular:', response);
      }
    } catch (error) {
      console.error('Error removing product from popular:', error);
    }
  };

  return (
    <Container>
      <h1 className="manage-products-title text-center my-4">Manage Products</h1>

      {/* Popular Products Section */}
      <h3 className="manage-products-section-title">Popular Products</h3>
      <Row className="mb-4">
        {popularProducts?.map((product) => (
          <Col key={product._id} xs={12} md={4} lg={3} className="mb-3">
            <Card className="manage-products-card">
              
              <Card.Img
                className="manage-products-card-img"
                variant="top"
                src={product.images[0].url}
              />
              <Card.Body>
                <Card.Title className="manage-products-card-title">
                  {product.name}
                </Card.Title>
                
                <Button
                  className="manage-products-btn-delete"
                  variant="outline-danger"
                  onClick={() => handleRemoveFromPopular(product._id)}
                >
                  Remove from popular
                </Button>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>

      {/* My Products Section */}
      <h3 className="manage-products-section-title">My Products</h3>
      <Row>
        {products
          .filter((product) => !popularProducts.some((popProduct) => popProduct._id === product._id))
          ?.map((product) => (
            <Col key={product._id} xs={12} md={4} lg={3} className="mb-3">
              <Card className="manage-products-card">
               
                <Card.Img
                  className="manage-products-card-img"
                  variant="top"
                  src={product.images[0].url}
                />
                <Card.Body>
                  <Card.Title className="manage-products-card-title">
                    {product.name}
                  </Card.Title>
                 
                  <Button
                    className="manage-products-btn-delete"
                    variant="success"
                    onClick={() => handleAddToPopular(product._id)}
                  >
                    Add to popular
                  </Button>
                  <Button
                    className="manage-products-btn-delete"
                    variant="outline-danger"
                    onClick={() => handleDelete(product._id)}
                  >
                    Delete
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          ))}
      </Row>
    </Container>
  );
};

export default ManageProducts;
