import React, { useContext, useEffect, useState } from 'react';
import { Container, Row, Col, Card, Button, Modal, Spinner } from 'react-bootstrap';
import axios from 'axios';
import './creatordashboard.css'; // Add your custom styles
import { CreatorContext } from '../CreatorContext'; // Assuming you have a context

function CreatorDashboard() {
  const [creatorData, setCreatorData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [promotableProducts, setPromotableProducts] = useState([]);
  const [showAllProducts, setShowAllProducts] = useState(false); // Manage the state for showing all products
  const [showPopup, setShowPopup] = useState(false); // State to control popup visibility
  const [loadingPromotable, setLoadingPromotable] = useState(true); // Loading state for promotable products
  const { creator, setCreator } = useContext(CreatorContext); // Assuming creator data is from context

  useEffect(() => {
    // Fetch creator data from the backend API
    const fetchCreatorData = async () => {
      try {
        const token = localStorage.getItem('Ctoken'); // Creator token from localStorage
        const response = await axios.get('https://node.instasales.in/api/v1/creator/dashboard', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setCreatorData(response.data);
        setLoading(false);
      } catch (err) {
        setError('Error fetching data');
        setLoading(false);
      }
    };

    const fetchPromotableProducts = async () => {
      try {
        const token = localStorage.getItem('Ctoken'); // Get the Creator token from localStorage
        const response = await axios.get('https://node.instasales.in/api/v1/creator/products/promotable', {
          headers: {
            Authorization: `Bearer ${token}`, // Attach the token to the Authorization header
          },
        });
        setPromotableProducts(response.data.products);
        setLoadingPromotable(false); // Stop showing the loader after data is fetched
      } catch (err) {
        console.error("Error fetching promotable products:", err);
        setLoadingPromotable(false); // Stop showing the loader if there's an error
      }
    };

    fetchCreatorData();
    fetchPromotableProducts();
  }, []);

  const handlePromote = async (productId) => {
    try {
      const token = localStorage.getItem('Ctoken');
      await axios.post(
        'https://node.instasales.in/api/v1/creator/products/promote',
        { productId },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Attach the token to the Authorization header
          },
        }
      );
      alert('Product promoted successfully!');
    } catch (err) {
      console.error("Error promoting product:", err);
      alert('Failed to promote product');
    }
  };

  const handleScheduleCall = async () => {
    try {
      const token = localStorage.getItem('Ctoken');
      const { creatorName, phone } = creator; // Use creator data from the context

      await axios.post(
        'https://node.instasales.in/api/v1/admin/callback-request', // Replace with actual API endpoint
        { name: creatorName, phone },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Show the success popup on success
      setShowPopup(true);
    } catch (err) {
      console.error("Error scheduling the call:", err);
      alert('Failed to schedule the call');
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  // Display only the first 4 products unless "View More" is clicked
  const displayedProducts = showAllProducts ? promotableProducts : promotableProducts.slice(0, 4);

  return (
    <Container className="creator-dashboard my-4">
      {/* Creator Dashboard Header */}
      <Row className="mb-4 text-center">
        <h4>Creator Dashboard</h4>
      </Row>

      {/* Statistics Cards */}
      <Row className="mb-4 text-center">
        <Col xs={12} sm={6} md={4} lg={2}>
          <Card className="stat-card">
            <Card.Body>
              <h5>{creatorData.totalRevenue || 0} ₹</h5>
              <p>Total Revenue</p>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} sm={6} md={4} lg={2}>
          <Card className="stat-card">
            <Card.Body>
              <h5>{creatorData.expectedEarnings || 0} ₹</h5>
              <p>Expected Earnings</p>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} sm={6} md={4} lg={2}>
          <Card className="stat-card">
            <Card.Body>
              <h5>{creatorData.storeViews || 0}</h5>
              <p>Store Views</p>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} sm={6} md={4} lg={2}>
          <Card className="stat-card">
            <Card.Body>
              <h5>{creatorData.productInteractions || 0}</h5>
              <p>Product Interactions</p>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} sm={6} md={4} lg={2}>
          <Card className="stat-card">
            <Card.Body>
              <h5>{creatorData.subscribers || 0}</h5>
              <p>No. of Store Subscribers</p>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} sm={6} md={4} lg={2}>
          <Card className="stat-card">
            <Card.Body>
              <h5>{creatorData.productsSold || 0}</h5>
              <p>Total Products Sold</p>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Add New Product and Manage Store */}
      <Row className="mb-4 text-center">
       
        <Col xs={12} md={6}>
          <Button variant="secondary" className="w-100 dashboard-btn" href='/creator/manage-products'>
            Manage Store
          </Button>
        </Col>
      </Row>

      {/* Promotable Products Section */}
      <Row className="mb-4">
        <Col md={12}>
          <h5>Products Available for Promotion</h5>
        </Col>
      </Row>
      <Row className="mb-4 text-center">
        {loadingPromotable ? (
          <Spinner animation="border" role="status" className="mx-auto">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        ) : displayedProducts.length > 0 ? (
          displayedProducts.map((product) => (
            <Col xs={12} sm={6} md={4} lg={3} key={product._id}>
              <Card className="product-card">
                <Card.Img variant="top" src={product.images[0].url} />
                <Card.Body>
                  <Card.Title>{product.name}</Card.Title>
                  <Card.Text>
                    <strike>{product.originalPrice}</strike> <strong>{product.selling_price}</strong>
                  </Card.Text>
                  <Button
                    variant="success"
                    className="mt-2"
                    onClick={() => handlePromote(product._id)}
                  >
                    Promote
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          ))
        ) : (
          <p>No promotable products available</p>
        )}
      </Row>

      {/* View More / View Less Products */}
      {promotableProducts.length > 4 && (
        <Row className="text-center mb-4">
          <Button
            variant="primary"
            className="w-50 mx-auto"
            onClick={() => setShowAllProducts(!showAllProducts)}
          >
            {showAllProducts ? 'View Less Products' : 'View More Products'}
          </Button>
        </Row>
      )}

      {/* Schedule a Call */}
      <Row className="my-4 text-center">
        <Col md={12}>
          <h5>Create Your Own Brand From Scratch</h5>
          <Button variant="dark" className="w-25 mx-auto" onClick={handleScheduleCall}>
            Schedule A Call
          </Button>
        </Col>
      </Row>

      {/* Popup Modal for Success */}
      <Modal show={showPopup} onHide={() => setShowPopup(false)} centered>
        <Modal.Body className="text-center">
          <h5>Your Call Has Been Scheduled</h5>
          <p>We will reach out within 24 Hours</p>
          <Button variant="primary" onClick={() => setShowPopup(false)}>
            Close
          </Button>
        </Modal.Body>
      </Modal>
    </Container>
  );
}

export default CreatorDashboard;
