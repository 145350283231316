import React from 'react'
import './info.css'
import ap1 from "../../media/ap1.png"
import ap2 from "../../media/ap2.png"
import { Col, Row, Accordion } from "react-bootstrap";

const About = () => {
    return (
        <div className="about">
            <Row className="us">
                <Col lg={3}>
                    <img src={ap1} className="us-img" />
                </Col>
                <Col lg={9}>
                    <div className="us-h">Who are we ?</div>
                    <div className="us-p">We are a platform that empowers Creators and Brands to create wishlists effortlessly, adding items they are interested in and making it available to you guys. Creators can easily revisit and edit their wishlists, making it a dynamic and adaptable tool.</div>
                </Col>
            </Row>
            <Row className="experience">
                <div className="experience-h">Elevating your shopping experience.</div>
                <div className="experience-p">In the realm of E-commerce, our platform is dedicated to elevating your shopping experience to new heights. We believe that shopping should be more than a transaction; it should be an immersive and personalized journey tailored to your preferences. Our commitment to excellence is reflected in every facet of our platform, from an intuitively designed interface that simplifies navigation to a curated selection of high-quality products that meet diverse needs and tastes. We prioritize user-centric features such as advanced search capabilities, personalized recommendations, and a seamless checkout process to ensure a smooth and enjoyable shopping experience.</div>
            </Row>
            <Row className="instasales">
                <Col lg={9}>
                    <div className="instasales-h">Why InstaSales?</div>
                    <div className="instasales-p">With a focus on innovation, we continuously integrate the latest technologies to stay ahead of the curve, offering you cutting-edge features and a glimpse into the future of E-commerce. Join us on this transformative journey as we redefine and elevate the very essence of your online shopping escapades. Welcome to a world where your satisfaction is not just a goal; it's our unwavering commitment.</div>
                </Col>
                <Col lg={3}>
                    <img src={ap2} className="instasales-img" />
                </Col>
            </Row>
            <div className="faqs">
                <div className="faqs-h">FAQ’s</div>
                <Accordion defaultActiveKey="0" className="faqs-accord">
                    <Accordion.Item eventKey="0" className="faq">
                        <Accordion.Header>How can B Rich help creators secure brand deals?</Accordion.Header>
                        <Accordion.Body>
                            B Rich acts as your bridge to brands. We leverage our expertise and network to
                            connect you with brands that align with your style and values, ensuring you secure
                            meaningful collaborations.
                        </Accordion.Body>
                        <hr className="custom-hr-1"></hr>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1" className="faq">
                        <Accordion.Header>What sets B Rich apart in the realm of strategic partnerships?</Accordion.Header>
                        <Accordion.Body>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                            minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                            aliquip ex ea commodo consequat. Duis aute irure dolor in
                            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                            culpa qui officia deserunt mollit anim id est laborum.
                        </Accordion.Body>
                        <hr className="custom-hr-1"></hr>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2" className="faq">
                        <Accordion.Header>Can B Rich assist with monetizing my creative efforts?</Accordion.Header>
                        <Accordion.Body>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                            minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                            aliquip ex ea commodo consequat. Duis aute irure dolor in
                            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                            culpa qui officia deserunt mollit anim id est laborum.
                        </Accordion.Body>
                        <hr className="custom-hr-1"></hr>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3" className="faq">
                        <Accordion.Header>How does B Rich amplify my influence?</Accordion.Header>
                        <Accordion.Body>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                            minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                            aliquip ex ea commodo consequat. Duis aute irure dolor in
                            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                            culpa qui officia deserunt mollit anim id est laborum.
                        </Accordion.Body>
                        <hr className="custom-hr-1"></hr>
                    </Accordion.Item>
                </Accordion>
            </div>
        </div>
    )
}

export default About
