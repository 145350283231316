import React, { useState, useEffect, useContext } from 'react';
import { Container, Row, Col, Card, Button, Form, InputGroup } from 'react-bootstrap';
import axios from 'axios';
import './cs.css'; // Custom CSS for styling
import { CreatorContext } from '../CreatorContext';

function CreatorStoreSettings() {
  const { creator } = useContext(CreatorContext);

  const [basicDetails, setBasicDetails] = useState({
    name: '',
    influencerType: '',
    allowMessage: false,
    followers: '',
    verificationLink: '',
  });
  
  const [socialLinks, setSocialLinks] = useState({
    instagram: '',
    twitter: '',
    facebook: '',
    youtube: '',
    snapchat: '',
    linkedin: '',
  });
console.log("socialLinks",socialLinks)
  const [storeTheme, setStoreTheme] = useState('');
  const themes = ['Light Green', 'Cool Blue', 'Dark Grey', 'Soft Pink', 'Light Aqua']; // Available themes

  // Fetch existing data
  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem('Ctoken');
      try {
        const response = await axios.get('https://node.instasales.in/api/v1/creator/store-settings', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const { basicDetails, socialLinks, storeTheme } = response.data;
        setBasicDetails(basicDetails);
        setSocialLinks(socialLinks || {});
        setStoreTheme(storeTheme);
      } catch (error) {
        console.error("Error fetching store settings", error);
      }
    };

    fetchData();
  }, []);


  // Update Basic Details
  const handleBasicDetailsUpdate = async () => {
    const token = localStorage.getItem('Ctoken');
    try {
      await axios.put('https://node.instasales.in/api/v1/creator/store-settings/basic', basicDetails, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      alert('Basic details updated successfully!');
    } catch (error) {
      console.error("Error updating basic details", error);
      alert('Failed to update basic details');
    }
  };

  // Update Social Links
  const handleSocialLinksUpdate = async () => {
    const token = localStorage.getItem('Ctoken');
    try {
      await axios.put('https://node.instasales.in/api/v1/creator/social-links', socialLinks, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      alert('Social links updated successfully!');
    } catch (error) {
      console.error("Error updating social links", error);
      alert('Failed to update social links');
    }
  };

  // Update Store Theme
  const handleStoreThemeChange = async () => {
    const token = localStorage.getItem('Ctoken');
    try {
      await axios.put('https://node.instasales.in/api/v1/creator/store-settings/theme', { theme: storeTheme }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      alert('Store theme updated successfully!');
    } catch (error) {
      console.error("Error updating store theme", error);
      alert('Failed to update store theme');
    }
  };

  return (
    <Container className="store-settings-page my-4">
      <Row>
        <Col md={12}>
          <h4>Store Settings</h4>
        </Col>
      </Row>

      <Row className="mt-4">
        {/* Left Section: Profile Image, Basic Details */}
        <Col md={6}>
          <Card className="p-3 mb-4">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <img src="https://via.placeholder.com/100" alt="Profile" className="img-fluid rounded-circle" />
              </div>
              <Button variant="link">Edit</Button>
            </div>
            <Form>
              <Form.Group className="my-3">
                <Form.Label>Your Name</Form.Label>
                <Form.Control
                  type="text"
                  value={basicDetails.name}
                  onChange={(e) => setBasicDetails({ ...basicDetails, name: e.target.value })}
                />
              </Form.Group>
              <Form.Group className="my-3">
                <Form.Label>Influencer Type</Form.Label>
                <Form.Control
                  type="text"
                  value={basicDetails.influencerType}
                  onChange={(e) => setBasicDetails({ ...basicDetails, influencerType: e.target.value })}
                />
              </Form.Group>
              <Form.Check
                type="switch"
                id="allow-messages"
                label="Allow Messages"
                checked={basicDetails.allowMessage}
                onChange={(e) => setBasicDetails({ ...basicDetails, allowMessage: e.target.checked })}
              />
              <InputGroup className="my-3">
                <Form.Control
                  type="text"
                  placeholder="Followers"
                  value={basicDetails.followers}
                  onChange={(e) => setBasicDetails({ ...basicDetails, followers: e.target.value })}
                />
                <Form.Control
                  type="text"
                  placeholder="Link for Verification"
                  value={basicDetails.verificationLink}
                  onChange={(e) => setBasicDetails({ ...basicDetails, verificationLink: e.target.value })}
                />
              </InputGroup>
              <Button variant="success" onClick={handleBasicDetailsUpdate}>Update</Button>
            </Form>
          </Card>
        </Col>

        {/* Right Section: Social Links */}
        <Col md={6}>
          <Card className="p-3 mb-4">
          <Form>
              <h5>Add Social Links</h5>
              {['Instagram', 'Twitter', 'Facebook', 'YouTube', 'Snapchat', 'LinkedIn'].map((platform, index) => (
                <Form.Group key={index} className="my-2">
                  <Form.Label>{platform}</Form.Label>
                  <Form.Control
                    type="text"
                    value={socialLinks[platform.toLowerCase()]}
                    onChange={(e) =>
                      setSocialLinks({ ...socialLinks, [platform.toLowerCase()]: e.target.value })
                    }
                  />
                </Form.Group>
              ))}
              <Button variant="success" onClick={handleSocialLinksUpdate}>Update</Button>
            </Form>
          </Card>
        </Col>
      </Row>

      {/* Store Theme */}
      <Row className="mb-4">
        <Col md={12}>
          <h5>Available Store Themes</h5>
          <div className="d-flex justify-content-between">
            {themes.map((theme, index) => (
              <Button
                key={index}
                variant={storeTheme === theme ? "primary" : "light"}
                onClick={() => setStoreTheme(theme)}
              >
                {theme}
              </Button>
            ))}
            <Button variant="success" onClick={handleStoreThemeChange}>Change</Button>
          </div>
        </Col>
      </Row>

      {/* Manage Products */}
      <Row className="mt-4">
        <Col md={12} className="text-center">
          <Button variant="primary" href='/creator/manage-products'>Manage Products</Button>
        </Col>
      </Row>
    </Container>
  );
}

export default CreatorStoreSettings;
