import React, { createContext, useState, useEffect } from 'react';

const CreatorContext = createContext();

const CreatorProvider = ({ children }) => {
    const [creator, setCreator] = useState(null);

    useEffect(() => {
        // Load user data from local storage
        const CreatorData = localStorage.getItem('creator');
        if (CreatorData) {
            setCreator(JSON.parse(CreatorData));
        }
    }, []);

    return (
        <CreatorContext.Provider value={{ creator, setCreator }}>
            {children}
        </CreatorContext.Provider>
    );
};

export { CreatorContext, CreatorProvider };
