import React, { useContext, useEffect, useRef, useState } from 'react'
import { Tab, Nav, InputGroup, Form, Row, Col, Button } from 'react-bootstrap'
import './address.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faHeart, faPlus } from '@fortawesome/free-solid-svg-icons'
import { useParams , useNavigate } from 'react-router-dom'
import axios from 'axios'
import { UserContext } from "../UserContext";
import swal from 'sweetalert';

const AddAddress = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phoneNumber: '',
        houseNo: '',
        street: '',
        landmark: '',
        town: '',
        city: '',
        state: '',
        postalCode: ''
    });


    const handleInputChangeaddress = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleAddAddress = async () => {
        const token = localStorage.getItem('token');

        try {
            const response = await axios.post('https://node.instasales.in/api/v1/address', formData, {
                            headers: {
              'Authorization': `Bearer ${token}`
            },
            });
            if (response.data.success) {
                setFormData({
                    name: '',
                    email: '',
                    phoneNumber: '',
                    houseNo: '',
                    street: '',
                    landmark: '',
                    town: '',
                    city: '',
                    state: '',
                    postalCode: ''
                });
                swal("Success!", "Address added successfully!", "success");
                navigate('/product-summary');
            }
        } catch (error) {
            console.error('Error adding address:', error);
            swal("Oops!", "Something went wrong while adding the address!", "error");
        }
    };

    return (
        <div className="profile add address-profile">
            <div className="profile-h"><FontAwesomeIcon icon={faPlus} />&nbsp;Add New Address</div>
            <Row className="profile-info add-address">
                <Col>
                    <InputGroup as={Col} className="profile-input  profile-input2">
                        <InputGroup.Text>Full Name</InputGroup.Text>
                        <Form.Control type="text" name="name" placeholder="Full Name" value={formData.name} onChange={handleInputChangeaddress} />
                    </InputGroup>
                    <InputGroup as={Col} className="profile-input profile-input2">
                        <InputGroup.Text>Email</InputGroup.Text>
                        <Form.Control type="mail" name="email" placeholder="Email" value={formData.email} onChange={handleInputChangeaddress} />
                    </InputGroup>
                    <InputGroup className="profile-input profile-input2">
                        <InputGroup.Text>+91</InputGroup.Text>
                        <Form.Control type="number" name="phoneNumber" placeholder="Continue with Mobile Number" value={formData.phoneNumber} onChange={handleInputChangeaddress} />
                    </InputGroup>
                </Col>
                <Col>
                    <InputGroup className="profile-input profile-input2">
                        <InputGroup.Text>Flat, House no. , Apartment</InputGroup.Text>
                        <Form.Control type="text" name="houseNo" placeholder="Flat, House no. , Apartment" value={formData.houseNo} onChange={handleInputChangeaddress} />
                    </InputGroup>
                    <InputGroup className="profile-input profile-input2">
                        <InputGroup.Text>Area, Street, Sector, Village</InputGroup.Text>
                        <Form.Control type="text" name="street" placeholder="Area, Street, Sector, Village" value={formData.street} onChange={handleInputChangeaddress} />
                    </InputGroup>
                    <InputGroup className="profile-input profile-input2">
                        <InputGroup.Text>Landmark</InputGroup.Text>
                        <Form.Control type="text" name="landmark" placeholder="Landmark" value={formData.landmark} onChange={handleInputChangeaddress} />
                    </InputGroup>
                </Col>
                <Col>
                    <InputGroup className="profile-input profile-input2">
                        <InputGroup.Text>Pincode</InputGroup.Text>
                        <Form.Control type="text" name="postalCode" placeholder="Pincode" value={formData.postalCode} onChange={handleInputChangeaddress} />
                    </InputGroup>
                    <InputGroup className="profile-input profile-input2">
                        <InputGroup.Text>Town / City</InputGroup.Text>
                        <Form.Control type="text" name="city" placeholder="Town / City" value={formData.city} onChange={handleInputChangeaddress} />
                    </InputGroup>
                    <InputGroup className="profile-input profile-input2">
                        <Form.Select name="state" value={formData.state} onChange={handleInputChangeaddress}>
                            <option>Choose a State</option>
                            <option value={'Haryana'}>Haryana</option>
                            {/* Add state options here */}
                        </Form.Select>
                    </InputGroup>
                </Col>
            </Row>
            <Button className="profile-btn" onClick={handleAddAddress}>Add Address</Button>


        </div>
    )
}

export default AddAddress
