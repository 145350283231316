import React, { useEffect, useState } from 'react'
import './creator.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Col, Form, Row, Modal } from "react-bootstrap";
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import pic from "../../media/profile.jpeg"
import p1 from "../../media/p1.png"
import p2 from "../../media/p2.png"
import p3 from "../../media/p3.png"
import { useParams } from 'react-router-dom';
import axios from 'axios';
import ClipLoader from 'react-spinners/ClipLoader';


const CreatorSeectedCategory = ({ subCategory, setSelectedCubcategory, creator }) => {
    console.log("creator is in ",creator,subCategory)
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    console.log("categoryName subSubCategory", subCategory)
    const [products, setProducts] = useState([]);
    const [productsLoading, setProductsLoading] = useState(true);

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await axios.get(`https://node.instasales.in/api/v1/creator/productsub/${creator?._id}/${subCategory}`);
                setProducts(response.data.products); // Assuming the API response contains a "products" field
                console.log("products", response)
                setProductsLoading(false);
            } catch (error) {
                console.error('Error fetching products:', error);
                setProductsLoading(false);
            }
        };

        fetchProducts();
    }, [subCategory]);

    // Chunking function
    const chunkArray = (array, size) => {
        const chunkedArray = [];
        for (let i = 0; i < array?.length; i += size) {
            chunkedArray.push(array.slice(i, i + size));
        }
        return chunkedArray;
    };

    // Chunk products into arrays of 4
    const productChunks = chunkArray(products, 4);

    console.log("products is all", products)

    const handleHoverDivClick = (link) => {
        window.location.href = link;
    };

    return (
        <div className="all">
            <Row className="categories-top" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Col className="categories-h" lg={9} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>{subCategory}</Col>
                {/* <Col lg={2} className="categories-filter"><Button className="categories-top-btn" onClick={handleShow}><FontAwesomeIcon icon={faFilter} /> Filters</Button></Col> */}
                <Col className="categories-color" lg={1} onClick={() => setSelectedCubcategory(null)}>Back</Col>
            </Row>
            <div className="all-items">
                {productsLoading ? (
                     <div className="wishlist-loading">
                     <ClipLoader size={50} color={"#123abc"} loading={productsLoading} />
                 </div>
                ) : (
                    <>


                        {productChunks.map((chunk, chunkIndex) => (
                            <div key={chunkIndex}>
                                <Row className="all-items-row">
                                    {chunk.map((product, productIndex) => (

                                        <Col key={productIndex} as={Card} className="categories-products-card" onClick={() => handleHoverDivClick(`/productd/${creator.slug}/${product._id}`)}>
                                            {/* <p className="categories-products-banner">
                                <span>
                                    <img src={pic} alt="promoting-creator" className="categories-products-banner-img overlap-1" />
                                    <img src={pic} alt="promoting-creator" className="categories-products-banner-img overlap-2" />
                                    <img src={pic} alt="promoting-creator" className="categories-products-banner-img overlap-3" />
                                </span>
                                30+ Creators are promoting
                            </p> */}
                                            <img src={product?.images[0]?.url || p1} alt="card-img" className="categories-products-card-img" />
                                            <div className="categories-products-price">₹{product?.variations[0]?.options[0]?.selling_price}</div>
                                            <div className="categories-products-title">{product.name}</div>
                                            <div className="categories-products-desc">{product.description}</div>
                                        </Col>
                                    ))}
                                </Row>
                            </div>
                        ))}
                    </>
                )}
            </div>
            {/* <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} size="lg" className="categories-modal">
        <Modal.Header className="categories-modal-header" closeButton>
            <Modal.Title className="categories-modal-title"><FontAwesomeIcon icon={faFilter} onClick={handleShow} /> Filters</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Row className="categories-modal-row">
                <Col className="categories-modal-col">
                    <Form.Select className="categories-modal-select">
                        <option>Skincare</option>
                        <option value="1">Art</option>
                        <option value="2">Healthcare</option>
                        <option value="3">Accessories</option>
                    </Form.Select>
                </Col>
                <Col className="categories-modal-col">
                    <Form.Select className="categories-modal-select">
                        <option>Facewash</option>
                        <option value="1">Moisturizers</option>
                        <option value="2">Firming Creams</option>
                        <option value="3">Sunscreen</option>
                    </Form.Select>
                </Col>
            </Row>
            <Row className="categories-modal-row">
                <Col className="categories-modal-col">
                    <Form.Select className="categories-modal-select">
                        <option>Sort</option>
                        <option value="1">Trending (Most Popular)</option>
                        <option value="2">Price: High to Low</option>
                        <option value="3">Price: Low to High</option>
                    </Form.Select>
                </Col>
                <Col className="categories-modal-col">
                    <Form.Select className="categories-modal-select">
                        <option>Discount</option>
                        <option value="1">Current Sale</option>
                        <option value="2">Over 50% off</option>
                        <option value="3">Over 20% off</option>
                    </Form.Select>
                </Col>
            </Row>
            <Row className="categories-modal-check">
                <Col className="categories-modal-check-col">
                    <p className="categories-modal-check-h"><span className="categories-modal-check-txt">Brands</span><Button className="categories-modal-check-btn">View All</Button></p>
                    <div key={`inline-checkbox`} className="mb-3">
                        <Form.Check
                            inline
                            label="Xenos"
                            name="group1"
                            id={`inline-checkbox-1`}
                        />
                        <Form.Check
                            inline
                            label="Pealrz"
                            name="group1"
                            id={`inline-checkbox-2`}
                        />
                        <Form.Check
                            inline
                            label="Zeste"
                            name="group1"
                            id={`inline-checkbox-3`}
                        />
                        <Form.Check
                            inline
                            label="Villea"
                            name="group1"
                            id={`inline-checkbox-4`}
                        />
                        <Form.Check
                            inline
                            label="Brutios"
                            name="group1"
                            id={`inline-checkbox-5`}
                        />
                        <Form.Check
                            inline
                            label="Murefe"
                            name="group1"
                            id={`inline-checkbox-6`}
                        />
                        <Form.Check
                            inline
                            label="Cleadecat"
                            name="group1"
                            id={`inline-checkbox-7`}
                        />
                        <Form.Check
                            inline
                            label="layzout"
                            name="group1"
                            id={`inline-checkbox-8`}
                        />
                    </div>
                    <div key={`inline-checkbox`} className="mb-3">
                        <Form.Check
                            inline
                            label="Xenos"
                            name="group1"
                            id={`inline-checkbox-1`}
                        />
                        <Form.Check
                            inline
                            label="Pealrz"
                            name="group1"
                            id={`inline-checkbox-2`}
                        />
                        <Form.Check
                            inline
                            label="Zeste"
                            name="group1"
                            id={`inline-checkbox-3`}
                        />
                        <Form.Check
                            inline
                            label="Villea"
                            name="group1"
                            id={`inline-checkbox-4`}
                        />
                        <Form.Check
                            inline
                            label="Brutios"
                            name="group1"
                            id={`inline-checkbox-5`}
                        />
                        <Form.Check
                            inline
                            label="Murefe"
                            name="group1"
                            id={`inline-checkbox-6`}
                        />
                        <Form.Check
                            inline
                            label="Cleadecat"
                            name="group1"
                            id={`inline-checkbox-7`}
                        />
                        <Form.Check
                            inline
                            label="layzout"
                            name="group1"
                            id={`inline-checkbox-8`}
                        />
                    </div>
                </Col>
            </Row>
            <Row className="categories-modal-row">
                <Col className="categories-modal-col-range">
                    <Form.Label>Price</Form.Label>
                    <Form.Range />
                    <span className="categories-modal-range-start">$0</span><span className="categories-modal-range-end">$800</span>
                </Col>
                <Col className="categories-modal-col-range">
                    <Form.Label>Discount</Form.Label>
                    <Form.Range value={0} />
                    <span className="categories-modal-range-start">10%</span><span className="categories-modal-range-end">95%</span>
                </Col>
            </Row>
        </Modal.Body>
        <Modal.Footer className="categories-modal-footer">
            <Button className="categories-modal-btn" onClick={handleClose}>
                Apply
            </Button>
        </Modal.Footer>
    </Modal> */}
        </div>
    )
}

export default CreatorSeectedCategory
