import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Spinner } from 'react-bootstrap';
import axios from 'axios';
import './cm.css'; // Add custom styling here if necessary

function CreatorMessages() {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const token = localStorage.getItem('Ctoken'); // Get the token from localStorage
        const response = await axios.get('https://node.instasales.in/api/v1/creator/creator-messages', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log("response",response)
        setMessages(response.data.data);
        setLoading(false);
      } catch (error) {
        setError('Error fetching messages');
        setLoading(false);
      }
    };

    fetchMessages();
  }, []);

  if (loading) {
    return <Spinner animation="border" />;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <Container className="messages-page my-4">
      <Row>
        <Col>
          <h4>Message</h4>
        </Col>
      </Row>

      <Row className="mt-4">
        {messages.length > 0 ? (
          messages.map((message) => (
            <Col xs={12} key={message._id} className="mb-3">
              <Card className="message-card p-3">
                <blockquote className="blockquote mb-0">
                  <p>"{message?.content}"</p>
                  <footer className="blockquote-footer">
                    {message?.sender?.name} <cite title="Source Title"></cite>
                  </footer>
                </blockquote>
              </Card>
            </Col>
          ))
        ) : (
          <p>No messages available</p>
        )}
      </Row>
    </Container>
  );
}

export default CreatorMessages;
