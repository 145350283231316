import React, { useState, useRef, useEffect } from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import './categories.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Col, Row, Modal, Form } from "react-bootstrap";
import { faFilter, faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import ClipLoader from 'react-spinners/ClipLoader';

const CategoriesClothings = () => {
    const { categoryName } = useParams();
    const [show, setShow] = useState(false);
    const carouselRefs = {
        men: useRef(null),
        women: useRef(null),
        children: useRef(null)
    };

    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 3
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
    const handleCarouselPrev = (key) => {
        carouselRefs[key].current.previous();
    };
    const handleCarouselNext = (key) => {
        carouselRefs[key].current.next();
    };
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [products, setProducts] = useState({
        men: [],
        women: [],
        children: []
    });
    const [loading, setLoading] = useState(false); // State for loading indicator

    useEffect(() => {
        setLoading(true);
        const fetchProducts = async (subcategory) => {
            try {
                const response = await axios.get(`https://node.instasales.in/api/v1/products/subcategory/${subcategory}`);
                return response.data.products;
            } catch (error) {
                console.error(`Error fetching ${subcategory} products:`, error);
                return [];
            } finally {
                setLoading(false); // Set loading to false after API call completes
            }
        };

        const fetchAllProducts = async () => {
            const menProducts = await fetchProducts('Men\'s Wear');
            const womenProducts = await fetchProducts('Women\'s Wear');
            const kidsProducts = await fetchProducts('Children\'s Wear');

            setProducts({
                men: menProducts,
                women: womenProducts,
                children: kidsProducts
            });
        };

        fetchAllProducts();
    }, []);
    console.log("products0", products)

    const handleHoverDivClick = (link) => {
        window.location.href = link;
    };

    return (
        <div className="categories">
            <Row className="categories-top">
                <Col className="categories-h" lg={9}>Clothings</Col>
               
            </Row>
            {loading ? (
                <div className="wishlist-loading">
                    <ClipLoader size={50} color={"#123abc"} loading={loading} />
                </div>
            ) : (
                <>
                    {/* {['men', 'women', 'kids'].map((key, index) => ( */}
                    {['men', 'women', 'children'].map((key, index) => (
                        <div className="categories-products" key={key}>
                            <Row className={`categories-products-row bg-${index % 2 === 0 ? 'green' : index % 3 === 0 ? 'blue' : 'yellow'}`}>
                                <Col lg={4} className="categories-products-content">
                                    <div className="categories-products-h">{`${key.charAt(0).toUpperCase() + key.slice(1)}'s Wear`}</div>
                                    <div className="categories-products-p"></div>
                                    <div className="categories-products-ctrl">
                                        <Button className="custom-left-arrow" onClick={() => handleCarouselPrev(key)}>
                                            <FontAwesomeIcon icon={faCaretLeft} size='2x' />
                                        </Button>
                                        <Button className="custom-right-arrow" onClick={() => handleCarouselNext(key)}>
                                            <FontAwesomeIcon icon={faCaretRight} size='2x' />
                                        </Button>
                                    </div>
                                    <Button onClick={() => handleHoverDivClick(`/subcategories-all/${key.charAt(0).toUpperCase() + key.slice(1)}'s Wear`)} className="categories-products-btn">View All</Button>

                                </Col>
                                <Col lg={8} className="categories-products-carousel-col">
                                    <Carousel responsive={responsive} arrows={false} ref={carouselRefs[key]} className="categories-products-carousel">
                                        {products[key].map((product) => (
                                            <Link to={`/product/${product.brand_slug}/${product._id}`} key={product._id}>
                                                <Card className="categories-products-card">
                                                    <img src={product.images[0].url} alt="card-img" className="categories-products-card-img" />
                                                    <div className="categories-products-price">₹{product?.variations[0]?.options[0]?.selling_price}</div>
                                                    <div className="categories-products-title">{product?.name}</div>
                                                    <div className="categories-products-desc">{product?.description}</div>
                                                </Card>
                                            </Link>
                                        ))}
                                    </Carousel>
                                </Col>
                            </Row>
                        </div>
                    ))}
                </>)}
        </div>
    );
}

export default CategoriesClothings;
