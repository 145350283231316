import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, Row, Col, Container } from 'react-bootstrap';
import axios from 'axios';
import "./feature.css";
import { Button } from "react-bootstrap";
import { faFilter, faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ClipLoader } from 'react-spinners';
import Slider from '@mui/material/Slider';
import {   Form, Modal } from "react-bootstrap";

const FeatureProducts = () => {
    const [products, setProducts] = useState([]);
    const [loadingProducts, setLoadingProducts] = useState(true);
    const [errorProducts, setErrorProducts] = useState(null);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [selectedFilters, setSelectedFilters] = useState({
        subCategory: '',
        sort: '',
        discount: '',
        brands: [],
        priceRange: [0, 800],
        discountRange: [10, 95]
    });
    const [maxPrice, setMaxPrice] = useState(1000);

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const { data } = await axios.get('https://node.instasales.in/api/v1/products');
                setProducts(data.product);
                setLoadingProducts(false);
            } catch (error) {
                setErrorProducts('Error fetching products.');
                setLoadingProducts(false);
            }
        };
        fetchProducts();
    }, []);

    if (loadingProducts) {
         return (
            <div className="cart-loading">
              <ClipLoader size={50} color={"#123abc"} loading={loadingProducts} />
            </div>
          );
    }

    if (errorProducts) {
        return <div>{errorProducts}</div>;
    }

    const handleHoverDivClick = (link) => {
        window.location.href = link;
      };

      const applyFilters = () => {
        let filtered = [...products];

        // Apply price range filter
        const [minPrice, maxPrice] = selectedFilters.priceRange;
        filtered = filtered.filter(product => {
            const price = product.variations[0]?.options[0].selling_price;
            return price >= minPrice && price <= maxPrice;
        });
        if (selectedFilters.subCategory) {
            filtered = filtered.filter(product => product.sub_category === selectedFilters.subCategory);
        }
        // Filter by brands
        if (selectedFilters.brands.length > 0) {
            filtered = filtered.filter(product => selectedFilters.brands.includes(product.brand_name));
        }

        // Apply sorting
        if (selectedFilters.sort) {
            if (selectedFilters.sort === '1') { // Trending
                filtered = filtered.sort((a, b) => b.popularity - a.popularity); // Assuming popularity is a field
            } else if (selectedFilters.sort === '2') { // Price: High to Low
                filtered = filtered.sort((a, b) => b.variations[0]?.options[0].selling_price - a.variations[0]?.options[0].selling_price);
            } else if (selectedFilters.sort === '3') { // Price: Low to High
                filtered = filtered.sort((a, b) => a.variations[0]?.options[0].selling_price - b.variations[0]?.options[0].selling_price);
            }
        }

        setProducts(filtered);
        handleClose();
    };

    const handleBrandFilterChange = (event) => {
        const brand = event.target.value;
        const isChecked = event.target.checked;
        setSelectedFilters(prevFilters => ({
            ...prevFilters,
            brands: isChecked ? [...prevFilters.brands, brand] : prevFilters.brands.filter(b => b !== brand)
        }));
    };
    const handleSubCategoryChange = (event) => {
        setSelectedFilters(prevFilters => ({
            ...prevFilters,
            subCategory: event.target.value
        }));
    };

    const handlePriceRangeChange = (value) => {
        setSelectedFilters(prevFilters => ({
            ...prevFilters,
            priceRange: value
        }));
    };

    const handleDiscountRangeChange = (event) => {
        const value = event.target.value;
        setSelectedFilters(prevFilters => ({
            ...prevFilters,
            discountRange: [10, value]
        }));
    };

    const getUniqueBrands = () => {
        const allBrands = products.map(product => product.brand_name);
        return [...new Set(allBrands)];
    };

    const handleCategoryClick = (category) => {
        window.location.href = `/categories/${category}`;
    };

    const handleDropdownChange = (event) => {
        const selectedCategory = event.target.value;

    };
    const getUniqueSubCategories = () => {
        const allSubCategories = products.map(product => product.sub_category);
        return [...new Set(allSubCategories)];
    };

    return (
        <div className="categories feature-container">
            <Row className="categories-top" style={{marginBottom:"36px"}}>
                <Col className="categories-h" lg={9}>Featured Products</Col>
                <Col lg={2} className="categories-filter">
                    <Button className="categories-top-btn" onClick={handleShow}>
                        <FontAwesomeIcon icon={faFilter} /> Filters
                    </Button>
                </Col>
                <Col className="categories-color" lg={1}></Col>
            </Row>
        <Container className='home-products '>
            <Row className='home-products-row' style={{rowGap:"48px"}}>
                {products.map((product) => (
                    <Col xs={12} sm={6} md={4} lg={3} key={product._id} className='home-products-carousel-col'>
                            <Card className="home-products-card feature-card" onClick={() => handleHoverDivClick(`/product/${product.brand_slug}/${product._id}`)}>
                                <Card.Img 
                                    variant="top" 
                                    src={product.images[0].url} 
                                    alt="product-img" 
                                    className="home-products-card-img product-card-img" 
                                />
                                <Card.Body className='feature-body'>
                                    <Card.Text className="home-products-price feature-price">
                                        ₹{product?.variations[0]?.options[0]?.selling_price}
                                    </Card.Text>
                                    <Card.Title className="home-products-title feature-title">
                                        {product?.name}
                                    </Card.Title>
                                    <Card.Text className="home-products-desc feature-desc">
                                        {product?.description}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                    </Col>
                ))}
            </Row>
        </Container>
        <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} size="lg" className="categories-modal">
                <Modal.Header className="categories-modal-header" closeButton>
                    <Modal.Title className="categories-modal-title"><FontAwesomeIcon icon={faFilter} onClick={handleShow} /> Filters</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="categories-modal-row">
                        <Col className="categories-modal-col">
                            <Form.Select className="categories-modal-select"  onChange={handleDropdownChange}>
                                <option disabled>Select a Category</option>
                                <option value="Skin Care">Skin Care</option>
                                <option value="Art">Art</option>
                                <option value="Clothing">Clothing</option>
                                <option value="Accessories">Accessories</option>
                                <option value="Home & Living">Home & Living</option>
                                <option value="Foods & Beverages">Foods & Beverages</option>
                                <option value="Kitchen">Kitchen</option>
                                <option value="Stationery">Stationery</option>
                            </Form.Select>
                        </Col>
                        <Col className="categories-modal-col">
                            <Form.Select className="categories-modal-select" value={selectedFilters.subCategory} onChange={handleSubCategoryChange}>
                                <option value="">All Subcategories</option>
                                {getUniqueSubCategories().map((subCategory, index) => (
                                    <option key={index} value={subCategory}>{subCategory}</option>
                                ))}
                            </Form.Select>
                        </Col>
                    </Row>
                    <Row className="categories-modal-row">
                        <Col className="categories-modal-col">
                            <Form.Select className="categories-modal-select" value={selectedFilters.sort} onChange={(e) => setSelectedFilters({ ...selectedFilters, sort: e.target.value })}>
                                <option value="">Sort</option>
                                <option value="1">Trending (Most Popular)</option>
                                <option value="2">Price: High to Low</option>
                                <option value="3">Price: Low to High</option>
                            </Form.Select>
                        </Col>
                        <Col className="categories-modal-col">
                            <Form.Select className="categories-modal-select" value={selectedFilters.discount} onChange={(e) => setSelectedFilters({ ...selectedFilters, discount: e.target.value })}>
                                <option value="">Discount</option>
                                <option value="1">Current Sale</option>
                                <option value="2">Over 50% off</option>
                                <option value="3">Over 20% off</option>
                            </Form.Select>
                        </Col>
                    </Row>

                    <Row className="categories-modal-check">
                        <Col className="categories-modal-check-col">
                            <p className="categories-modal-check-h">
                                <span className="categories-modal-check-txt">Brands</span>
                            </p>
                            <div key={`inline-checkbox`} className="mb-3">
                                {getUniqueBrands().map((brand, index) => (
                                    <Form.Check
                                        key={index}
                                        inline
                                        label={brand}
                                        value={brand}
                                        onChange={handleBrandFilterChange}
                                        checked={selectedFilters.brands.includes(brand)}
                                    />
                                ))}
                            </div>
                        </Col>
                    </Row>
                    <Row className="categories-modal-row">
                        <Col className="categories-modal-col-range">
                            <Form.Label>Price (₹)</Form.Label>
                            <Slider
                                range={true}
                                step={10}
                                defaultValue={[10, maxPrice]}
                                min={0}
                                max={maxPrice}
                                onChange={changeEvent => handlePriceRangeChange(changeEvent.target.value)}
                                railStyle={{ backgroundColor: "#959595" }}
                                trackStyle={{ backgroundColor: "#959595" }}
                            />

                            <span className="categories-modal-range-start">₹{selectedFilters.priceRange[0]}</span>
                            <span className="categories-modal-range-end">₹{selectedFilters.priceRange[1]}</span>
                        </Col>
                        <Col className="categories-modal-col-range">
                            <Form.Label>Discount</Form.Label>
                            <Slider
                                range={true}
                                value={selectedFilters.discountRange[1]}
                                defaultValue={[5, 95]}
                                onChange={changeEvent => handleDiscountRangeChange(changeEvent)}
                                min={10}
                                max={95}
                               
                            />
                            <span className="categories-modal-range-start">{selectedFilters.discountRange[0]}%</span>
                            <span className="categories-modal-range-end">{selectedFilters.discountRange[1]}%</span>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className="categories-modal-footer">
                    <Button className="categories-modal-btn" onClick={applyFilters}>
                        Apply
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default FeatureProducts;
