import React from 'react'
import './info.css'

const Privacy = () => {
    return (
        <div className="privacy">
            <div className="privacy-h">Privacy Policy</div>
            <div className="policy">
                <div className="policy-p">Privacy issues are a high priority for us and we follow standard industry practices to maintain your personal information. Please read the Privacy Policy of the Website at www.localnation.in to learn more about the Website’s Privacy Policy.</div>
            </div>
            <div className="policy">
                <div className="policy-h">
                    Policy on Abuse on the Website:
                </div>
                <div className="policy-p">
                    This Website is committed to ensuring and enforcing a safe and friendly online community space for all our members and /or subscribers and / or Users. To this effect, we have come up with a Policy on Abuse that we would like all our members to be aware of. These rules govern the use of the App and its Product/Service offerings and we would like all our members and / or subscribers and / or Users to cooperate in complying with these rules so that everyone can have a pleasant experience.
                </div>
            </div>
            <div className="policy">
                <div className="policy-h">
                    Conduct and use of language:
                </div>
                <div className="policy-p">
                    Member and / or subscribers contributions must be civil and tasteful and must not be malicious or designed to offend. Use of swear-words or profanity is strictly prohibited.
                    <br></br>Users of the Website may not insult or defame the Website, including any of its employees, moderators or freelancers or persons giving opinion on the Website. You may not post the contents (in part or whole) of any email or message that you may receive from any of the Website’s employee(s) and/or moderator.
                    <br></br>Do not post any material that is obscene, defamatory, profane, libelous, threatening, harassing, abusive, hateful or embarrassing towards other person(s) or an entity. We encourage a healthy exchange of opinions and disagreements. By all means challenging of an opinion is allowed but the same must be done respectfully. Name-calling, insults and use of foul or abusive language will not be tolerated. This includes the use of offensive member Display Names and URLs (your personal website address) on the Website.
                    <br></br>Please be sure before you divulge the details of your identity or personal information. Anything posted online is accessible to the entire internet community and the Website is not liable in cases where such information is misused, altered or tampered with. Please be aware of the general dangers of online information sharing like identity theft, phishing and other fraudulent activities for which, the Website shall not accept any responsibility.
                    <br></br>A User of the Website must not impersonate another User.
                </div>
            </div>
            <div className="policy">
                <div className="policy-h">
                    Copyrighted Material:
                </div>
                <div className="policy-p">
                    Uploading copies of copyrighted material as a whole or in part on the Website without explicit permission from the copyright owner shall be deemed to infringe on the copyright and this applies to materials such as text, images, etc. The Website reserves the right to immediately delete such material without prior permission / consultation from such uploading person / member. If you suspect that any material or image submitted to the Website is copyright protected and has been used fraudulently, please contact us immediately or send us an email at info@instasales.in
                </div>
            </div>
            <div className="policy">
                <div className="policy-h">
                    Safety Guidelines:
                </div>
                <div className="policy-p">
                    The Website is always reaching out to a wider customer and / or User. However, in doing so, we will not allow to be uploaded anything on the Website that we think could be potentially endangering or is inflammatory or embarrassing to our members / Users /subscribers / any third persons / group / community / section / caste / linguistic group / etc. It also helps members to be aware of certain things in the interest of their safety.
                    <br></br>All members are responsible for the safety of their personal information.
                </div>
            </div>
            <div className="policy">
                <div className="policy-h">
                    Legalities:
                </div>
                <div className="policy-p">
                    All Users and / or members and / or subscribers are reminded that they may be held legally / personally accountable for what they say, sell, buy or do online. In particular, members may be held liable for any defamatory comments, threats and/or untrue statements or other illegal and fraudulent claims made by them.
                    <br></br>The Website does not endorse in anyway any advertisers / contents of advertisers on its pages. Please therefore verify the veracity of all information on your own before undertaking reliance and actioning thereupon. The Website shall not be responsible or liable for any consequential damages arising on account of you are relying on the contents of the advertisement/s.
                    <br></br>The Website does not assume any legal liability or responsibility for the accuracy, legality, legitimacy, validity, reliability, completeness, suitability or usefulness of any information, content, Product, Services, or processes disclosed on the Website or any other material or information accessible from the Website.
                    <br></br>If any member fails to observe the above rules of conduct at any time, the Website reserves the right to terminate the membership of the member and/or registration of the User and delete all their contents and contributions (either temporarily or permanently) from the Website, depending on the nature and severity of the breach and without prior warning or consent of the member and / or User.
                    <br></br>You agree to indemnify and hold the Website, its developer or any person associated with the Website, Instasales affiliates, officers and employees, harmless from any claim/s, demand/s, or damage/s, including reasonable attorneys' fees, asserted by any third party due to or arising out of your use of or conduct on the Website.
                    <br></br>You must use the Service in a manner consistent with any and all applicable local, state, Indian and International law and such other applicable laws, rules and regulations. Illegal and/or unauthorized use of the Service, including unauthorized framing of or linking to the Website will be investigated, and appropriate legal action will be taken, including without limitation, civil, criminal and injunctive redress.
                    <br></br>The performance of this Agreement is subject to existing laws and legal process, and nothing contained in this Terms is in derogation of individual’s right to comply with governmental, court and law enforcement requests or requirements relating to your use of the Website’s Services or information provided to or gathered by the Website with respect to such use.
                    <br></br>Registration as the Website’s member and / or User at any time past or present and use of the Website and all its Product / Service offerings will be taken as acceptance of the above rules of conduct. The Website’s and Instasales decision on all matters is final and binding on its members.This Website is created and controlled by Webminix operating in Hydrabad as such the laws of India shall apply; courts in Hydrabad shall have the exclusive jurisdiction in respect of all the terms, conditions and disclaimers. You hereby irrevocably consent to the exclusive jurisdiction and venue of courts in Hydrabad in all disputes arising out of or relating to the use of the Website’s Services. Use of the Website’s Services is unauthorized in any jurisdiction that does not give effect to all provisions of the terms and conditions contained herein, including without limitation to this paragraph.
                </div>
            </div>
            <div className="policy">
                <div className="policy-h">
                    Severability:
                </div>
                <div className="policy-p">
                    In the event that a court of competent jurisdiction declares any portion of the terms and conditions of the present Service/ Agreement to be void or invalid for any reasons, then such unenforceable provision shall be deemed severable and shall not affect the validity and enforceability of the remaining clauses of the terms and conditions.
                </div>
            </div>
            <div className="policy">
                <div className="policy-h">
                    Dispute Resolution and Governing Law:
                </div>
                <div className="policy-p">
                    All disputes, controversies or claims arising out of or in connection with this Agreement, including any dispute regarding its existence, validity, interpretation, breach or termination and the services provided by the Website, shall be first amicably resolved between the User and us. If the dispute is not resolved through such discussions within 7 (seven) days after either of the parties has served a written notice on the other requesting the commencement of discussions, either party shall refer the disputes to arbitration which shall be decided in accordance with the provisions of the Arbitration and Conciliation Act, 1996. The Arbitration proceedings shall be conducted in the English language. Webinix shall appoint a sole Arbitrator. The Arbitration award shall be reasoned award and shall be final and binding on both the parties and shall be enforceable in any court of competent jurisdiction.You agree that any legal action or actions, dispute or disputes, proceeding or proceedings or other matters relating to your access to or use of this Website shall be governed by Laws of India. In the event of any legal action or actions, dispute or disputes, proceeding or proceedings or other matters arising hereunder, you also agree to submit to the jurisdiction and venue of courts in Hydrabad.Updated Terms of Services.
                </div>
            </div>
            <div className="policy">
                <div className="policy-h">
                    General Provision:
                </div>
                <div className="policy-p">
                    This Website endeavors to foster a vibrant online community by enabling its users to generate leads for the products available on the platform, as soon as those leads turn into real business, a commission payout is given to the user who generate the lead and thus forming mutually beneficial relationships. We are here to serve our Users 24x7 and use all feedback to refine and improve the online experience for our Users. You can reach our team anytime by writing to info@instasales.in and we will respond to you in a timely manner. We hope you have an enjoyable and rewarding experience on our Website. Welcome aboard ACCEPT
                </div>
            </div>
        </div>
    )
}

export default Privacy
