import React, { useContext, useState } from 'react'
import './auth.css'
import { Col, Row, Button, Container, InputGroup, Form } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import { faGoogle } from '@fortawesome/free-brands-svg-icons'
import axios from 'axios';
import { GoogleLogin } from 'react-google-login';
import OtpInput from "react-otp-input";
import { UserContext } from '../UserContext';



const Auth = () => {
    const [authStep, setAuthStep] = useState(1);
    const [googleResponse, setGoogleResponse] = useState('');
    const [userNotFound, setUserNotFound] = useState(false);
    const [code, setCode] = useState("");
    const handleChange = (code) => setCode(code);
    const { setUser } = useContext(UserContext);

   

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [fullName, setFullName] = useState('');
    const [phone, setPhone] = useState('');
    const [gender, setGender] = useState('');
    const [dob, setDob] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [otp, setOtp] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [loginErrorMessage, setLoginErrorMessage] = useState('');

    const handleEmailChange = (e) => setEmail(e.target.value);
    const handlePasswordChange = (e) => setPassword(e.target.value);
    const handleFullNameChange = (e) => setFullName(e.target.value);
    const handlePhoneChange = (e) => setPhone(e.target.value);
    const handleGenderChange = (e) => setGender(e.target.value);
    const handleDobChange = (e) => setDob(e.target.value);
    const handleConfirmPasswordChange = (e) => setConfirmPassword(e.target.value);
    const handleOtpChange = (e) => setOtp(e.target.value);

    const handleSignUpClick = () => {
        if (password !== confirmPassword) {
            setError('Passwords do not match');
        } else {
            setError('');
            setAuthStep(7); // Proceed to the next step
        }
    };

    const handleLogin = async () => {
        try {
            const { data } = await axios.post('https://node.instasales.in/api/v1/login', {
                email,
                password,
            });
            console.log('Login successful:', data);
            const { token, user } = data;
            localStorage.setItem('token', token);
            localStorage.setItem('user', JSON.stringify(user));
            setUser(user);
            const expires = new Date(Date.now() + 5 * 24 * 60 * 60 * 1000);
            document.cookie = `token=${token}; expires=${expires.toUTCString()}; path=/; Secure; SameSite=None`;
            window.location.href = '/';
        } catch (error) {
            console.error('Error logging in:', error);
            setLoginErrorMessage(error.response?.data?.message || 'Login failed. Please try again.');
        }
    };

    const handleRegister = async () => {
        try {
            const { data } = await axios.post('https://node.instasales.in/api/v1/register', {
                name: fullName,
                email,
                phone,
                gender,
                dob,
                ...(password && { password })
            });
            console.log('Register successful:', data);
            const { token, user } = data;
            localStorage.setItem('token', token);
            localStorage.setItem('user', JSON.stringify(user));
            setUser(user);
            const expires = new Date(Date.now() + 5 * 24 * 60 * 60 * 1000);
            document.cookie = `token=${token}; expires=${expires.toUTCString()}; path=/; Secure; SameSite=None`;
            window.location.href = '/';
        } catch (error) {
            console.error('Error registering user:', error);
            setError('Registration failed. Please try again.');
        }
    };


    const [formState, setFormState] = useState({
        email: '',
        password: '',
        fullName: '',
        phone: '',
        gender: '',
        dob: '',
        confirmPassword: '',
        otp: '',
        error: '',
        success: '',
    });

    const [loginState, setLoginState] = useState({
        email: '',
        password: '',
        errorMessage: '' // Add error message state
    });


    const handleLoginInputChange = (e) => {
        const { name, value } = e.target;
        setLoginState(prevState => ({
            ...prevState,
            [name]: value,
            errorMessage: '' // Clear error message on input change
        }));
    };

    console.log("form state", formState)
    console.log("googleResponse", googleResponse)


    const [confirmerror, setConfirmError] = useState('');

    

   

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name.startsWith("otp")) {
            const otpIndex = parseInt(name.charAt(name.length - 1), 10); // Extract the OTP index
            setFormState(prevState => ({
                ...prevState,
                [`otp${otpIndex}`]: value,
            }));
        } else {
            setFormState(prevState => ({
            ...prevState,
            [name]: value
        }));
        }
    };
    const responseGoogle = (response) => {
        console.log(response);
        setGoogleResponse(response);
        const { tokenId, profileObj } = response;

        axios.post('https://node.instasales.in/api/v1/google/login', {
            token_id: tokenId,
            email: profileObj?.email,
            name: profileObj?.name,
            image: profileObj?.imageUrl
        }, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(response => {
                console.log('Google login successful:', response.data);
                const { token, user } = response.data;
                localStorage.setItem('token', token);
                localStorage.setItem('user', JSON.stringify(user));
                setUser(user);
                const expires = new Date(Date.now() + 5 * 24 * 60 * 60 * 1000);
                document.cookie = `token=${token}; expires=${expires.toUTCString()}; path=/; Secure; SameSite=None`;
                window.location.href = '/'
                // Handle successful login, store token and user details
                window.location.href = '/'
            })
            .catch(error => {
                if (error.response && error.response.data.message === 'User Not Found') {
                    setUserNotFound(true);
                    setAuthStep(5);
                } else {
                    console.error('Error logging in with Google:', error);
                }
            });
    };

    const registerUser = async () => {

        try {
            const { data } = await axios.post('https://node.instasales.in/api/v1/register', {
                name: formState.fullName,
                email: formState.email,
                phone: formState.phone,
                gender: formState.gender,
                dob: formState.dob,
                ...(formState.password && { password: formState.password }) // Only include password if it's not an empty string

            });
            console.log(data);
            // Handle successful registration, perhaps redirect to another page
            console.log("register success");
            const { token, user } = data;
            localStorage.setItem('token', token);
            localStorage.setItem('user', JSON.stringify(user));
            setUser(user);
            // Handle successful login, store token and user details
            const expires = new Date(Date.now() + 5 * 24 * 60 * 60 * 1000);
            document.cookie = `token=${token}; expires=${expires.toUTCString()}; path=/; Secure; SameSite=None`;
            window.location.href = '/'
        } catch (error) {
            console.error('Error registering user:', error);
            // Handle registration error
        }
    }


    const GoogleregisterUser = async () => {
        try {
            const { data } = await axios.post('https://node.instasales.in/api/v1/register', {
                name: formState.fullName,
                email: googleResponse.profileObj?.email,
                phone: formState.phone,
                gender: formState.gender,
                dob: formState.dob
            });
            console.log(data);
            // Handle successful registration, perhaps redirect to another page
            console.log("register success");
            const { token, user } = data;
            localStorage.setItem('token', token);
            localStorage.setItem('user', JSON.stringify(user));
            setUser(user);
            // Handle successful login, store token and user details
            const expires = new Date(Date.now() + 5 * 24 * 60 * 60 * 1000);
            document.cookie = `token=${token}; expires=${expires.toUTCString()}; path=/; Secure; SameSite=None`;
            window.location.href = '/'
        } catch (error) {
            console.error('Error registering user:', error);
            // Handle registration error
        }
    };

    const login = async () => {
        try {
            const { data } = await axios.post('https://node.instasales.in/api/v1/login', {
                email: loginState.email,
                password: loginState.password,
            });
            console.log('Login successful:', data);
            // Handle successful login, perhaps store token and user details in state
            const { token, user } = data;
            localStorage.setItem('token', token);
            localStorage.setItem('user', JSON.stringify(user));
            setUser(user);
            // Handle successful login, store token and user details
            const expires = new Date(Date.now() + 5 * 24 * 60 * 60 * 1000);
            document.cookie = `token=${token}; expires=${expires.toUTCString()}; path=/; Secure; SameSite=None`;
            window.location.href = '/'
        } catch (error) {
            console.error('Error logging in:', error);
            // Set error message
            setLoginState(prevState => ({
                ...prevState,
                errorMessage: error.response?.data?.message || 'Login failed. Please try again.'
            }));
        }
    };



    const sendOTP = async () => {
        setAuthStep(9)
        try {

            const { data } = await axios.post('https://node.instasales.in/api/v1/send-mobile-otp', {
                phone: formState.phone,
            });
            console.log('Login successful:', data);
            // const { token, user } = data;
            // localStorage.setItem('token', token);
            // localStorage.setItem('user', JSON.stringify(user));
            // // Handle successful login, store token and user details
            // window.location.href = '/'
            // Handle successful login, perhaps store token and user details in state
        } catch (error) {
            console.error('Error logging in:', error);
            // Handle login error

        }
    };

    const handleSubmitOTP = async () => {
        try {
            const { data } = await axios.post('https://node.instasales.in/api/v1/verify-otp', {
                phone: formState.phone,
                otp: code
            });

            console.log('OTP verification response:', data);

            if (data.success) {
                console.log("login success");
                const { token, user } = data;
                localStorage.setItem('token', token);
                localStorage.setItem('user', JSON.stringify(user));
                setUser(user);
                // Handle successful login, store token and user details
                const expires = new Date(Date.now() + 5 * 24 * 60 * 60 * 1000);
                document.cookie = `token=${token}; expires=${expires.toUTCString()}; path=/; Secure; SameSite=None`;
                window.location.href = '/'
                // Handle successful login, maybe redirect or show success message
            } else {
                // Check the error message

                alert('Invalid OTP. Please try again.');
            }
        } catch (error) {
            console.error('Error verifying OTP:', error);
            if (error.response && error.response.data.message === 'User not found') {
                setUserNotFound(true);
                setAuthStep(6);
            } else {
                console.error('Error logging in with phone:', error);
            }
        }
    };
   
    const [message, setMessage] = useState('');

    const handleInputPassChange = (e) => {
        const { name, value } = e.target;
        if (name === 'password') {
            setPassword(value);
        } else if (name === 'confirmPassword') {
            setConfirmPassword(value);
        }
    };


    const handleSendOTP = async () => {
        try {
            const response = await axios.post('https://node.instasales.in/api/v1/send-otp', { email });
            if (response.data.success) {
                setAuthStep(8)
                setMessage('OTP sent successfully. Please check your email.');
                setError('');
            } else {
                setError(response.data.message);
                setMessage('');
            }
        } catch (error) {
            console.error('Error sending OTP:', error);
            setError(error.response.data.message);
            setMessage('');
        }
    };

    const handleResetPassword = async () => {
        try {
            const response = await axios.post('https://node.instasales.in/api/v1/change-password', { email, code, password, confirmPassword });
            if (response.data.success) {
                // setStep(1);
                setAuthStep(1)
                setMessage('Password updated successfully.');
                setError('');
            } else {
                setError(response.data.message);
                setMessage('');
            }
        } catch (error) {
            console.error('Error resetting password:', error);
            setError('Something went wrong. Please try again.');
            setMessage('');
        }
    };



    const AuthComponent1 = () => {
        return (
            <div>
                {/* <Button className="auth-btn" onClick={() => setAuthStep(5)}><FontAwesomeIcon icon={faGoogle} /> Continue with Google</Button> */}
                <GoogleLogin
                    clientId="1039353997484-lbnfp3o3sbmdtorl6dl669lp99locmfv.apps.googleusercontent.com"
                    buttonText="Continue with Google"
                    onSuccess={responseGoogle}
                    onFailure={responseGoogle}
                    cookiePolicy={'single_host_origin'}
                    className='auth-btn'
                // isSignedIn={true}
                />
                <Button className="auth-btn" onClick={() => setAuthStep(2)}><FontAwesomeIcon icon={faEnvelope} /> Continue with Email</Button>
                <InputGroup className="auth-btn input-grp">
                    <InputGroup.Text>+91</InputGroup.Text>
                    <Form.Control
                        placeholder="xxxxxxxxxx"
                        name="phone"
                        value={formState.phone}
                        onChange={handleInputChange}
                    />
                    <Button onClick={sendOTP}><FontAwesomeIcon icon={faPaperPlane} /></Button>
                </InputGroup>
            </div>
        )
    }

    const AuthComponent2 = () => {
        return (
            <div>
                <InputGroup className="auth-btn input-grp">
                    <InputGroup.Text>Email</InputGroup.Text>
                    <Form.Control
                        name="email"
                        value={loginState.email}
                        onChange={handleLoginInputChange}
                    />
                </InputGroup>
                <InputGroup className="auth-btn input-grp">
                    <InputGroup.Text>Password</InputGroup.Text>
                    <Form.Control
                        type="password"
                        name="password"
                        value={loginState.password}
                        onChange={handleLoginInputChange}
                    />
                </InputGroup>
                {loginState.errorMessage && (
                    <div className="error-message">
                        {loginState.errorMessage}
                    </div>
                )}
                <a className="auth-fp" onClick={() => setAuthStep(11)}>Forgot password?</a>
                <div className="auth-si-btn-wrapper">
                    <Button className="auth-si-btn" onClick={login}>Sign-in</Button>
                </div>

            </div>
        );
    };


    const AuthComponent3 = () => {
        return (
            <div>
                <InputGroup className="auth-btn input-grp">
                    <InputGroup.Text>Email</InputGroup.Text>
                    <Form.Control
                        name="email"
                        value={formState.email}
                        onChange={handleInputChange}
                    />
                </InputGroup>
                <InputGroup className="auth-btn input-grp">
                    <InputGroup.Text>Phone No.</InputGroup.Text>
                    <Form.Control
                        name="phone"
                        value={formState.phone}
                        onChange={handleInputChange}
                    />
                </InputGroup>
                <InputGroup className="auth-btn input-grp">
                    <InputGroup.Text>Password</InputGroup.Text>
                    <Form.Control
                        type="password"
                        name="password"
                        value={formState.password}
                        onChange={handleInputChange}
                    />
                </InputGroup>
                <InputGroup className="auth-btn input-grp">
                    <InputGroup.Text>Confirm Password</InputGroup.Text>
                    <Form.Control
                        type="password"
                        name="confirmPassword"
                        value={formState.confirmPassword}
                        onChange={handleInputChange}
                    />
                </InputGroup>

                {confirmerror && <div className="error-message">{confirmerror}</div>}

                <div className="auth-si-btn-wrapper">
                    <Button className="auth-si-btn" onClick={handleSignUpClick}>Sign-up</Button>
                </div>
            </div>
        )
    }

    const AuthComponent4 = () => {
        return (
            <div>
                <InputGroup className="auth-btn input-grp">
                    <InputGroup.Text>New Password</InputGroup.Text>
                    <Form.Control
                        type="password"
                        name="password"
                        value={password}
                        onChange={handleInputPassChange}
                    />
                </InputGroup>
                <InputGroup className="auth-btn input-grp">
                    <InputGroup.Text>Confirm Password</InputGroup.Text>
                    <Form.Control
                        type="password"
                        name="confirmPassword"
                        value={confirmPassword}
                        onChange={handleInputPassChange}
                    />
                </InputGroup>

                <div className="auth-si-btn-wrapper">
                    <Button className="auth-si-btn" onClick={handleResetPassword}>Proceed</Button>
                </div>
            </div>
        )
    }

    const AuthComponent5 = () => {
        return (
            <div>
                <InputGroup className="auth-btn input-grp">
                    <InputGroup.Text>Full Name</InputGroup.Text>
                    <Form.Control
                        name="fullName"
                        value={formState.fullName}
                        onChange={handleInputChange}
                    />
                </InputGroup>
                <InputGroup className="auth-btn input-grp">
                    <InputGroup.Text>Phone No.</InputGroup.Text>
                    <Form.Control
                        name="phone"
                        value={formState.phone}
                        onChange={handleInputChange}
                    />
                </InputGroup>
                <InputGroup className="auth-btn input-grp">
                    <InputGroup.Text>Gender</InputGroup.Text>
                    <Form.Select
                        name="gender"
                        value={formState.gender}
                        onChange={handleInputChange}
                    >
                        <option>--Select one--</option>
                        <option value="Female">Female</option>
                        <option value="Male">Male</option>
                        <option value="Other">Other</option>
                    </Form.Select>
                </InputGroup>
                <InputGroup className="auth-btn input-grp">
                    <InputGroup.Text>Date of Birth</InputGroup.Text>
                    <Form.Control
                        type="date"
                        name="dob"
                        value={formState.dob}
                        onChange={handleInputChange}
                    />
                </InputGroup>

                <div className="auth-si-btn-wrapper">
                    <Button className="auth-si-btn" onClick={GoogleregisterUser}>Proceed</Button>
                </div>
            </div>
        )
    }



    const AuthComponent6 = () => {
        return (
            <div>
                <InputGroup className="auth-btn input-grp">
                    <InputGroup.Text>Full Name</InputGroup.Text>
                    <Form.Control
                        name="fullName"
                        value={formState.fullName}
                        onChange={handleInputChange}
                    />
                </InputGroup>
                <InputGroup className="auth-btn input-grp">
                    <InputGroup.Text>Email</InputGroup.Text>
                    <Form.Control
                        name="email"
                        value={formState.email}
                        onChange={handleInputChange}
                    />
                </InputGroup>
                <InputGroup className="auth-btn input-grp">
                    <InputGroup.Text>Gender</InputGroup.Text>
                    <Form.Select
                        name="gender"
                        value={formState.gender}
                        onChange={handleInputChange}
                    >
                        <option>--Select one--</option>
                        <option value="Female">Female</option>
                        <option value="Male">Male</option>
                        <option value="Other">Other</option>
                    </Form.Select>
                </InputGroup>
                <InputGroup className="auth-btn input-grp">
                    <InputGroup.Text>Date of Birth</InputGroup.Text>
                    <Form.Control
                        type="date"
                        name="dob"
                        value={formState.dob}
                        onChange={handleInputChange}
                    />
                </InputGroup>

                <div className="auth-si-btn-wrapper">
                    <Button className="auth-si-btn" onClick={() => setAuthStep(10)}>Proceed</Button>
                </div>
            </div>
        )
    }

    const AuthComponent7 = () => {
        return (
            <div>
                <InputGroup className="auth-btn input-grp">
                    <InputGroup.Text>Full Name</InputGroup.Text>
                    <Form.Control
                        name="fullName"
                        value={formState.fullName}
                        onChange={handleInputChange}
                    />
                </InputGroup>
                <InputGroup className="auth-btn input-grp">
                    <InputGroup.Text>Gender</InputGroup.Text>
                    <Form.Select
                        name="gender"
                        value={formState.gender}
                        onChange={handleInputChange}
                    >
                        <option>--Select one--</option>
                        <option value="Female">Female</option>
                        <option value="Male">Male</option>
                        <option value="Other">Other</option>
                    </Form.Select>
                </InputGroup>
                <InputGroup className="auth-btn input-grp">
                    <InputGroup.Text>Date of Birth</InputGroup.Text>
                    <Form.Control
                        type="date"
                        name="dob"
                        value={formState.dob}
                        onChange={handleInputChange}
                    />
                </InputGroup>

                <div className="auth-si-btn-wrapper">
                    <Button className="auth-si-btn" onClick={() => setAuthStep(10)}>Proceed</Button>
                </div>
            </div>
        )
    }

    const AuthComponent8 = () => {
        return (
            <div>
                <div className="auth-form-txt">Reset Password <span className="bold">OTP</span> Sent to Your <span className="bold">Email</span> Please check your <span className="bold">inbox</span> for verification</div>
                <OtpInput
                    className="auth-otp"
                    value={code}
                    onChange={handleChange}
                    numInputs={4}
                    renderSeparator={<span style={{ width: "12px" }}> </span>}
                    isInputNum={true}
                    shouldAutoFocus={true}
                    renderInput={(props) => <input {...props} />}
                    inputStyle={{
                        border: "1px solid black",
                        borderRadius: "8px",
                        width: "50px",
                        height: "50px",
                        fontSize: "18px",
                        color: "#000",
                        fontWeight: "400",
                        caretColor: "blue",
                    }}
                    focusStyle={{
                        border: "1px solid #CFD3DB",
                        outline: "none"
                    }}
                />
                <div className="auth-si-btn-wrapper">
                    <Button className="auth-si-btn" onClick={() => setAuthStep(4)}>Proceed</Button>
                </div>
            </div>
        )
    }

    const AuthComponent9 = () => {
        return (
            <div>
                <div className="auth-form-txt"><span className="bold">OTP</span> has been Sent to Your <span className="bold">Phone</span> Please check your <span className="bold">inbox</span> for verification.</div>
                <OtpInput
                    className="auth-otp"
                    value={code}
                    onChange={handleChange}
                    numInputs={4}
                    renderSeparator={<span style={{ width: "12px" }}> </span>}
                    isInputNum={true}
                    shouldAutoFocus={true}
                    renderInput={(props) => <input {...props} />}
                    inputStyle={{
                        border: "1px solid black",
                        borderRadius: "8px",
                        width: "50px",
                        height: "50px",
                        fontSize: "18px",
                        color: "#000",
                        fontWeight: "400",
                        caretColor: "blue",
                    }}
                    focusStyle={{
                        border: "1px solid #CFD3DB",
                        outline: "none"
                    }}
                />
                <div className="auth-si-btn-wrapper">
                    {/* <Button className="auth-si-btn" onClick={() => setAuthStep(6)}>Proceed</Button> */}
                    <Button className="auth-si-btn" onClick={handleSubmitOTP}>Proceed</Button>
                </div>
            </div>
        )
    }

    const AuthComponent10 = () => {
        return (
            <div>
                <div className="auth-form-txt">Upload Image (Profile Picture)</div>
                <Form.Control type="file" className="input-grp" />
                <div className="auth-si-btn-wrapper">
                    <Button className="auth-si-btn" onClick={registerUser}>Proceed</Button>
                </div>
            </div>
        )
    }

    const AuthComponent11 = () => {
        return (
            <div>
                <div className="auth-form-txt">Enter Email Address in which you want to send OTP</div>
                <InputGroup className="auth-btn input-grp">
                    <InputGroup.Text>Email</InputGroup.Text>
                    <Form.Control
                        type="email"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </InputGroup>
                <div className="auth-si-btn-wrapper">
                    {/* <Button className="auth-si-btn" onClick={() => setAuthStep(6)}>Proceed</Button> */}
                    <Button className="auth-si-btn" onClick={handleSendOTP}>Proceed</Button>
                </div>
            </div>
        )
    }

    return (
        <div className="auth">
            <div className="auth-header">
                <Row className="auth-header-content">
                    <Col lg={2}>
                        <span className="brand-name">INSTASALES</span>
                    </Col>
                </Row>
            </div>
            <div className="auth-content">
                <Container>
                    <Row>
                        <Col>
                            <div className="auth-txt">
                                <div className="auth-h">Sign-in to Continue</div>
                                <div className="auth-p">Signing in enhances your shopping experience by personalizing it, keeping your orders secure, and making the process more efficient.</div>
                                {/* The auth-h and auth-p would be different according to the design and what component has been rendered */}
                            </div>
                        </Col>
                        <Col>
                            <div className="auth-form">
                                {message && <p className="success-message">{message}</p>}
                                {authStep === 1 && <AuthComponent1 />}
                                {authStep === 2 && <AuthComponent2 />}
                                {authStep === 3 && <AuthComponent3 />}
                                {authStep === 4 && <AuthComponent4 />}
                                {authStep === 5 && <AuthComponent5 />}
                                {authStep === 6 && <AuthComponent6 />}
                                {authStep === 7 && <AuthComponent7 />}
                                {authStep === 8 && <AuthComponent8 />}
                                {authStep === 9 && <AuthComponent9 />}
                                {authStep === 10 && <AuthComponent10 />}
                                {authStep === 11 && <AuthComponent11 />}
                                {error && <p className="error-message">{error}</p>}
                            </div>
                            {authStep === 1 && <div className="auth-comment">We respect your <span className="bold"><a>privacy</a></span></div>}
                            {authStep === 2 && <div className="auth-comment" onClick={() => setAuthStep(3)}>Don't have account? <span className="bold"><a>Signup</a></span></div>}
                            {/* <div className="auth-comment">Already have account? <span className="bold"><a>signin</a></span></div> */}
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="auth-footer">
                <Row>
                    <Col lg={2}><Button className="auth-footer-btn">Terms & Condition</Button></Col>
                    <Col lg={2}><Button className="auth-footer-btn">Privacy Policy</Button></Col>
                    <Col lg={2}><Button className="auth-footer-btn">Help & Support</Button></Col>
                    <Col lg={6} className="auth-footer-right">
                        <Button className="auth-footer-btn"><FontAwesomeIcon icon={faEnvelope} /> instasales@info.com</Button>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default Auth
